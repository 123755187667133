import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Payroll_layout4 = () => {
  return (
   <div className="p-3">
     <div className='row mx-auto m-0 py-4 align-items-center justify-content-between' style={{backgroundColor:'#F9F9F9',borderRadius:'10px'}}>
        <div className="col-lg-5 ps-md-5 ms-md-5">
            <p className='fw-medium' style={{letterSpacing:'3px',color:'#4F14DE'}}>BENEFITS</p>
            <p className='fs-2 fw-medium'>Benefits of Using Our Payroll Management Software</p>
            <p className="text-secondary mb-5" style={{fontSize:'13px'}}>
            Simplify your payroll tasks while ensuring accuracy and compliance. This software is designed to enhance efficiency for every business size.
            </p>

            <p className='fw-medium d-flex align-items-center'><span className='me-3'><Svg6/></span>Accurate salary calculations every time</p>
            <p className='fw-medium d-flex align-items-center'><span className='me-3'><Svg6/></span>Protect employee data with top security</p>
            <p className='fw-medium d-flex align-items-center'><span className='me-3'><Svg6/></span>Timely updates on tax regulations</p>
            <p className='fw-medium d-flex align-items-center'><span className='me-3'><Svg6/></span>Access instant insights on payroll status</p>
            <p className='fw-medium d-flex align-items-center'><span className='me-3'><Svg6/></span>User-friendly interface for everyone</p>
        </div>
        <div className="col-lg-6 pe-md-0">
            <img src={require('../../images/products/iPhone 12 Pro.png')} style={{width:'100%'}} alt="" />
        </div>
    </div>
   </div>
  )
}

export default Payroll_layout4
