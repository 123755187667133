import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Hrms_layout3 = () => {
  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold mb-3 mb-md-5' style={{letterSpacing:'1px',lineHeight:'54px'}}>Why Choose Merida Tech Minds?</p>
       
       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Custom HR solutions built for your needs</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Exceptional support from our tech experts</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Advanced tools for every workforce size</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Trusted for secure and fast performance</span></p>

       
       </div>
       <div className="col-md-6 text-center">
       <img src={require('../../images/products/Frame 1 (1).png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Hrms_layout3
