import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="77" height="72" viewBox="0 0 77 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M62.6554 69.4492C59.7635 69.1849 57.0649 67.7249 55.2112 65.5013C53.0506 62.9085 52.9624 59.8794 52.4748 56.716C52.0082 53.7036 50.0116 51.0311 47.5652 49.2858C41.857 45.2162 33.673 45.2204 28.4734 50.4102C26.8635 52.0212 25.7874 54.098 25.1989 56.2838C24.6273 58.4025 24.8795 60.6136 24.0304 62.6484C22.4835 66.374 18.9064 69.0758 14.8712 69.4492C7.99861 70.0869 2.27358 64.4482 2.7864 57.6138C3.0428 54.2029 4.95955 51.0437 7.83047 49.1851C9.42776 48.1488 11.4118 47.4901 13.3832 47.4566C15.5269 47.4188 17.6076 48.2034 19.7639 48.0062C22.3742 47.767 24.8963 46.3909 26.4011 44.2344C28.4902 42.1199 29.768 39.2124 29.768 36.0155C29.768 30.9348 25.5982 24.6667 20.1716 24.1297C18.0531 23.9199 16.0313 24.7716 13.9296 24.822C8.21298 24.9604 3.21094 20.2825 2.7864 14.6312C2.26938 7.80512 7.99441 2.1664 14.8628 2.79992C19.7093 3.24883 23.7445 6.95344 24.6651 11.7279C24.7618 12.2146 24.8248 12.7012 24.85 13.1837C25.2451 20.1021 31.6091 27.0498 38.7549 27.0498C42.6935 27.0498 46.2621 25.1324 48.7379 22.1327C51.2978 19.028 52.0965 15.5626 52.8363 11.7279C53.7568 6.95344 57.7921 3.24883 62.6386 2.79992C69.5111 2.1664 75.232 7.80092 74.7191 14.6354C74.324 19.8839 70.1374 24.2094 64.8916 24.759C62.2771 25.0359 59.7593 23.8318 57.1742 24.2052C51.4071 25.0401 46.8044 30.3516 46.8044 36.1288C46.8044 39.9928 48.7674 43.6764 51.9704 45.8413C53.6055 46.9489 55.4802 47.96 57.4852 48.0271C59.7298 48.1027 61.9198 47.3768 64.1644 47.4524C69.6667 47.637 74.3114 52.1471 74.7191 57.6138C75.232 64.444 69.5153 70.0828 62.6512 69.4534L62.6554 69.4492Z" fill="#9C40F2" stroke="#2F2F2F" stroke-width="3.75"/>
<path d="M17.7574 44.6119C22.4537 44.6119 26.2609 40.8119 26.2609 36.1244C26.2609 31.4369 22.4537 27.637 17.7574 27.637C13.061 27.637 9.25391 31.4369 9.25391 36.1244C9.25391 40.8119 13.061 44.6119 17.7574 44.6119Z" fill="#9C40F2" stroke="#2F2F2F" stroke-width="3.75"/>
<path d="M59.1636 44.6119C63.86 44.6119 67.6671 40.8119 67.6671 36.1244C67.6671 31.4369 63.86 27.637 59.1636 27.637C54.4673 27.637 50.6602 31.4369 50.6602 36.1244C50.6602 40.8119 54.4673 44.6119 59.1636 44.6119Z" fill="#9C40F2" stroke="#2F2F2F" stroke-width="3.75"/>
<path d="M44.7652 64.4366C48.086 61.122 48.086 55.7481 44.7652 52.4335C41.4443 49.119 36.0602 49.119 32.7394 52.4335C29.4186 55.7481 29.4186 61.122 32.7394 64.4366C36.0602 67.7511 41.4443 67.7511 44.7652 64.4366Z" fill="#9C40F2" stroke="#2F2F2F" stroke-width="3.75"/>
<path d="M44.7652 21.4337C48.086 18.1191 48.086 12.7452 44.7652 9.4306C41.4443 6.11605 36.0602 6.11605 32.7394 9.4306C29.4186 12.7452 29.4186 18.1191 32.7394 21.4337C36.0602 24.7482 41.4443 24.7482 44.7652 21.4337Z" fill="#9C40F2" stroke="#2F2F2F" stroke-width="3.75"/>
</svg>

    </div>
  )
}

export default Svg2
