import React from 'react'
import Check from '../../Svgs/Products/Check'

const Task_layout4 = () => {
  return (
    <div>
      <div className="container pt-5 row m-0 mx-auto align-items-center">
      <div className="col-md-6">
       <img src={require('../../images/products/Hero Content1.png')} className='' style={{width:'90%'}} alt="" />
       </div>

       <div className='col-md-6 col-lg-5 mx-auto'>
        <p className='mb-4'><span className='fw-bold border px-2 py-1 rounded-pill' style={{fontSize:'14px'}}><img className='me-1' src={require('../../images/products/Union.png')} alt="" /> TRACK PROJECTS</span></p>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Task and project <span className='task-text-color'>management</span></p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Manage tasks, teams, and workflows efficiently to meet your objectives without any delays.</p>

       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Organize and update tasks for easy tracking.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Assign tasks to teammates with clear deadlines and priorities.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Use visual tools to monitor progress and updates.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Switch between tasks and projects to maintain productivity.</span></p>

       
       </div>
      </div>
    </div>
  )
}

export default Task_layout4
