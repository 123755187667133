import React, { useEffect, useState } from 'react';
import Svg1 from '../Svgs/Svg1';
import Svg2 from '../Svgs/Svg2';
import Slider from 'react-slick';

const News_and_events = () => {
  const [isSliding, setIsSliding] = useState(false); // State to track if slider is running

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    cssEase: 'linear',
    beforeChange: () => setIsSliding(true), // Slider is sliding, stop the animation
    afterChange: () => setIsSliding(false), // Slider has stopped, start the animation
  };

  return (
    <div className="news-container py-5">
      <p className="client-main-text">
        News & <span className="client-text">Events</span>
      </p>
      <p className="text-white text-center">
        Catch up on what’s new at Merida Tech Minds! We share exciting updates,
        events, and helpful news to support your journey.
      </p>

      <div className="row m-0 container mx-auto mt-5">
        <div className="slider-container">
          <Slider
            {...settings}
            className=""
          >
            <div className="col-md-4 py-4 px-3">
              <div
                className={`client-card2 p-4 ${
                  !isSliding ? 'tilt-animation' : ''
                }`}
              >
                <div
                  className="row m-0"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="col-8 bg-secondary-subtle"
                    style={{ height: '300px', borderRadius: '20px' }}
                  >
                    <div
                      className="d-flex news-color"
                      style={{ position: 'absolute' }}
                    >
                      <p
                        className="m-0 client-text fs-2 fw-bold"
                        style={{ lineHeight: '40px' }}
                      >
                        Market Research
                      </p>
                      <span className="text-white">
                        <Svg2 />
                      </span>
                    </div>
                  </div>
                  <div className="col-4 pe-0">
                    <p className="text-white fs-1 fw-medium text-end">01</p>
                  </div>
                </div>

                <p
                  className="text-white m-0 mt-3"
                  style={{ fontSize: '13px' }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Expedita soluta cumque magnam explicabo eos. Aliquid sed fugit
                  recusandae modi
                </p>
              </div>
            </div>

            <div className="col-md-4 px-3 py-4">
              <div
                className={`client-card2 p-4 ${
                  !isSliding ? 'tilt-animation' : ''
                }`}
              >
                <div
                  className="row m-0"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="col-8 bg-secondary-subtle"
                    style={{ height: '300px', borderRadius: '20px' }}
                  >
                    <div
                      className="d-flex news-color"
                      style={{ position: 'absolute' }}
                    >
                      <p
                        className="m-0 client-text fs-2 fw-bold"
                        style={{ lineHeight: '40px' }}
                      >
                        Market Research
                      </p>
                      <span className="text-white">
                        <Svg2 />
                      </span>
                    </div>
                  </div>
                  <div className="col-4 pe-0">
                    <p className="text-white fs-1 fw-medium text-end">01</p>
                  </div>
                </div>

                <p
                  className="text-white m-0 mt-3"
                  style={{ fontSize: '13px' }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Expedita soluta cumque magnam explicabo eos. Aliquid sed fugit
                  recusandae modi
                </p>
              </div>
            </div>

            <div className="col-md-4 px-3 py-4">
              <div
                className={`client-card2 p-4 ${
                  !isSliding ? 'tilt-animation' : ''
                }`}
              >
                <div
                  className="row m-0"
                  style={{ position: 'relative' }}
                >
                  <div
                    className="col-8 bg-secondary-subtle"
                    style={{ height: '300px', borderRadius: '20px' }}
                  >
                    <div
                      className="d-flex news-color"
                      style={{ position: 'absolute' }}
                    >
                      <p
                        className="m-0 client-text fs-2 fw-bold"
                        style={{ lineHeight: '40px' }}
                      >
                        Market Research
                      </p>
                      <span className="text-white">
                        <Svg2 />
                      </span>
                    </div>
                  </div>
                  <div className="col-4 pe-0">
                    <p className="text-white fs-1 fw-medium text-end">01</p>
                  </div>
                </div>

                <p
                  className="text-white m-0 mt-3"
                  style={{ fontSize: '13px' }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Expedita soluta cumque magnam explicabo eos. Aliquid sed fugit
                  recusandae modi
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      {/* <p className='text-white m-0'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita soluta cumque magnam explicabo eos. Aliquid sed fugit recusandae modi! Distinctio aliquam architecto labore laudantium tempore iusto consectetur perferendis quibusdam. Illo corrupti soluta necessitatibus recusandae doloremque, magni repudiandae accusamus? Repellendus accusantium placeat ex asperiores quae velit laboriosam enim itaque voluptas atque?</p> */}
    </div>
  );
};

export default News_and_events;
