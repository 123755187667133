import React from 'react';
import Layout2 from './Layout2';
import Layout3 from './Layout3';
import Blogs from '../Landingpages/Blogs';
import News_and_events from '../Landingpages/News_and_events';
import Client_reviews from '../Landingpages/Client_reviews';
import Navbar from '../Landingpages/Navbar';
import Layout4 from './Layout4';
import Layout5 from './Layout5';
import FAQ from './FAQ';
import Contact_us_layout from '../Landingpages/Contact_us_layout';
import myvideo from '../images/newVideos/digital_marketing.mp4';
// import myvideo from '../images/videos/digital_marketing.mp4';
import Scrolltotop from '../Scrolltotop';
import Backtotop from '../Backtotop';
import { Helmet } from 'react-helmet';

const Inner1_Mainpage = () => {
  return (
    <div>
      <Helmet>
        <title>
          Professional SEO Services For Website's Visibility & Traffic
        </title>
        <meta
          name="description"
          content="Improve your search rankings with expert SEO strategies including keyword research, on-page seo, link building to drive organic traffic & increase conversions"
        />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <div>
        <h1 className="d-none">best seo services</h1>
        <h2 className="d-none">top seo company</h2>
        <div className="video-background">
          <Navbar />
          <video
            autoPlay
            muted
            loop
            className="background-video"
          >
            <source
              src={myvideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <Layout2 />
        <Layout3 />
        <Layout4 />
        <Layout5 />
        <Client_reviews />
        <News_and_events />
        <FAQ />
        <Contact_us_layout />
        <Blogs />
        <Scrolltotop />
        <Backtotop />
      </div>
    </div>
  );
};

export default Inner1_Mainpage;
