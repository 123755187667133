import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Ocr_layout3 = () => {
  return (
    <div style={{minHeight:'90vh'}} className='d-flex align-items-center position-relative'>
        <img src={require('../../images/products/Effect_Dot.png')} style={{width:'5%'}} className='position-absolute top-0' alt="" />
        <img src={require('../../images/products/Effect_Dot.png')} style={{width:'5%'}} className='position-absolute bottom-0 end-0' alt="" />
      <div className="container row m-0 mx-auto justify-content-center">
        <div className="col-md-6">

        </div>
        <div className="col-md-6 col-lg-5">
            <p className='fw-bold fs-2 mb-1' style={{color:'#3735B3',lineHeight:'40px'}}>Advantages of Using Our Software Solution</p>
            <p className='mb-4'>Gain significant benefits that enhance learning experiences and management efficiency.</p>

            <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Increased user satisfaction</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Simplified task organization</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Access to insightful analytics</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Ongoing technical support</span></p>
        </div>
      </div>
    </div>
  )
}

export default Ocr_layout3
