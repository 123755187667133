import React from 'react'

const Hrms_layout6 = () => {

  const data=[
    {
      heading:"Document Storage",
      para:"Securely store employee files and policies in one place for quick access anytime.",
      img:"/asset/card-img1.png"
    },
    {
      heading:"Performance Insights",
      para:"Generate reports to understand individual and team performance for better decision-making.",
      img:"/asset/card-img2.png"
    }
  ]

  return (
    <div style={{backgroundColor:'#F3F4FF'}}>
        <div className='row m-0 container justify-content-center mx-auto py-4 py-md-5'>
      <div className='mt-5 mb-4'>
      <button className='p-2 d-block mx-auto px-3 btn rounded fw-medium m-0' style={{color:'#ffff',backgroundColor:'#C866D8',fontSize:'14px'}}>FEATURES</button>
       <p className="fw-medium fs-1 mt-3 text-center" style={{color:'#183B56'}}>Data and Document Management</p>
      </div>

      {data.map((x)=>{
        return(
          <div className="col-lg-5 col-md-6 mb-4 mb-lg-0 px-lg-4">
       <div className='p-2 p-lg-3' style={{backgroundColor:'#FFFF',borderRadius:'20px'}}>
       <img src={x.img} alt="" className='mb-4' style={{width:'100%'}}/>
        <div className='hrms-layout6-subdiv mx-auto'>
        <p className='fw-medium text-center fs-5 mb-2' style={{color:'#183B56'}}>{x.heading}</p>
        <p className=' text-center' style={{fontSize:'13px',color:'#5A7184',lineHeight:'20px'}}>{x.para}</p>
        </div>
       </div>
      </div>
        )
      })}
    </div>
    </div>
  )
}

export default Hrms_layout6
