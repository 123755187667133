import React from 'react'

const CRM_layout4 = () => {
  return (
    <div className='py-5'>
      <div className='container row mx-auto m-0 p-5' style={{borderRadius:'20px',backgroundColor:'#F0F0F0'}}>
        <div className="col-md-6 col-lg-5">
        <p className='crm-layout3-text'>INTEGRATION</p>
        <p className='fs-1 fw-bold' style={{lineHeight:'54px'}}>CRM Solutions for Every Industry</p>
        <p style={{fontSize:'13px',lineHeight:'26px'}}>We offer personalized CRM solutions for diverse industries, enabling every business to connect with customers in a simple and effective way.</p>
        <p className='crm-layout3-text mt-4' style={{fontSize:'14px',cursor:'pointer'}}>View All Integrations <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF2465" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></span></p>

        </div>
      </div>

      <div className='container row mx-auto m-0 p-0 mt-4'>
        <div className="col-md-6 ps-md-0 mb-4 mb-lg-0">
            <div className="p-5" style={{borderRadius:'20px',backgroundColor:'#F0F0F0'}}>
            <p className='crm-layout3-text'>INTEGRATION</p>
        <p className='fs-1 fw-bold' style={{lineHeight:'54px'}}>Proven Track Record of Success</p>
        <p style={{fontSize:'13px',lineHeight:'26px'}}>Having completed many successful projects, we are committed to leveraging our expertise to help your business succeed with CRM solutions.</p>
        <p className='crm-layout3-text mt-4' style={{fontSize:'14px',cursor:'pointer'}}>View All Integrations <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF2465" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></span></p>
            </div>
        </div>
        <div className="col-md-6 pe-md-0 mb-4 mb-lg-0">
            <div className="p-5" style={{borderRadius:'20px',backgroundColor:'#F0F0F0'}}>
            <p className='crm-layout3-text'>TEAM MANAGEMENT</p>
        <p className='fs-1 fw-bold' style={{lineHeight:'54px'}}>Enhance Your Customer Relationships Like Never Before</p>
        <p className='mb-4 pb-3' style={{fontSize:'13px',lineHeight:'26px'}}>Want to know how CRM can benefit you and your business? Reach out now for a free consultation to discuss our services and find the right fit for you!</p>

<div className="row m-0">
<div className="col-md-4 ps-md-0 mb-4 mb-lg-0">
    <div className='crm-layout4-card1 pt-4'>
        <div className='bg-white rounded-circle mx-auto' style={{height:'60px',width:'60px'}}>

        </div>
    </div>
</div>
<div className="col-md-4 ps-md-0 mb-4 mb-lg-0">
    <div className='crm-layout4-card2 pt-4'>
        <div className='bg-white rounded-circle mx-auto' style={{height:'60px',width:'60px'}}>

        </div>
    </div>
</div>
<div className="col-md-4 ps-md-0">
    <div className='crm-layout4-card3 pt-4'>
        <div className='bg-white rounded-circle mx-auto' style={{height:'60px',width:'60px'}}>

        </div>
    </div>
</div>
</div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CRM_layout4
