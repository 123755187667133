import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#E6F7F2"/>
<path d="M9 17L12.5264 19.8211C13.3537 20.483 14.5536 20.3848 15.2624 19.5973L23 11" stroke="#00A874" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  )
}

export default Svg1
