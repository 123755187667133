import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:" What is lead generation?",
            answer:"Lead generation involves attracting the ideal customers for your business. It helps create interest in your products or services, encouraging people to connect with you!"
        },
        {
            question:"How does lead generation work?",
            answer:"Lead generation works by using various strategies, like email marketing, content marketing, social media, to engage people. These methods help bring interested visitors to your business."
        },
        {
            question:"Why is lead generation important for my business?",
            answer:"Lead generation is crucial because it helps you find new customers. By reaching more people, you can grow your sales and build lasting relationships with clients. Merida Tech Minds’ lead generation services can simplify this process by offering professional guidance and tools to enhance your outreach strategies."
        },
        {
            question:"Is lead generation a one-time effort?",
            answer:"No, lead generation is an ongoing process! Regularly updating your strategies and engaging with ideal customers keeps your business thriving and continuously attracting interest."
        },
        {
            question:"When will I start seeing results from your lead generation?",
            answer:"While results can differ, most businesses begin to notice leads within just a few weeks. Consistent efforts and effective strategies lead to more significant results over time!"
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
