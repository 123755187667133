import React from 'react'
import Check from '../../Svgs/Products/Check'
import Svg1 from '../../Svgs/Printlele/Svg1'

const Printlele_layout4 = () => {
  return (
    <div className='pt-md-4'>
      <div className="container py-5 row m-0 mx-auto align-items-center">

       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Why Choose Printlele for Your Printing Needs?</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Printlele delivers printing solutions that work for everyone, whether for personal or business use, making the process easy and straightforward.</p>

       <p className='d-flex align-items-center'><Svg1/> <span className='fw-bold ms-3'>Fast and Reliable Prints</span></p>
       <p className='d-flex align-items-center mt-4'><Svg1/> <span className='fw-bold ms-3'>Lower Printing Costs</span></p>
       <p className='d-flex align-items-center mt-4'><Svg1/> <span className='fw-bold ms-3'>Secure Document Handling</span></p>
       <p className='d-flex align-items-center mt-4'><Svg1/> <span className='fw-bold ms-3'>Simplified User Access</span></p>

       
       </div>
       <div className="col-md-6 text-center">
       <img src={require('../../images/products/Container.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Printlele_layout4
