import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7184 18.9977C15.1163 18.8426 15.5758 19.1816 15.4905 19.6C15.0005 21.5 13.9005 22 12.5505 22H11.4605C10.1105 22 9.00055 21.5 8.52055 19.59C8.4383 19.171 8.89557 18.8347 9.2932 18.9905C10.1478 19.3253 11.0613 19.5 12.0005 19.5C12.9442 19.5 13.8624 19.3314 14.7184 18.9977Z" fill="white"/>
<path d="M15.4908 4.4C15.5792 4.82271 15.1132 5.1669 14.7113 5.00871C13.8736 4.67891 12.9595 4.5 12.0008 4.5C11.0432 4.5 10.1309 4.68113 9.29243 5.01127C8.89454 5.16793 8.43543 4.82901 8.52078 4.41C9.00078 2.5 10.1108 2 11.4608 2H12.5508C13.9008 2 15.0008 2.5 15.4908 4.4Z" fill="white"/>
<path d="M12 5.5C8.41 5.5 5.5 8.41 5.5 12C5.5 14.1 6.49 15.96 8.03 17.15H8.04C9.14 18 10.51 18.5 12 18.5C13.51 18.5 14.89 17.99 15.99 17.13H16C17.52 15.94 18.5 14.08 18.5 12C18.5 8.41 15.59 5.5 12 5.5ZM13.93 14.38C13.78 14.53 13.59 14.6 13.4 14.6C13.21 14.6 13.02 14.53 12.87 14.38L11.47 12.98C11.33 12.84 11.25 12.65 11.25 12.45V9.66C11.25 9.25 11.59 8.91 12 8.91C12.41 8.91 12.75 9.25 12.75 9.66V12.14L13.93 13.32C14.22 13.61 14.22 14.09 13.93 14.38Z" fill="white"/>
</svg>
    </div>
  )
}

export default Svg1
