import React from 'react'
import { useNavigate } from 'react-router-dom'

const Hrms_layout7 = () => {
  const navigate=useNavigate()

  return (
    <div>
<div className='hrms-layout7 d-flex align-items-center justify-content-center'>
    <img src={require('../../images/products/Frame 66.png')} className='position-absolute hrms-layout7-img' alt="" />

  <div className='text-center medical-subdiv mx-auto' style={{position:'relative',zIndex:5}}> 
      <p className='hrms-text fw-medium text-center'>HR Made Easy with Our Smart Solutions</p>
      <p style={{fontSize:'14px'}}>Our HRMS software brings purpose to every process. Find out how our solutions can enhance your HR operations. Get in touch with us today!</p>

      <button onClick={()=>navigate('/contact-us')} style={{backgroundColor:'#7744D6',fontSize:'14px'}} className='mt-4 mt-lg-5 btn text-white fw-medium px-4 px-lg-5 py-3 rounded-pill d-block mx-auto'>Contact Us</button>
  </div>
</div>
    </div>
  )
}

export default Hrms_layout7
