import React from 'react'

const Hrms_layout5 = () => {

  const data=[
    {
      heading:"Quick Onboarding",
      para:"Welcome new employees smoothly with minimal paperwork, ensuring a fast start from day one.",
      img:"/asset/01.png"
    },
    {
      heading:"Leave Planner",
      para:"Plan, track, and approve leaves easily, helping everyone stay updated on team availability.",
      img:"/asset/02.png"
    },
    {
      heading:"Attendance Monitor",
      para:"Automatically track employee work hours, ensuring accurate logs without manual effort.",
      img:"/asset/03.png"
    },
    {
        heading:"Alerts System",
        para:"Receive timely reminders for payroll and compliance tasks to stay on schedule.",
        img:"/asset/04.png"
      }
  ]

  return (
    <div style={{backgroundColor:'#F3F4FF'}}>
        <div className='row m-0 container justify-content-evenly mx-auto py-4 py-md-5'>
      <div className='my-5'>
      <button className='p-2 px-3 btn rounded fw-medium m-0' style={{color:'#ffff',backgroundColor:'#C866D8',fontSize:'14px'}}>FEATURES</button>
       <p className="fw-medium fs-1 mt-3 mb-2" style={{color:'#183B56'}}>Tools to Keep Every Team on Track</p>
       <p>Advanced solutions to enhance productivity, improve communication, and maintain organizational consistency.</p>
      </div>

      {data.map((x)=>{
        return(
          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 px-lg-4">
       <div>
       <img src={x.img} alt="" className='mb-4' style={{width:'18%'}}/>
        <p className='fw-medium fs-5 mb-2' style={{color:'#183B56'}}>{x.heading}</p>
        <p className='' style={{fontSize:'13px',color:'#5A7184',lineHeight:'20px'}}>{x.para}</p>
       </div>
      </div>
        )
      })}
    </div>
    </div>
  )
}

export default Hrms_layout5
