import React from 'react'

const Algo_layout4 = () => {

    const card_data=[
        {
        heading:"Strategy Development",
        para:"Formulate your trading strategy based on personal goals.",
        img:"/asset/Frame 1000006346.png"
    },
    {
        heading:"Algorithm Design",
        para:"Create algorithms that reflect your trading preferences and rules.",
        img:"/asset/Graphic.png"
    },
    {
        heading:"Backtest",
        para:"Test algorithms on historical data to evaluate effectiveness before live trading.",
        img:"/asset/Graphic (4).png"
    }
]

  return (
    <div style={{backgroundColor:'#EBF5FD'}}>
      <div className="container row m-0 mx-auto py-4 py-md-5">
       <p style={{color:'#3E95CF',letterSpacing:'2px'}} className='fw-medium text-center'>FEATURES</p>
        <p className='fs-2 fw-bold text-center mb-5'>The Algo Trading Process</p>

        {card_data.slice(0,3).map((x)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-4 px-lg-4">
            <div className='algo-layout2-cards bg-white pb-md-4'>
            <div className='d-flex align-items-end justify-content-center p-0' style={{height:'200px'}}>
                <img src={x.img} style={{width:'80%',height:'80%'}} alt="" />
                </div>
                <div className='px-2 p-md-4' style={{height:'150px'}}>
                <p className='fs-5 fw-bold text-center'>{x.heading}</p>
                <p className='text-secondary text-center' style={{fontSize:'13px'}}>{x.para}</p>
                </div>
            </div>
        </div>
            )
        })}
      </div>
    </div>
  )
}

export default Algo_layout4
