import React from 'react'

const Brand_building_layout1 = () => {
  return (
    <div className='bg-black d-flex align-items-center' style={{minHeight:'100vh'}}>
      <div className="row m-0 mx-auto container">
        <div className="col-lg-6">
            <img src={require('../images/innerpage5/Rectangle 45001.png')} style={{width:'90%'}} alt="" />
        </div>
        <div className="col-lg-5">
            <p className='text-white fw-medium text-end mb-4'>Brand Identity <span className='text-danger ms-4'>2024</span></p>
            <p className='fs-1 fw-medium text-white'>What Does <span style={{color:'#CDF552'}}>Brand Building </span>
            Really Mean?</p>
            <p className='text-white' style={{fontSize:'14px',lineHeight:'30px'}}>Brand building is about creating a strong image for your company. It helps people understand what your brand stands for and what makes it special. When customers trust and like a brand, they are more likely to return and make purchases. Good brand building also helps attract new customers. In simple terms, strong branding creates lasting connections with people, which is important for a business to grow and succeed over time.</p>
        </div>
      </div>
    </div>
  )
}

export default Brand_building_layout1
