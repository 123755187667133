import React from 'react';
import Layout1 from './Layout1';
import Layout2 from './Layout2';
import Layout3 from './Layout3';
import Layout4 from '../Inner_page1/Layout4';
import Layout5 from '../Inner_page1/Layout5';
import Client_reviews from '../Landingpages/Client_reviews';
import News_and_events from '../Landingpages/News_and_events';
import FAQ from './FAQ';
import Contact_us_layout from '../Landingpages/Contact_us_layout';
import Blogs from '../Landingpages/Blogs';
import Footer from '../Landingpages/Footer';
import myvideo from '../images/newVideos/Lead_Gen.mp4';
// import myvideo from '../images/videos/Lead_Gen.mp4';
import Navbar from '../Landingpages/Navbar';
import Scrolltotop from '../Scrolltotop';
import Backtotop from '../Backtotop';
import { Helmet } from 'react-helmet';

const Inner_page2 = () => {
  return (
    <div>
      <Helmet>
        <title>
          Our Lead Generation Strategies for Growing Sales & Business
        </title>
        <meta
          name="description"
          content="Grow your business with our proven lead generation techniques to attract, convert and retain high-quality leads using the latest strategies and tools for sucess"
        />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <div>
        <h1 className="d-none">lead generation techniques</h1>
        <h2 className="d-none">lead generation strategies </h2>

        <div className="video-background">
          <Navbar />
          <video
            autoPlay
            muted
            loop
            className="background-video"
          >
            <source
              src={myvideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <Layout1 />
        <Layout2 />
        <Layout3 />
        <Layout4 />
        <Layout5 />
        <Client_reviews />
        <News_and_events />
        <FAQ />
        <Contact_us_layout />
        <Blogs />
        <Scrolltotop />
        <Backtotop />
      </div>
    </div>
  );
};

export default Inner_page2;
