import React from 'react'

const Task_layout3 = () => {
  return (
    <div>
      <div className="container py-5 row m-0 mx-auto align-items-center">
       <div className='col-md-6 col-lg-5 mx-auto'>
        <p className='mb-4'><span className='fw-bold border px-2 py-1 rounded-pill' style={{fontSize:'14px'}}><img className='me-1' src={require('../../images/products/Union.png')} alt="" /> TO THE POINT</span></p>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Clear Insights for <span className='task-text-color'>Better Decisions</span></p>
       <p className='text-secondary  mt-3 mt-md-4' style={{fontSize:'15px'}}>Get straight to the point with practical insights to enhance your task management strategies effectively.</p>
  <button className='task-manager-gardient-buttons btn text-dark bg-white rounded-pill fw-medium mt-4'>Get Notified</button>

       
       </div>
       <div className="col-md-6">
       <img src={require('../../images/products/Hero Content.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Task_layout3
