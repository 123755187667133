import React, { useEffect, useRef, useState } from 'react'
import ReactCardFlip from 'react-card-flip';

const Home_layout3 = () => {

  const [boxes, setBoxes] = useState([]);
  const isAddingBox = useRef(false); // To prevent overlapping box generation

  // Function to handle mouse movement and add new boxes
  const handleMouseMove = (e) => {
    if (!isAddingBox.current) {
      isAddingBox.current = true; // Prevent additional box generation

      const newBox = {
        x: e.clientX,
        y: e.clientY,
        id: new Date().getTime(), // Unique id for each box
      };

      setBoxes((prevBoxes) => [...prevBoxes, newBox]);

      // Remove box after 1 second
      setTimeout(() => {
        setBoxes((prevBoxes) => prevBoxes.filter((box) => box.id !== newBox.id));
      }, 800); // 1 second before it disappears

      // Add delay to generate the next box
      setTimeout(() => {
        isAddingBox.current = false; // Allow new box to be added
      }, 250); // 300ms delay between box generation
    }
  };

  const [array, setArray] = useState([]);
  const [hoveredIndices, setHoveredIndices] = useState([]);

  useEffect(() => {
    const newArray = Array.from({ length: 132 }, (_, index) => index + 1);
    setArray(newArray);
  }, []);


  const [hoveredIndex, setHoveredIndex] = useState(-1)
  const [previoushoveredIndex, setpreviousHoveredIndex] = useState(-1)

  const handleMouseenter = (index) => {
    setHoveredIndices((prev) => {
      const newHistory = [index, ...prev].slice(0, 3);

      setTimeout(() => {
        setHoveredIndices((prevHistory) => prevHistory.filter(i => i === index || !newHistory.includes(i)));
      }, 1000);

      return newHistory;
    });
  };

  // Card Flip
  const [flipped, setflipped] = useState(false)
  const [flipped2, setflipped2] = useState(false)
  const [flipped3, setflipped3] = useState(false)

  return (
    <div className='mouse-animation' onMouseMove={handleMouseMove} style={{ backgroundColor: '#262626' }}>
      <div className='row m-0 mx-auto' style={{ minHeight: '90vh' }}>
        <div className="col-lg-6 d-flex justify-content-center align-items-center" style={{ position: 'relative' }}>
          <div className="row m-0 h-100 px-5 py-4" style={{ position: 'absolute', zIndex: 1 }}>
            {array.map((x, index) => {
              return (
                <div onMouseEnter={() => {
                  handleMouseenter(index)
                }} className='col-lg-1 border border-secondary border-bottom-0 square border-start-0' style={{ backgroundColor: hoveredIndices.includes(index) ? '#d0f64a' : '' }}>

                </div>
              )
            })}
          </div>

          <img src={require('../images/Group 1000003174.png')} className='mt-md-5' style={{ width: '66%', position: 'relative', zIndex: 2 }} alt="" />
        </div>
        <div className="col-lg-6 ps-md-4 d-flex flex-column justify-content-center">
          <p className='fs-1 text-white'>How We Works</p>


          <section onMouseEnter={() => {
            setflipped(true)
          }} onMouseLeave={() => {
            setflipped(false)
          }} >

            <ReactCardFlip isFlipped={flipped} flipDirection="vertical">
              <div className='row m-0 mt-4' >
                <div className='col-2 bg-white p-2'>
                  <p className='fs-4 text-center fw-medium m-0'>STEP</p>
                  <p className='fs-2 text-center fw-medium'>1</p>
                </div>
                <div className="col-10 py-2" style={{ border: '0.5px solid #5B5A5A' }}>
                  <p className='fs-5 text-white'>Connecting With You </p>
                  <p className='text-white mt-2 works-text'>We start by understanding your needs, listening closely to your ideas, and gathering all the details to serve you better.</p>
                </div>
              </div>

              <div className='row m-0 mt-4' style={{backgroundColor:'#D9D9D9'}}>
                <div className='col-2 bg-white p-2'>
                  <p className='fs-4 text-center fw-medium m-0'>STEP</p>
                  <p className='fs-2 text-center fw-medium'>1</p>
                </div>
                <div className="col-10 py-2" style={{ border: '0.5px solid #5B5A5A' }}>
                  <p className='fs-5 '>Customized Planning</p>
                  <p className=' mt-2 works-text'>Next, we develop a clear plan that outlines the steps we’ll take, ensuring it meets your goals and expectations.</p>
                </div>
              </div>
            </ReactCardFlip>

          </section>

          <section onMouseEnter={() => {
            setflipped2(true)
          }} onMouseLeave={() => {
            setflipped2(false)
          }} >

            <ReactCardFlip isFlipped={flipped2} flipDirection="vertical">
          <div className='row m-0'>
            <div className='col-2 bg-secondary-subtle p-2'>
              <p className='fs-4 text-center m-0 fw-medium'>STEP</p>
              <p className='fs-2 text-center fw-medium'>2</p>
            </div>
            <div className="col-10 py-2 border-top-0" style={{ border: '0.5px solid #5B5A5A' }}>
              <p className='fs-5 text-white'>Execute & Deliver</p>
              <p className='text-white mt-2 works-text'>Finally, ​​we put our plan into action, keeping you updated and delivering results that make you happy and satisfied.</p>
            </div>
          </div> 

          <div className='row m-0' style={{backgroundColor:'#D9D9D9'}}>
                <div className='col-2 bg-white p-2'>
                  <p className='fs-4 text-center fw-medium m-0'>STEP</p>
                  <p className='fs-2 text-center fw-medium'>2</p>
                </div>
                <div className="col-10 py-2" style={{ border: '0.5px solid #5B5A5A' }}>
                  <p className='fs-5 '>Initial Consultation</p>
                  <p className=' mt-2 works-text'>We begin by connecting with you, listening to your ideas, and understanding your specific requirements to ensure we’re on the same page.</p>
                </div>
              </div>
          </ReactCardFlip>

</section>          
          
<section onMouseEnter={() => {
            setflipped3(true)
          }} onMouseLeave={() => {
            setflipped3(false)
          }} >

            <ReactCardFlip isFlipped={flipped3} flipDirection="vertical">
          <div className='row m-0'>
            <div className='col-2 bg-secondary-subtle p-2'>
              <p className='fs-4 text-center m-0 fw-medium'>STEP</p>
              <p className='fs-2 text-center fw-medium'>3</p>
            </div>
            <div className="col-10 py-2 border-top-0" style={{ border: '0.5px solid #5B5A5A' }}>
              <p className='fs-5 text-white'>Personalized Planning</p>
              <p className='text-white mt-2 works-text'>Next, we create a custom plan just for you, outlining each step to meet your goals and expectations effectively and efficiently.</p>
            </div>
          </div> 

          <div className='row m-0' style={{backgroundColor:'#D9D9D9'}}>
                <div className='col-2 bg-white p-2'>
                  <p className='fs-4 text-center fw-medium m-0'>STEP</p>
                  <p className='fs-2 text-center fw-medium'>3</p>
                </div>
                <div className="col-10 py-2" style={{ border: '0.5px solid #5B5A5A' }}>
                  <p className='fs-5'>Execute with Care</p>
                  <p className=' mt-2 works-text'>Then, we put our plan into action, executing each detail carefully while keeping you updated on how things are going throughout the process.</p>
                </div>
              </div>
          </ReactCardFlip>

</section>    
        </div>
      </div>
      {/* {boxes.map((box) => (
        <div
          key={box.id}
          className="code-block-box"
          style={{ left: `${box.x+10}px`, top: `${box.y+10}px` }}
        >
        </div>
      ))} */}

    </div>
  )
}

export default Home_layout3
