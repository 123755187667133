import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Onlinetest_layout4 = () => {
  return (
    <div className='py-lg-4'>
      <div className="py-5 row m-0 mx-auto align-items-center">

       <div className='col-md-6 col-lg-4 mx-auto'>
        <p style={{color:'#36B37E',letterSpacing:'2px'}} className='fw-medium'>ANALYTICS</p>
       <p className='fs-1 fw-medium' style={{letterSpacing:'1px',lineHeight:'54px',color:'#183B56'}}>Technologies Enhancing Your Online Testing Experience</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Our platform utilizes advanced technology for reliable and secure assessments.</p>

       <div className="row m-0">
        <div className="col-md-6">
            <img src={require('../../images/products/Icon (9).png')} alt="" />
            <p className='fw-medium mt-3 mb-2' style={{color:'#183B56'}}>Anti-Cheat Mechanisms</p>
            <p style={{color:'#5A7184',fontSize:'14px'}}>Advanced systems prevent dishonest practices during assessments.</p>
        </div>
        <div className="col-md-6">
            <img src={require('../../images/products/Icon (10).png')} alt="" />
            <p className='fw-medium mt-3 mb-2' style={{color:'#183B56'}}>Secure Cloud Storage</p>
            <p style={{color:'#5A7184',fontSize:'14px'}}>Ensures data is safely stored and easily accessible.</p>
        </div>
       </div>
       </div>

       <div className="col-md-6 col-lg-5 text-end pe-md-0">
       <img src={require('../../images/mtm_images/online test 3.png')} className='' style={{width:'100%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Onlinetest_layout4