import React from 'react'

const Algo_layout3 = () => {
  return (
    <div className='d-flex align-items-center' style={{backgroundColor:'#EBF5FD',minHeight:'90vh'}}>
      <div className="container row m-0 mx-auto align-items-center justify-content-center">
        <div className="col-md-6">
          <img src={require('../../images/mtm_images/algo trading 2.png')} style={{width:'100%'}} alt="" />
        </div>
        <div className="col-md-6 col-lg-5 ps-lg-5">
            <p className='fw-medium' style={{letterSpacing:'2px',color:'#3E95CF'}}>WHAT IS ALGO TRADING?</p>
            <p style={{fontSize:'14px',lineHeight:'24px'}}>Algorithmic trading uses pre-set rules and mathematical models to automatically buy or sell stocks in milliseconds. It eliminates emotional biases and executes trades at the most optimal price, ensuring better profits. This modern technology helps investors focus on strategy while the algorithm trading system takes care of the rest.</p>
            <p style={{fontSize:'14px',lineHeight:'24px'}}>With algo trading, investors can automate complex strategies, ensuring trades are faster than manual execution. The system constantly monitors the market and reacts to changes instantly. Whether buying, selling, or adjusting positions, the software ensures minimal delays, allowing even beginners to participate without the fear of missing market opportunities.</p>
        </div>
      </div>
    </div>
  )
}

export default Algo_layout3
