import React from 'react'

const Arrow_svg = () => {
  return (
    <div>
      <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.20312 6.41797H18.2031V16.418M18.2031 6.41797L6.20312 18.418L18.2031 6.41797Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  )
}

export default Arrow_svg
