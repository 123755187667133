import React from 'react'
import { useNavigate } from 'react-router-dom'

const Medical_layout7 = () => {
  const navigate=useNavigate()

  return (
    <div className='p-2 p-lg-4'>
      <div className='medical-layout7 row m-0 justify-content-between align-items-center'>
        <div className="col-md-6 col-lg-5 ps-lg-5 ps-">
            <p className='fs-1 fw-medium' style={{lineHeight:'50px'}}>Ensuring Clarity and Accuracy in Every Payment</p>
            <p style={{fontSize:'13px'}}>Get in Touch to enhance your billing processes and ensure accurate, timely transactions every time.</p>
            <button onClick={()=>navigate('/contact-us')} className='btn rounded-pill text-white fw-medium px-4 mt-4 py-2' style={{backgroundColor:'#18A0FB',fontSize:'14px'}}>Contact Us</button>
        </div>
        <div className="col-md-6 text-end">
            <img src={require('../../images/products/Dashboard.png')} style={{width:'100%'}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Medical_layout7
