import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Ticket_layout3 = () => {
  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>How Our Ticket Management Benefits You</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Simplify support with improved productivity and customer satisfaction</p>

       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Faster ticket responses</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Enhanced customer experience</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Reduced ticket resolution delays</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Greater team collaboration</span></p>

       
       </div>
       <div className="col-md-6 text-center">
       <img src={require('../../images/mtm_images/ticket management 2.jpg')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Ticket_layout3
