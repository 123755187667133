import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4793 36.8076C11.9808 32.2286 0.0938988 28.9485 0.973862 19.4119C1.95369 8.79305 13.1525 3.16303 23.7576 1.27932C35.3015 -0.771141 48.5031 -0.127228 54.3589 9.92947C61.0477 21.4166 60.0114 37.2976 49.5588 45.59C40.8166 52.5256 30.3198 42.1098 20.4793 36.8076Z" fill="url(#paint0_linear_949_27066)"/>
<path d="M13.8945 20.5851C13.9769 19.9897 14.0466 19.3942 14.1416 18.8051C15.2058 12.3503 20.4697 6.97231 26.8992 5.75609C29.3 5.30001 31.6818 5.34435 34.0509 5.93979C34.83 6.13616 35.2607 6.78228 35.0897 7.48541C34.9123 8.20754 34.2282 8.58761 33.4301 8.39124C30.7126 7.71978 28.0141 7.7958 25.4043 8.81565C20.5521 10.7033 17.5369 14.2253 16.6374 19.3626C15.5795 25.393 18.6137 31.1574 24.1057 33.8939C28.9009 36.282 34.8743 35.5282 38.9284 32.0125C41.4939 29.7891 43.0585 27.002 43.5843 23.651C43.8566 21.9217 43.7806 20.2051 43.3752 18.4947C43.3182 18.254 43.2802 17.988 43.3182 17.7473C43.4132 17.1518 43.9263 16.7338 44.5154 16.7147C45.0919 16.6957 45.6493 17.0568 45.757 17.6396C45.9597 18.7671 46.2004 19.9073 46.2384 21.0475C46.4664 27.9268 42.5074 34.0079 36.1539 36.6304C34.621 37.2638 33.0247 37.6312 31.3714 37.7516C31.27 37.7579 31.1687 37.7896 31.061 37.8086C30.4085 37.8086 29.7497 37.8086 29.0973 37.8086C29.0149 37.7896 28.9326 37.7706 28.8502 37.7579C28.1091 37.6502 27.3616 37.5805 26.6332 37.4285C20.4761 36.1743 15.3831 31.018 14.2176 24.8482C14.0782 24.1134 14.0022 23.3596 13.8945 22.6122C13.8945 21.9344 13.8945 21.2629 13.8945 20.5851Z" fill="white"/>
<path d="M42.9694 5.38232C43.4508 5.55969 43.9639 5.67371 44.401 5.92709C46.5041 7.13698 46.9158 10.0508 45.2182 11.7802C42.9187 14.1176 40.5813 16.4233 38.2502 18.7354C38.0729 18.9128 37.8195 19.0458 37.5724 19.1155C35.9445 19.5842 34.3038 20.0213 32.6695 20.4774C32.1628 20.6168 31.7004 20.5597 31.3266 20.1733C30.9529 19.7933 30.8895 19.3309 31.0416 18.8241C31.5293 17.2341 32.0044 15.6379 32.5048 14.0479C32.5872 13.7882 32.7456 13.5221 32.9356 13.3321C35.2033 11.0517 37.4837 8.77761 39.7642 6.50986C40.3469 5.92709 41.0501 5.55969 41.8672 5.433C41.9179 5.42667 41.9622 5.39499 42.0129 5.38232C42.336 5.38232 42.6527 5.38232 42.9694 5.38232ZM34.1138 17.4305C34.2468 17.4052 34.3228 17.3925 34.4052 17.3735C35.064 17.1961 35.7291 17.0251 36.3815 16.8224C36.5652 16.7654 36.7489 16.664 36.882 16.531C38.2946 15.1374 39.6945 13.7375 41.0944 12.3313C41.1641 12.2616 41.2338 12.1792 41.2781 12.1349C40.6763 11.5331 40.0936 10.9503 39.4981 10.3612C39.4981 10.3612 39.4728 10.3739 39.4538 10.3992C37.9588 11.8878 36.4702 13.3764 34.9753 14.8714C34.9309 14.9157 34.8739 14.9601 34.8613 15.0171C34.6142 15.8026 34.3735 16.5944 34.1138 17.4305ZM43.1468 10.2725C43.5712 9.93048 43.8246 9.4934 43.7042 8.91063C43.5902 8.3912 43.2545 8.05548 42.735 7.94146C42.1586 7.81477 41.7152 8.06815 41.3731 8.49889C41.9622 9.088 42.5387 9.66444 43.1468 10.2725Z" fill="white"/>
<path d="M18.8867 21.6239C18.9564 16.1826 22.8204 11.6028 28.2047 10.6589C28.5278 10.6019 28.8509 10.5766 29.1803 10.5386C29.9341 10.4499 30.5612 10.9313 30.6435 11.6535C30.7259 12.3946 30.2064 12.99 29.4273 13.0534C25.69 13.3701 22.6431 15.9546 21.7246 19.5842C20.5147 24.3604 23.7263 29.276 28.5911 30.0931C33.2723 30.8786 37.6178 27.8824 38.5236 23.2392C38.5996 22.8465 38.6376 22.4474 38.663 22.0483C38.72 21.3009 39.2774 20.7751 39.9932 20.8131C40.709 20.8511 41.2284 21.4402 41.1968 22.175C40.9941 26.7105 37.9028 30.7836 33.6017 32.1835C27.2356 34.2486 20.6097 30.4542 19.1971 23.9107C19.0197 23.1632 18.9817 22.3841 18.8867 21.6239Z" fill="white"/>
<path d="M23.9419 21.2313C24.0243 19.2866 25.0251 17.4939 27.0458 16.2967C27.7426 15.885 28.4901 16.0497 28.8638 16.6895C29.2439 17.3356 29.0158 18.064 28.3064 18.4884C26.6531 19.4703 26.007 21.4276 26.7988 23.1126C27.3815 24.3542 28.376 25.0826 29.7506 25.203C31.1759 25.3297 32.3034 24.7596 33.1016 23.575C33.393 23.1443 33.735 22.8402 34.2735 22.8402C34.7549 22.8402 35.1349 23.0556 35.3693 23.4737C35.6164 23.9171 35.5974 24.3605 35.325 24.7913C34.4318 26.2292 33.1649 27.1667 31.5243 27.5784C27.6793 28.5413 23.8912 25.5514 23.9419 21.2313Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_949_27066" x1="0.927689" y1="47.8965" x2="22.5538" y2="-12.5492" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF2465"/>
<stop offset="1" stop-color="#3B3591"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Svg1
