import React from 'react'
import Svg1 from '../../Svgs/Medical/Svg1'

const Medical_layout4 = () => {
  return (
    <div style={{minHeight:'90vh'}} className='py-4'>
      <div className="container row m-0 mx-auto align-items-center justify-content-evenly">
        <div className="col-md-6 col-lg-5">
            <div className='medical-layout2-subdiv d-flex align-items-center justify-content-center' style={{height:'490px'}}>
                <img src={require('../../images/products/Income.png')} style={{width:'80%'}} alt="" />
            </div>
        </div>
        <div className="col-md-6">
            <button className='btn border rounded-pill fw-medium px-3 py-1' style={{color:'#18A0FB',fontSize:'13px'}}>Security</button>
            <p className='fs-1 fw-medium mt-4' style={{lineHeight:'50px'}}>Ensuring Data Privacy and <span style={{color:'#18A0FB'}}>HIPAA Compliance</span></p>
            <p style={{fontSize:'13px'}}>Protect sensitive healthcare information with encryption and meet all necessary compliance standards effortlessly.</p>

            <p className='d-flex align-items-center mt-4'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>Encrypted Data</span></p>
            <p className='d-flex align-items-center mt-3'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>HIPAA-Compliant</span></p>
        </div>
      </div>
    </div>
  )
}

export default Medical_layout4
