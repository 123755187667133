import React from 'react'
import Svg1 from '../Svgs/Inner_page2/Svg1'
import Svg2 from '../Svgs/Inner_page3/Svg2'
import Inner3_Svg1 from '../Svgs/Inner_page3/Svg1'
import Svg3 from '../Svgs/Inner_page3/Svg3'
import Svg4 from '../Svgs/Inner_page3/Svg4'

const Layout1 = () => {
  
  return (
    <div className="bg-black pb-md-5">
        <div className='inner2-Layout1 py-5 container' style={{minHeight:'100vh'}}>
        <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>IMPORTANCE</p>
      <p className='fs-1 fw-medium text-center m-0 text-white'>Why Is Logo Design Crucial for Establishing a
      <span style={{color:'#9C40F2'}}> Strong Brand Identity?</span></p>

      <div className="row m-0 mt-5">
        <div className="col-lg-3">
            <div className='p-4 inner-page3-card' style={{backgroundColor:'#2F2F2F'}}>
                <p className='mt-3'><Inner3_Svg1/></p>
                <p className='text-white m-0 py-2 fw-bold fs-3 mt-4'>First Impressions</p>
                <p className='text-white' style={{fontSize:'13px'}}>A logo is typically the first thing people notice about your brand, leaving a lasting and impactful impression.</p>
               </div>
        </div>
        <div className="col-lg-3">
            <div className='p-4 inner-page3-card' style={{backgroundColor:'#2F2F2F'}}>
                <p className='mt-3'><Svg2/></p>
                <p className='text-white m-0 py-2 fw-bold fs-3 mt-4'>Brand Recognition</p>
                <p className='text-white' style={{fontSize:'13px'}}>A distinctive logo allows people to easily identify your brand and recall it better than they do with your competitors.</p>
               </div>
        </div>
        <div className="col-lg-3">
            <div className='p-4 inner-page3-card' style={{backgroundColor:'#2F2F2F'}}>
                <p className='mt-3'><Svg3/></p>
                <p className='text-white m-0 py-2 fw-bold fs-3 mt-4'>Professional Image</p>
                <p className='text-white' style={{fontSize:'13px'}}>A thoughtfully crafted  logo helps people trust your brand, showing that you care about quality and take your business seriously.</p>
               </div>
        </div>
        <div className="col-lg-3">
            <div className='p-4 inner-page3-card' style={{backgroundColor:'#2F2F2F'}}>
                <p className='mt-3'><Svg4/></p>
                <p className='text-white m-0 py-2 fw-bold fs-3 mt-4'>Emotional Connection</p>
                <p className='text-white' style={{fontSize:'13px'}}>Logos spark emotions that help people connect with your brand, building a strong relationship that keeps your brand in their minds.</p>
               </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Layout1
