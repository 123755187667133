import React from 'react'
import { useNavigate } from 'react-router-dom'

const Ocr_layout6 = () => {
  const navigate=useNavigate()

  return (
    <div className='py-4 py-md-5'>
      <div className='ocr-layout6 container d-flex align-items-center' style={{borderRadius:'25px'}}>
        <div className='ocr-layout6-subdiv mx-auto'>
        <p className='fs-2 fw-medium text-center text-white'>Join Us in Creating Meaningful Learning Experiences</p>
        <button onClick={()=>navigate('/contact-us')} className='bg-light rounded-pill d-block mx-auto mt-5 px-4 py-3 fw-medium btn' style={{color:'#ED6C3C'}}>Contact Us</button>
        </div>
      </div>
    </div>
  )
}

export default Ocr_layout6
