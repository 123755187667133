import React from 'react'

const Svg_arrow = () => {
  return (
    <div>
      <svg width="38" height="11" viewBox="0 0 38 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.1831 6.12525C37.4645 5.84385 37.4645 5.3876 37.1831 5.10619L32.5974 0.520441C32.316 0.239036 31.8597 0.239036 31.5783 0.520441C31.2969 0.801846 31.2969 1.25809 31.5783 1.5395L35.6546 5.61572L31.5783 9.69195C31.2969 9.97335 31.2969 10.4296 31.5783 10.711C31.8597 10.9924 32.316 10.9924 32.5974 10.711L37.1831 6.12525ZM0.644531 6.3363H36.6736V4.89514H0.644531V6.3363Z" fill="currentColor"/>
</svg>
    </div>
  )
}

export default Svg_arrow
