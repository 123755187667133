import React from 'react'

const Layout2 = () => {
  return (
    <div className='container-fluid py-5' style={{backgroundColor:'#FBFBFD',minHeight:'100vh'}}>
      <div className="row m-0 justify-content-center pt-5 align-items-center" style={{height:'470px'}}>
        <div className="col-lg-2">
            <div className='p-3 animate__animated animate__zoomIn inner-divs inner-card1 d-flex flex-column justify-content-between' style={{backgroundColor:'#FDE000',borderRadius:'20px'}}>
               <div>
               <p className='fw-medium fs-4'>IT</p>
               <hr />
               </div>
                <p>Technology is everywhere! Digital marketing showcases IT solutions, making it easier for businesses to find the right support.</p>
            </div>
        </div>

        <div className="col-lg-2" style={{marginBottom:'140px'}}>
            <div className='p-3 animate__animated animate__zoomIn inner-div1 mb-5 inner-card2 inner-divs d-flex align-items-end text-white' style={{borderRadius:'20px'}}>
               
                <p>Managing money can be tricky. Financial services become clearer and more user-friendly through effective digital marketing strategies.</p>
            </div>
        </div>

        <div className="col-lg-2 mx-4" style={{marginBottom:'260px'}}>
            <div className='p-3 animate__animated animate__zoomIn inner-divs inner-card3 d-flex flex-column justify-content-between' style={{backgroundColor:'#FF5C25',borderRadius:'20px'}}>
                <div>
                <p className='fw-medium fs-4'>Retail & Ecommerce</p>
                <hr />
                </div>
                <p>Shopping should be fun and simple. Digital marketing connects buyers with exciting products, making online shopping enjoyable.</p>
            </div>
        </div>

        <div className="col-lg-2" style={{marginBottom:'120px'}}>
            <div className='p-3 animate__animated animate__zoomIn inner-div1 mb-5 inner-card4 inner-divs d-flex align-items-end text-white' style={{borderRadius:'20px'}}>
               
                <p>Health matters to everyone. Digital marketing shares valuable health information, helping people make better choices for their well-being.</p>
            </div>
        </div>

        <div className="col-lg-2">
            <div className='p-3 animate__animated animate__zoomIn inner-divs inner-card5 d-flex flex-column justify-content-between' style={{backgroundColor:'#3A4563',borderRadius:'20px'}}>
               <div>
               <p className='fw-medium fs-4 text-white mb-4'>Travel</p>
               <hr className='text-white border-2'/>
               </div>
                <p className='text-white'>Exploring new places is exciting. Digital marketing shows the best travel options, helping people plan their perfect adventures.</p>
            </div>
        </div>
      </div>

      <button className='sector-btn rounded-pill px-4 py-2 fw-medium d-block mx-auto'>Sectors</button>

      <p className='fw-medium text-center mt-5 inner-text container' style={{lineHeight:'60px'}}>Digital Marketing Services That Helps Your Brand Connect, Engage, and Grow Everyday</p>
    </div>
  )
}

export default Layout2
