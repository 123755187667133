import React, { useState } from 'react';

const Why_choose_us = () => {
  const [isHovered, setIsHovered] = useState(false);

  const data = [
    {
      heading: 'Expert Solutions',
      para: 'With years of experience, we deliver personalized services that fit your business needs and guarantee great results.',
    },
    {
      heading: 'Customer-First Approach',
      para: 'Your satisfaction is our top priority. We design our solutions to ensure your success every step of the way.',
    },
    {
      heading: 'Timely Delivery',
      para: 'Every project is completed on time, ensuring your deadlines are met without any delays or last-minute surprises.',
    },
    {
      heading: 'Cost-Effective',
      para: 'Save money without cutting quality. Our services provide excellent value while fitting your budget perfectly.',
    },
    {
      heading: 'Collaborative Teamwork',
      para: 'Our team works hand-in-hand with you, solving challenges together for better results and smarter solutions.',
    },
    {
      heading: 'Transparent Communication',
      para: 'We keep you informed throughout the process so you always know exactly what is happening with your project.',
    },
    {
      heading: 'Latest Technology',
      para: 'We use the most up-to-date tools to help your projects run faster, work smarter, and achieve better results.',
    },
    {
      heading: 'Proven Track Record',
      para: 'With hundreds of successful projects, our work speaks for itself. We are ready to support your business next.',
    },
    {
      heading: 'Long-Term Support',
      para: 'Build lasting relationships with us. We are here to support you by offering help whenever you need it.',
    },
    {
      heading: 'Risk-Free Planning',
      para: 'We handle risks to keep your business safe, letting you concentrate on growth and achieving success.',
    },
  ];

  return (
    <div
      className="row m-0"
      style={{ minHeight: '100vh' }}
    >
      <div className="div4"></div>
      <div
        className="col-lg-6 px-2 px-md-5 py-5 choose-div1"
        style={{
          backgroundColor: isHovered ? '#5A2CDA' : '#EEEFF3',
          transition: '0.6s ease',
        }}
      >
        <p
          className={`fs-1 fw-bold choose-head ${
            isHovered ? 'text-white' : 'text-dark'
          }`}
        >
          Why Choose Us
        </p>

        {data.slice(0, 5).map((x, index) => {
          return (
            <div className="row m-0 mt-5">
              <div className="col-2 main-chooseus">
                <div
                  className={`choose-us ${
                    isHovered ? 'text-dark' : 'text-white'
                  }`}
                  style={{
                    backgroundColor: isHovered ? '#EEEFF3' : '#5A2CDA',
                    transition: '0.6s ease',
                  }}
                >
                  <p className="text-center">
                    <span className="fs-1 fw-medium">{index + 1}</span>{' '}
                    <span className="fw-medium">STEP</span>
                  </p>
                </div>
                <div className="choose-us3"></div>
              </div>
              <div className={`col-10`}>
                <p
                  className={`fw-medium fs-5 m-0 mb-2 choose-head ${
                    isHovered ? 'text-white' : 'text-dark'
                  }`}
                >
                  {x.heading}
                </p>
                <p
                  className={`choose-text ${
                    isHovered ? 'text-white' : 'text-dark'
                  }`}
                >
                  {x.para}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {/* Layout2 */}
      <div
        className="col-lg-6 px-2 px-md-5 pb-5 choose-div2"
        style={{ paddingTop: '106px' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {data.slice(5, 10).map((x, index) => {
          return (
            <div className="row m-0 mt-5">
              <div className="col-2 main-chooseus">
                <div className="choose-us2">
                  <p className="text-center">
                    <span className="fs-1 fw-medium">{index + 1}</span>{' '}
                    <span className="fw-medium">STEP</span>
                  </p>
                </div>
                <div className="choose-us4"></div>
              </div>
              <div className="col-10 text-white">
                <p className="fw-medium fs-5 m-0 mb-2 choose-head2">
                  {x.heading}
                </p>
                <p className="choose-text2">{x.para}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Why_choose_us;
