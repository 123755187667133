import React from 'react'
import Svg1 from '../Svgs/Inner_page2/Svg1'

const Layout3 = () => {
  
  return (
    <div className='inner2-layout3 py-5 container'>
      <p className='fs-1 fw-medium text-center m-0'>Top Lead Generation Services</p>
      <img src={require('../images/innerpage2/Group 1171275043.png')} className='d-block mx-auto mt-3' alt="" />
      <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>Offering a wide range of lead generation services, our dedicated team works to create strategies that help businesses engage more effectively with their audience. Every service is made to help you find new leads and grow your business.</p>

      <div className="row m-0 mt-5">
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142189.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Email Marketing</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Engaging emails are delivered directly to customers to build connections and encourage interaction with your brand regularly.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142188.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Content Marketing</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Our team creates informative and entertaining content that draws in visitors and keeps them interested in your business.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142186.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Social Media Marketing</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Using popular social platforms, we help you connect with audiences, share content, and improve engagement for better visibility.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142187.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Search Engine Optimization (SEO)</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Our SEO services enhance your website's visibility in search results, ensuring customers can easily discover you.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142189.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Pay-Per-Click Advertising</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>We create targeted ads that appear when potential customers search online, driving traffic to your website quickly.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142188.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Referral Programs</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Providing structured programs that motivate your existing customers to refer friends and family, bringing in fresh leads naturally.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142186.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Webinars and Online Events</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Hosting informative webinars attracts interested audiences, and we help showcase what you offer and establish your brand as a trusted source.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142187.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold' style={{height:'80px'}}>Analytics and Reporting</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Get simple, easy-to-understand insights into your lead generation efforts, allowing you to make smarter decisions with confidence.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Layout3
