import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1097_15353)">
<path d="M12.9307 2.19482C18.4537 2.19482 22.9307 6.67182 22.9307 12.1948C22.9307 17.7178 18.4537 22.1948 12.9307 22.1948C7.40766 22.1948 2.93066 17.7178 2.93066 12.1948C2.93066 6.67182 7.40766 2.19482 12.9307 2.19482ZM12.9307 4.19482C10.8089 4.19482 8.7741 5.03768 7.27381 6.53797C5.77352 8.03826 4.93066 10.0731 4.93066 12.1948C4.93066 14.3166 5.77352 16.3514 7.27381 17.8517C8.7741 19.352 10.8089 20.1948 12.9307 20.1948C15.0524 20.1948 17.0872 19.352 18.5875 17.8517C20.0878 16.3514 20.9307 14.3166 20.9307 12.1948C20.9307 10.0731 20.0878 8.03826 18.5875 6.53797C17.0872 5.03768 15.0524 4.19482 12.9307 4.19482ZM16.4657 8.57582C16.6452 8.39457 16.8872 8.28879 17.1422 8.28014C17.3972 8.27149 17.6458 8.36062 17.8372 8.52929C18.0287 8.69796 18.1484 8.93341 18.1719 9.18745C18.1954 9.44149 18.1209 9.6949 17.9637 9.89582L17.8807 9.98982L12.2947 15.5768C12.104 15.7675 11.85 15.8814 11.5808 15.8971C11.3117 15.9128 11.0462 15.829 10.8347 15.6618L10.7387 15.5768L7.98066 12.8188C7.79941 12.6393 7.69363 12.3973 7.68498 12.1423C7.67633 11.8873 7.76546 11.6387 7.93413 11.4472C8.1028 11.2558 8.33825 11.1361 8.59229 11.1126C8.84633 11.0891 9.09974 11.1636 9.30066 11.3208L9.39466 11.4048L11.5167 13.5258L16.4667 8.57582H16.4657Z" fill="#18A0FB"/>
</g>
<defs>
<clipPath id="clip0_1097_15353">
<rect width="24" height="24" fill="white" transform="translate(0.930664 0.194824)"/>
</clipPath>
</defs>
</svg>

    </div>
  )
}

export default Svg1
