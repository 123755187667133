import React from 'react'

const Task_layout7 = () => {
  return (
    <div>
      <div className="container py-5 row m-0 mx-auto align-items-center">
       <div className='col-md-6 col-lg-5 mx-auto'>
        <img className='me-1' src={require('../../images/products/Frame 14 (1).png')} alt="" />
       <p className='fs-1 fw-bold mt-3' style={{letterSpacing:'1px',lineHeight:'54px'}}>Looking to simplify <span className='task-text-color'>task management?</span></p>
       <p className='text-secondary  mt-3 mt-md-4' style={{fontSize:'15px'}}>Discover how our task manager service keeps your team organized and projects on track.</p>
  <button className='task-manager-gardient-buttons btn text-dark bg-white rounded-pill fw-medium mt-4'>Get Notified</button>

       
       </div>
       <div className="col-md-6">
       <img src={require('../../images/products/Hero Content.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Task_layout7
