import React from 'react'
import Check from '../../Svgs/Products/Check'

const Task_layout6 = () => {
  return (
    <div>
      <div className="container pb-5 row m-0 mx-auto align-items-center">

       <div className='col-md-6 col-lg-5 mx-auto'>
       <img className='me-1' src={require('../../images/products/meridaicon (1).png')} style={{width:'10%'}} alt="" />
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Integration with <span className='task-text-color'>Other Apps</span></p>
       <p className='text-secondary  mt-3 mt-md-4' style={{fontSize:'15px'}}>Connect effortlessly with your favorite apps for enhanced task management and smooth workflows across all platforms.</p>
  <button className='task-manager-gardient-buttons btn text-dark bg-white rounded-pill fw-medium mt-4'>Discover More</button>

       
       </div>
       <div className="col-md-6">
       <img src={require('../../images/products/Frame 18.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Task_layout6
