import React from 'react'

const Mobile_app_layout4 = () => {
  return (
    <div className='d-flex align-items-center py-4'>
      <div className="row mx-auto m-0 align-items-center mobile-app-layout4 justify-content-center">
        <div className="col-md-6 p-5">
            <h3 className='fs-1 text-white'>Got an amazing app idea but unsure where to begin?</h3>
            <p className='text-white mt-5' style={{fontSize:'15px'}}>Our dedicated team of experts are here to help you turn your innovative ideas into a functional app. With personalized guidance and a passion for innovation, we’ll work closely with you and ensure every detail reflects your unique vision and goals. </p>
<button className='btn rounded-pill text-transparent px-4 py-3 mt-4' style={{backgroundColor:'#ffff',fontSize:'13px'}}>Learn More</button>
        </div>

        <div className="col-md-6 col-lg-5 text-end pt-5">
            <img src={require('../images/innerpage4/Img.png')} style={{width:'80%'}} alt="" />
           </div>
      </div>
    </div>
  )
}

export default Mobile_app_layout4
