import React from 'react'

const Background_layout2 = () => {

  const data=[
    {
      heading:"Identity Verification",
      para:"Reviewing IDs to confirm who you say you are.",
      img:"/asset/Icon.png"
    },
    {
      heading:"Employment Checks",
      para:"Verifying past jobs to ensure accurate candidate histories.",
      img:"/asset/Icon (6).png"
    },{
      heading:"Criminal Record Checks",
      para:"Looking for any legal issues to ensure safe hiring.",
      img:"/asset/Icon (7).png"
    },{
      heading:"Education Validation",
      para:"Confirming educational backgrounds to ensure qualifications are genuine.",
      img:"/asset/Icon (8).png"
    },
  ]
  
  return (
    <div className='row m-0 container mx-auto py-4 py-md-5'>
      <div className='mb-5'>
      <button className='p-2 px-3 btn rounded fw-medium m-0 d-block mx-auto' style={{color:'#36B37E',backgroundColor:'#E6F7F2'}}>SERVICES</button>
       <p className="text-center fw-bold fs-2 mt-3" style={{color:'#183B56'}}>Know Your Candidates Better with our Verification Solutions</p>
       <p className='text-center' style={{fontSize:'13px',color:'#5A7184'}}>Personalized services designed to support your verification needs effectively.</p>
      </div>

      {data.map((x)=>{
        return(
          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
       <div>
       <img src={x.img} alt="" className='d-block mx-auto mb-4' style={{width:'14%'}}/>
        <p className='fw-medium text-center mb-2' style={{color:'#183B56'}}>{x.heading}</p>
        <p className='text-center' style={{fontSize:'13px',color:'#5A7184',lineHeight:'20px'}}>{x.para}</p>
       </div>
      </div>
        )
      })}
    </div>
  )
}

export default Background_layout2
