import React, { useEffect, useState } from 'react'
import { blogs } from '../Contents/blogs'
import Navbar from '../Landingpages/Navbar'
import { useNavigate } from 'react-router-dom'
import Footer from '../Landingpages/Footer'
import Scrolltotop from '../Scrolltotop'
import Backtotop from '../Backtotop'
import axios from 'axios'

const All_blogs = () => {

  const navigate=useNavigate()

  let [webblogs, setblogs] = useState([])
  
  useEffect(()=>{
  axios.get("https://backendapi.meridatechminds.com/api/blog/")
  .then((r)=>{
    console.log("Blogs",r.data)
    setblogs(r.data)
  })
  },[])
  
  const date_function = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return { day, month, year };
  };

  return (
    <div className='animate__animated animate__fadeIn'>
      <Navbar/>
      <div className='blogs-banner'>
        <h3 className='fw-bold text-white text-center'>Our Blogs</h3>
      </div>

      <div className='py-5 container'>
        <p className='fs-3 fw-bold text-center mb-5'>Explore Latest <span style={{color:'#5A2CDA'}} className='ms-1'>Blogs & News</span></p>

       {webblogs.map((x,index)=>{
        const {day,month,year}=date_function(x.created_at)
        return(
            <div className="row m-0 align-items-center mb-4" style={{cursor:'pointer'}} onClick={()=>{
              navigate(`/blog/${x.slug}`)
            }}>
            <div className="col-md-4 p-0">
                <img src={x.img} className='blogs-img' alt="" />
            </div>
            <div className="col-md-8 px-2 px-lg-5 py-3 blog-subdiv">
                <div style={{fontSize:'13px'}} className='d-flex align-items-center justify-content-between'>
                    {/* <span>{x.category}</span> */}
                    <span>Oct 09 2024</span>
                </div>
                <p className='fw-bold fs-4 mt-2 mb-2'>{x.Main_Title}</p>
                <span style={{fontSize:'13px'}}>{x.Paragraph1 && x.Paragraph1.slice(0,400)}</span>
                <div className='mt-3 d-flex justify-content-between align-items-center'>
                    <button onClick={()=>{
              navigate(`/blog/${x.slug}`)
            }} className='btn btn-sm rounded-pill text-white px-3' style={{backgroundColor:'#5A2CDA'}}>Read More</button>
                    <img src={require('../images/blogs/Vector 39.png')} className='d-none d-lg-inline' style={{width:'80%'}} alt="" />
                </div>
            </div>
        </div>
        )
       })}
      </div>
      <Footer/>
      <Scrolltotop/>
      <Backtotop/>
    </div>
  )
}

export default All_blogs
