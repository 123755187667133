import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="45" height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.1528 1.26034L14.4637 10.7947C-4.82123 17.2463 -4.82123 27.7659 14.4637 34.1857L22.9783 37.0142L25.8059 45.5316C32.2236 64.8228 42.7715 64.8228 49.1892 45.5316L58.7522 16.8649C63.0095 3.99352 56.0199 -3.03013 43.1528 1.26034ZM44.1694 18.3586L32.0965 30.4991C31.6199 30.9758 31.0163 31.1983 30.4126 31.1983C29.809 31.1983 29.2054 30.9758 28.7288 30.4991C27.8074 29.5774 27.8074 28.0519 28.7288 27.1303L40.8017 14.9898C41.7231 14.0682 43.2481 14.0682 44.1694 14.9898C45.0908 15.9115 45.0908 17.437 44.1694 18.3586Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg1
