import React from 'react'

const Mobile_app_layout1 = () => {
  return (
    <div className='mobile-app-layout1 d-flex align-items-center py-5'>
      <div className="row mx-auto m-0 container align-items-center py-5">
        <div className="col-md-6 text-end" style={{position:'relative'}}>
            <img src={require('../images/innerpage4/iPhone 14 Pro.png')} style={{width:'50%'}} alt="" />
            <img src={require('../images/innerpage4/Body.png')} style={{width:'46%',position:'absolute',left:'30%',bottom:0}} alt="" />
        </div>
        <div className="col-md-6 ps-md-5">
            <h3 className='fs-1'>Building Mobile Apps To Fit Your Business Perfectly</h3>
            <p className='text-secondary mt-5' style={{fontSize:'15px'}}>Mobile app development services that are dedicated to creating functional and user-friendly applications customized to meet specific business needs. Each of our projects emphasize sleek design and smooth functionality, ensuring a reliable experience for all users.</p>
            <p className='text-secondary mt-3' style={{fontSize:'15px'}}>The focus is on delivering apps that not only perform well but also provide value and ease of use for everyone involved.</p>
<button className='btn rounded-pill text-white px-4 py-3 mt-4' style={{backgroundColor:'#0628EE',fontSize:'13px'}}>Discover More</button>
        </div>
      </div>
    </div>
  )
}

export default Mobile_app_layout1
