import React from 'react'
import Svg1 from '../../Svgs/Onlientest/Svg1'

const Onlinetest_layout3 = () => {
    const data=[
        {
            heading:"Limited Accessibilitys",
            para:"Students must be present at a physical location, restricting options and flexibility."
        },
        {
            heading:"Higher Chance of Cheating",
            para:"Monitoring is challenging in-person, making it hard to prevent dishonest behavior."
        }, 
        {
            heading:"Delayed Results",
            para:"Results often take time to process, causing delays in feedback and next steps."
        }, 
        {
            heading:"Accessible Anytime, Anywhere",
            para:"Tests can be taken on various devices, enabling easy access from anywhere."
        },
        {
            heading:"No Cheating Assurance",
            para:"Built-in anti-cheat mechanisms ensure integrity during assessments, enhancing reliability."
        },
        {
            heading:"Instant Results Provided",
            para:"Receive immediate results after the test, helping you make quick and informed decisions."
        },
    ]

  return (
    <div className='onlinetest-layout3 py4 py-md-5'>
         <div className='onlinetest-layout3-subdiv mx-auto py-4 py-md-5'>
            <p className='text-white fw-medium fs-1 text-center mt-md-5'>Why Choose Online Testing Over Traditional?</p>
            <p className="text-white text-center" style={{letterSpacing:'1px',lineHeight:'28px'}}>
            Landy lets you take control of your money, balance your income and expenses, and understand where your money goes.
            </p>
        </div>
      <div className="container row m-0 mx-auto justify-content-evenly">
        <div className="col-md-4 col-lg-3 pe-md-0 d-flex flex-column justify-content-between">
           {data.slice(0,3).map((x)=>{
            return(
                <div className='text-end py-4'>
                <Svg1/>
                <p className='fw-bold text-white mt-3'>{x.heading}</p>
                <p className='' style={{fontSize:'14px',color:'#FAFAFB'}}>{x.para}</p>
            </div>
            )
           })}
        </div>

        <div className="col-md-4 col-lg-3">
            <img src={require('../../images/mtm_images/online test 2.png')} style={{width:'100%'}} alt="" />
            </div>

            <div className="col-md-4 col-lg-3 ps-md-0 d-flex flex-column justify-content-between">
           {data.slice(3,6).map((x)=>{
            return(
                <div className='text-start py-4'>
                <Svg1/>
                <p className='fw-bold text-white mt-3'>{x.heading}</p>
                <p className='' style={{fontSize:'14px',color:'#FAFAFB'}}>{x.para}</p>
            </div>
            )
           })}
        </div>
      </div>
    </div>
  )
}

export default Onlinetest_layout3
