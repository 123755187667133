import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Background_layout4 = () => {
  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
      <div className="col-md-6 text-center">
       <img src={require('../../images/mtm_images/bg verification 3.png')} className='' style={{width:'90%'}} alt="" />
       </div>

       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Advantages of Our Background Checks</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Gaining insights into candidates leads to informed hiring choices.</p>

       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Enhanced hiring confidence</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Reduced hiring risks</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Improved organizational integrity</span></p>

       
       </div>
      </div>
    </div>
  )
}

export default Background_layout4
