import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"How much time does it usually take to develop a mobile app?",
            answer:"The development time depends on your app’s features and complexity. On average, it takes 2-4 months to build a functional, customized app."
        },
        {
            question:" Which platforms do you develop mobile apps for?",
            answer:"We develop apps for both Android and iOS, ensuring they work smoothly across smartphones and tablets. You can choose one or go for cross-platform apps."
        },
        {
            question:" Do I need to have technical knowledge to get an app developed?",
            answer:"Not at all! Our team will guide you through every step, from idea to delivery, ensuring your vision is perfectly implemented."
        },
        {
            question:"How much does it cost to develop a mobile app?",
            answer:"The cost varies based on features, platform, and development time. At Merida Tech Minds, we provide custom quotes to fit your specific business needs and budget."
        },
        {
            question:"Will the app be easy for users to navigate?",
            answer:"Absolutely! We focus on mobile UI/UX design to create user-friendly, intuitive interfaces for a smooth experience."
        },
        {
            question:"Can you update my app after it’s launched?",
            answer:"Yes! We offer ongoing maintenance and support services to keep your app up-to-date with bug fixes and new features."
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
