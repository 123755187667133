import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"How does graphic design help my business?",
            answer:"Graphic design enhances your personal brand, makes it memorable, and helps you connect with customers in a fun, creative way."
        },
        {
            question:"Why is a strong brand identity important?",
            answer:"A strong brand identity builds trust and loyalty, helping customers feel connected and excited about what your business offers."
        },
        {
            question:"How long does the graphic design process take?",
            answer:"We work efficiently to create stunning designs. Although the timeline varies, we aim to deliver your amazing visuals as soon as possible."
        },
        {
            question:"What if I don’t like the designs?",
            answer:"Your satisfaction matters! We’ll gladly refine the designs based on your feedback until they perfectly match your vision and requirements."
        },
        {
            question:"Do I own the designs once they are completed?",
            answer:"Yes, once the designs are finalized, they are entirely yours. Use them however you like and showcase your brand proudly."
        },
        {
            question:"How can I make the most of graphic design services?",
            answer:"Share your ideas and goals with our professional graphic designers at Merida Tech Minds. The more we know, the better we can create designs that truly resonate with your brand's story."
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
