import React from 'react'
import Svg1 from '../../Svgs/Payroll_management/Svg1'

const Payroll_layout5 = () => {
  return (
    <div className='p-3'>
      <div className='payroll-layout2 py-3 py-md-5 d-flex align-items-center' style={{minHeight:'56vh'}}>
        <div className='payroll-layout5-div mx-auto'>
        <p className='text-white fs-2 fw-medium text-center'>Your Payroll Management Made Simple and Reliable</p>
        <p className='text-secondary text-center' style={{fontSize:'14px'}}>Discover how our software ensures smooth payroll processes. Reach out to learn more!</p>

        <button className='mt-5 payroll-mainbtn d-block mx-auto btn rounded-pill px-4 border-0 text-white fw-medium d-flex align-items-center py-3'><span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></span>Contact Us</button>
        </div>
      </div>
    </div>
  )
}

export default Payroll_layout5
