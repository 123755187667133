import React from 'react';
import myvideo from '../images/newVideos/brand_building.mp4';
// import myvideo from '../images/videos/brand_building.mp4'
import Inner_page2 from '../Inner_page2/Inner_page2';
import Logo_design from '../Inner_page3/Logo_design';
import Layout4 from '../Inner_page1/Layout4';
import Layout5 from '../Inner_page1/Layout5';
import Client_reviews from '../Landingpages/Client_reviews';
import News_and_events from '../Landingpages/News_and_events';
import FAQ from './FAQ';
import Contact_us_layout from '../Landingpages/Contact_us_layout';
import Blogs from '../Landingpages/Blogs';
import Footer from '../Landingpages/Footer';
import Layout1 from '../Inner_page4/Layout1';
import Inner4_layout4 from '../Inner_page4/Inner4_layout4';
import Layout2 from '../Inner_page2/Layout2';
import Navbar from '../Landingpages/Navbar';
import Backtotop from '../Backtotop';
import Scrolltotop from '../Scrolltotop';
import Brand_building_layout1 from './Brand_building_layout1';
import Brand_building_layout2 from './Brand_building_layout2';
import Brand_building_layout3 from './Brand_building_layout3';

const Brand_building = () => {
  return (
    <div>
      <div className="video-background">
        <Navbar />
        <video
          autoPlay
          muted
          loop
          className="background-video"
        >
          <source
            src={myvideo}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
      <Brand_building_layout1 />
      <Brand_building_layout2 />
      <Layout4 />
      <Brand_building_layout3 />
      <Layout5 />
      <Client_reviews />
      <News_and_events />
      <FAQ />
      <Contact_us_layout />
      <Blogs />
      <Backtotop />
      <Scrolltotop />
    </div>
  );
};

export default Brand_building;
