import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Background_layout2 from './Background_layout2'
import Background_layout3 from './Background_layout3'
import Background_layout4 from './Background_layout4'
import Background_layout5 from './Background_layout5'
import Background_layout6 from './Background_layout6'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Background_verification = () => {
  const navigate=useNavigate()

  return (
    <div>
       <Helmet>
        <title>Reliable Background Verification Services for Secure Hiring</title>
        <meta name="description" content="Ensure a safe workforce with our thorough background verification services. We provide reliable checks for identity, employment, criminal records, and more." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Thorough Background Verification Checks for Confident Hiring Decisions</h1>
      <h2 className="d-none">Benefits of Background Verification Checks</h2>

      <div>
      <div className='background-verification'>
        <Navbar/>
       <div className="background-verification-subdiv mx-auto pt-4 pt-md-5">
       <p className='fs-1 fw-medium text-white text-center' style={{letterSpacing:'1px'}}>Expertise you can count on for all your Background Verification needs</p>
        <p className='text-white text-center' style={{fontSize:'16px'}}>Thorough verification is crucial for ensuring a safe and reliable workforce. Trust in your hiring decisions with our processes!</p>
        <button onClick={()=>navigate('/contact-us')} className='btn btn-light rounded-pill px-4 py-3 fw-medium d-block mx-auto mt-4' style={{color:'#1565D8',fontSize:'14px'}}>Get Started</button>

        <img src={require('../../images/mtm_images/bg verification 1.png')} style={{width:'100%',marginTop:'120px'}} alt="" />
       </div>
      </div>

      <Background_layout2/>
      <Background_layout3/>
      <Background_layout4/>
      <Background_layout5/>
      <Background_layout6/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Background_verification
