import React from 'react'
import Svg2 from '../Svgs/Inner_page4/Svg2'
import Svg3 from '../Svgs/Inner_page4/Svg3'
import Svg4 from '../Svgs/Inner_page4/Svg4'
import Svg1 from '../Svgs/Inner_page4/Svg1'

const Layout1 = () => {
  
  return (
    <div className="bg-black pb-md-5">
        <div className='inner2-Layout1 py-5 container' style={{minHeight:'100vh'}}>
        <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>IMPORTANCE</p>
      <p className='fs-1 fw-medium text-center m-0 text-white'>What Makes a <span style={{color:'#9C40F2'}}>Website Experience</span> Truly Impressive?</p>

      <div className="row m-0 mt-5">
        <div className="col-md-6 mb-4">
            <div className='p-3 p-md-5 inner-page4-card d-flex align-items-center justify-content-between' style={{borderRadius:'15px'}}>
                <p className='text-white m-0 fw-bold fs-3'>Simple User Interface</p>
                <p className='m-0 h-25 inner-page4-svg'><Svg1/></p>
                </div>
        </div>
        <div className="col-md-6 mb-4">
            <div className='p-3 p-md-5 inner-page4-card d-flex align-items-center justify-content-between' style={{borderRadius:'15px'}}>
                <p className='text-white m-0 fw-bold fs-3'>Responsive Mobile Layout</p>
                <p className='m-0 h-25 inner-page4-svg'><Svg2/></p>
                </div>
        </div>
        <div className="col-md-6 mb-4">
            <div className='p-3 p-md-5 inner-page4-card d-flex align-items-center justify-content-between' style={{borderRadius:'15px'}}>
                <p className='text-white m-0 fw-bold fs-3'>Fast Loading Speed</p>
                <p className='m-0 h-25 inner-page4-svg'><Svg3/></p>
                </div>
        </div>
        <div className="col-md-6 mb-4">
            <div className='p-3 p-md-5 inner-page4-card d-flex align-items-center justify-content-between' style={{borderRadius:'15px'}}>
                <p className='text-white m-0 fw-bold fs-3'>Secure Data Management</p>
                <p className='m-0 h-25 inner-page4-svg'><Svg4/></p>
                </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Layout1
