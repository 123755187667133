import React from 'react'

const CRM_layout3 = () => {
  return (
    <div className='py-5'>
      <div className="row m-0 container mx-auto justify-content-center">
        <div className="col-md-6">
            <img src={require('../../images/mtm_images/crm 2.jpg')} style={{width:'90%'}} alt="" />
        </div>
        <div className="col-lg-5">
            <p className='crm-layout3-text'>BOOST EFFICIENCY</p>
            <p className='fs-1 fw-bold' style={{lineHeight:'54px'}}>Benefits of Integrating a CRM System in Your Business</p>
            <p style={{fontSize:'13px'}}>Implementing a CRM system offers valuable benefits that enhance your business operations and strengthen relationships with your customers.</p>

            <p style={{fontSize:'13px'}} className='mt-4 pt-2'><img className='me-3' src={require('../../images/products/icon-ceklist-circle.png')} alt="" />Understand what your customers want and need for better service.</p>
            <p style={{fontSize:'13px'}}><img className='me-3' src={require('../../images/products/icon-ceklist-circle.png')} alt="" />Increase sales and improve conversion rates effectively over time.</p>
            <p style={{fontSize:'13px'}}><img className='me-3' src={require('../../images/products/icon-ceklist-circle.png')} alt="" />Manage customer interactions and support inquiries with ease.</p>
        </div>
      </div>

      <div className="row m-0 container mx-auto justify-content-center mt-5 pt-5">
    
        <div className="col-lg-5">
            <p className='crm-layout3-text'>IN-DEPTH DATA INSIGHTS</p>
            <p className='fs-1 fw-bold' style={{lineHeight:'54px'}}>Advanced CRM: Simple Insights for Smart Decisions</p>
            <p style={{fontSize:'13px'}}>With our advanced CRM tools, gain clear insights into customers, enabling businesses to make smarter, data-driven decisions and enhance relationships.</p>

            <div className='row m-0 mt-5'>
                <div className="col-md-6">
                <img className='d-block' src={require('../../images/products/icon (1).png')} alt="" />
                <p className='m-0 mt-2 mb-1 fw-medium' style={{fontSize:'15px'}}>Dynamic Dashboard</p>
                <p style={{fontSize:'13px'}}>Seamlessly merging numerous reports into one visually stunning interface.</p>
                </div>

                <div className="col-md-6">
                <img className='d-block' src={require('../../images/products/icon (2).png')} alt="" />
                <p className='m-0 mt-2 mb-1 fw-medium' style={{fontSize:'15px'}}>Continuous Data Sync </p>
                <p style={{fontSize:'13px'}}>Keep all your data updated and accurate across systems automatically.</p>
                </div>
            </div>
            </div>
        <div className="col-md-6 text-end">
            <img src={require('../../images/mtm_images/crm 3.jpg')} style={{width:'90%'}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default CRM_layout3
