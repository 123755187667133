import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Background_layout5 = () => {
  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Why Partner with Merida Tech Minds?</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>We provide thorough Background Verification services, ensuring that your hiring process is secure and trustworthy. </p>

       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Experienced professionals at your service.</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Customized solutions for unique needs.</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Commitment to accuracy & confidentiality.</span></p>

       
       </div>
       <div className="col-md-6 text-center">
       <img src={require('../../images/mtm_images/bg verification 4.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Background_layout5
