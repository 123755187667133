import React from 'react'

const Inner3_Svg1 = () => {
  return (
    <div>
      <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.1224 14.3217C9.93661 -11.2915 -11.2942 9.93934 14.3153 36.1251C-11.2942 62.3147 9.93287 83.5417 36.1224 57.9285C62.3119 83.5417 83.539 62.3109 57.9258 36.1251C83.539 9.93559 62.3082 -11.2915 36.1224 14.3217ZM36.1224 48.3292C21.4633 62.6665 9.57734 50.7842 23.9146 36.1251C9.57734 21.466 21.4633 9.58006 36.1224 23.9173C50.7815 9.58006 62.6637 21.466 48.3265 36.1251C62.6637 50.7842 50.7815 62.6665 36.1224 48.3292Z" fill="white" stroke="#2F2F2F" stroke-width="4.5"/>
</svg>

    </div>
  )
}

export default Inner3_Svg1
