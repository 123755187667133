import React from 'react'
import Svg1 from '../../Svgs/Medical/Svg1'

const Medical_layout2 = () => {
  return (
    <div style={{minHeight:'100vh'}} className='py-4 py-md-5'>
        <div className='medical-layout2-div mx-auto pt-md-5 pb-5'>
            <p className='fs-1 fw-medium text-center'>Billing Tools Built for Every Medical Practice</p>
            <p className='text-secondary text-center' style={{fontSize:'14px'}}>Our billing software offers efficient solutions for invoicing, payment tracking, and compliance, designed to simplify healthcare operations.</p>
        </div>
      <div className="container row m-0 mx-auto align-items-center justify-content-evenly">
        <div className="col-md-6 col-lg-5">
            <div className='medical-layout2-subdiv d-flex align-items-center justify-content-center' style={{height:'490px'}}>
                <img src={require('../../images/products/Income.png')} style={{width:'80%'}} alt="" />
            </div>
        </div>
        <div className="col-md-6">
            <button className='btn border rounded-pill fw-medium px-3 py-1' style={{color:'#18A0FB',fontSize:'13px'}}>Automation</button>
            <p className='fs-1 fw-medium mt-4' style={{lineHeight:'50px'}}>Automating Payments and <span style={{color:'#18A0FB'}}>Reducing Errors</span></p>
            <p style={{fontSize:'13px'}}>Say goodbye to manual errors with automated recurring payments, ensuring smooth reimbursements and improved accuracy.</p>

            <p className='d-flex align-items-center mt-4'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>Faster Payments</span></p>
            <p className='d-flex align-items-center mt-3'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>Reduced Errors</span></p>
        </div>
      </div>
    </div>
  )
}

export default Medical_layout2
