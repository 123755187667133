import React from 'react'
import Svg1 from '../../Svgs/Algo/Svg1'

const Algo_layout5 = () => {
  return (
    <div className='container row mx-auto m-0 justify-content-around align-items-center' style={{minHeight:'100vh'}}>
      <div className="col-md-6 col-lg-5">
        <p className='fs-2 fw-medium'>Benefits of Algo Trading</p>
        <p className='text-secondary' style={{fontSize:'13px'}}>Algo trading simplifies strategies for smooth execution and safer trades every time.</p>

        <p className='d-flex align-items-center mt-5'><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Outperforms manual trading with precise execution and speed.</span></p>
        <p className='d-flex align-items-center mt-4 pt-3'><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Lowers risks through automatic risk-control systems.</span></p>
        <p className='d-flex align-items-center mt-4 pt-3' pt-3><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Keeps trading active without human intervention or breaks.</span></p>
      </div>
      <div className="col-md-6 col-lg-5">
        <div style={{backgroundColor:'#EBF5FD'}} className='p-4 rounded text-center'>
            <img src={require('../../images/products/element 1.png')} style={{width:'100%'}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Algo_layout5
