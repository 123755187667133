import React from 'react';
// import myvideo from '../images/videos/Software_consulting.mp4';
import myvideo from '../images/newVideos/Software_consulting.mp4';
import Inner_page2 from '../Inner_page2/Inner_page2';
import Logo_design from '../Inner_page3/Logo_design';
import Layout4 from '../Inner_page1/Layout4';
import Layout5 from '../Inner_page1/Layout5';
import Client_reviews from '../Landingpages/Client_reviews';
import News_and_events from '../Landingpages/News_and_events';
import FAQ from './FAQ';
import Contact_us_layout from '../Landingpages/Contact_us_layout';
import Blogs from '../Landingpages/Blogs';
import Footer from '../Landingpages/Footer';
import Layout1 from '../Inner_page4/Layout1';
import Inner4_layout4 from '../Inner_page4/Inner4_layout4';
import Layout2 from '../Inner_page2/Layout2';
import Navbar from '../Landingpages/Navbar';
import Software_layout1 from './Software_layout1';
import Software_layout2 from './Software_layout2';
import Software_layout3 from './Software_layout3';
import Backtotop from '../Backtotop';
import Scrolltotop from '../Scrolltotop';
import { Helmet } from 'react-helmet';
// import Mobile_app_layout1 from './Mobile_app_layout1'
// import Mobile_app_layout2 from './Mobile_app_layout2'
// import Mobile_app_layout3 from './Mobile_app_layout3'
// import Mobile_app_layout4 from './Mobile_app_layout4'

const Software_consulting = () => {
  return (
    <div>
      <Helmet>
        <title>
          Actual Sofware Developement Service for Your Business Needs
        </title>
        <meta
          name="description"
          content="Get our best software developement services tailored for your business needs with morden technologies and innovative software design that ft to the customers."
        />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <div>
        <h1 className="d-none">custom software development</h1>
        <h2 className="d-none">software development services</h2>
        <div className="video-background">
          <Navbar />
          <video
            autoPlay
            muted
            loop
            className="background-video"
          >
            <source
              src={myvideo}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <Software_layout1 />
        <Software_layout2 />
        <Software_layout3 />
        <Layout4 />
        <Layout5 />
        <Client_reviews />
        <News_and_events />
        <FAQ />
        <Contact_us_layout />
        <Blogs />
        <Backtotop />
        <Scrolltotop />
      </div>
    </div>
  );
};

export default Software_consulting;
