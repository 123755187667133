import React, { useEffect, useState } from 'react';
import { blogs } from '../Contents/blogs';
import Navbar from '../Landingpages/Navbar';
import { useNavigate, useParams } from 'react-router-dom';
import Bullet_point from '../Svgs/Bullet_point';
import Footer from '../Landingpages/Footer';
import Scrolltotop from '../Scrolltotop';
import Backtotop from '../Backtotop';
import axios from 'axios';

const View_blogs = () => {
  const { slug } = useParams();

  const navigate = useNavigate();
  const [blog_details, setblog_details] = useState({});
  const [webblogs, setwebblogs] = useState([]);

  const [day, setday] = useState('');
  const [month, setmonth] = useState('');
  const [year, setyear] = useState('');

  const fetch_blogs = () => {
    axios.get('https://backendapi.meridatechminds.com/api/blog/').then(r => {
      console.log('Blogs', r.data);
      setwebblogs(r.data);
      const filtered_blog = r.data.find(x => x.slug === slug);
      console.log('Blog details', filtered_blog);
      setblog_details(filtered_blog);
      const { day, month, year } = date_function(filtered_blog.created_at);
      setday(day);
      setmonth(month);
      setyear(year);
    });
  };

  useEffect(() => {
    fetch_blogs();
  }, [slug]);

  const date_function = dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return { day, month, year };
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Navbar />
      <div className="blogs-banner">
        <h3 className="fw-bold text-white text-center">
          {blog_details.Main_Title}
        </h3>
      </div>

      <div className="py-5 container row m-0 mx-auto">
        <div className="col-lg-8 ps-md-0">
          <img
            src={blog_details.img}
            style={{ width: '100%', borderRadius: '20px' }}
            alt=""
          />
          <span
            style={{ fontSize: '13px' }}
            className="mt-3 d-block"
          >
            {day} {month} {year}
          </span>

          <p
            style={{ color: '#5a2cda' }}
            className="mt-4 fw-bold fs-5"
          >
            {blog_details.Main_Title}
          </p>
          <p style={{ fontSize: '14px' }}>{blog_details.Paragraph1}</p>

          {blog_details.Text_Content &&
            blog_details.Text_Content.map(x => {
              return (
                <>
                  <p className="fw-bold fs-5 mt-5">{x.Heading} :</p>
                  <p style={{ fontSize: '14px' }}>{x.Paragraph}</p>
                </>
              );
            })}

          <div className="row m-0 py-4">
            <div className="col-md-6 ps-md-0">
              <img
                src={require('../images/blogs/Rectangle 45009.png')}
                style={{ width: '100%', borderRadius: '15px' }}
                alt=""
              />
            </div>
            <div className="col-md-6 pe-md-0">
              <img
                src={require('../images/blogs/Rectangle 45015.png')}
                style={{ width: '100%', borderRadius: '15px' }}
                alt=""
              />
            </div>
          </div>

          {/* <div className='py-3'>
    <p className='fw-bold fs-4 mb-4'>What You Will Do :</p>

    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>Vestibulum in ipsum velit. Aliquam libero sem asfds asf, rutrum eu scelerisque </span></p>
    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>ut, vehicula a erat. Phasellus ac sem sed erat pos se quam dignissim. Mauris </span></p>
    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>Vestibulum in ipsum velit. Aliquam libero sem asfds asf, rutrum eu scelerisque </span></p>
    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>ut, vehicula a erat. Phasellus ac sem sed erat pos se quam dignissim. Mauris </span></p>
    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>Vestibulum in ipsum velit. Aliquam libero sem asfds asf, rutrum eu scelerisque </span></p>
    <p className='d-flex align-items-start'><img src={require('../images/blogs/Vector.png')} width={18} alt="" /> <span className='ms-2' style={{fontSize:'13px'}}>ut, vehicula a erat. Phasellus ac sem sed erat pos se quam dignissim. Mauris </span></p>
 </div> */}
        </div>

        <div className="col-lg-4 pe-md-0 ps-md-4">
          <div
            className="p-3 rounded"
            style={{ backgroundColor: '#F5EBFF' }}
          >
            <p className="fw-bold fs-5 my-4">Recent Blogs</p>

            {console.log('MUIZ', webblogs)}

            {webblogs.slice(0, 5).map(x => {
              const { day, month, year } = date_function(x.created_at);
              return (
                <div className="row m-0 mb-3">
                  <div className="col-5 ps-0">
                    <img
                      src={x.img}
                      style={{ width: '100%', borderRadius: '6px' }}
                      alt=""
                    />
                  </div>
                  <div className="col-7">
                    <p
                      className="m-0 mb-2"
                      style={{ fontSize: '11px' }}
                    >
                      {day} {month} {year}
                    </p>
                    <p
                      className="fw-medium"
                      style={{ fontSize: '12px', cursor: 'pointer' }}
                      onClick={() => {
                        navigate(`/blog/${x.slug}`);
                      }}
                    >
                      {x.Main_Title}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
      <Scrolltotop />
      <Backtotop />
    </div>
  );
};

export default View_blogs;
