import React from 'react'

const Inner_svg5 = () => {
  return (
    <div>
      <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7122 20.899L11.7376 16.477C10.9422 15.7703 10.4862 14.7547 10.4862 13.69V13.5678C10.4862 12.6503 10.8434 11.788 11.4925 11.1389C12.0551 10.5762 12.8038 10.2665 13.5992 10.2665C14.3946 10.2665 15.1433 10.5762 15.7059 11.1389L16.7122 12.1452L17.7185 11.1389C18.8439 10.0143 20.8066 10.0143 21.932 11.1389C22.581 11.788 22.9383 12.6503 22.9383 13.5678V13.69C22.9383 14.7539 22.4822 15.7703 21.6868 16.477L16.7122 20.899ZM24.4948 13.69V13.5678C24.4948 12.2347 23.9757 10.9817 23.0324 10.0384C22.1764 9.18158 21.037 8.70996 19.8253 8.70996C18.6579 8.70996 17.5574 9.14812 16.7122 9.94661C15.867 9.14812 14.7666 8.70996 13.5992 8.70996C12.3875 8.70996 11.2481 9.18158 10.392 10.0384C9.44878 10.9817 8.92969 12.2347 8.92969 13.5678V13.69C8.92969 15.1983 9.57642 16.6381 10.7033 17.6397L16.7122 22.9816L22.7204 17.6405C23.8481 16.6388 24.4948 15.1991 24.4948 13.69L24.4948 13.69Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.6774 34.4726C62.6774 35.0492 62.2084 35.5172 61.6328 35.5172H49.6531L44.5428 41.6481C44.3359 41.8946 43.8742 41.7285 43.8742 41.4068V35.5172H30.2941C29.7185 35.5172 29.2495 35.0492 29.2495 34.4726V29.2495H30.2941C32.0219 29.2495 33.428 27.8434 33.428 26.1156V10.4462H61.6328C62.2084 10.4462 62.6774 10.9142 62.6774 11.4909L62.6774 34.4726ZM37.6065 55.3651C37.6065 55.9417 37.1374 56.4097 36.5618 56.4097H22.9817V62.2993C22.9817 62.6221 22.52 62.7892 22.3132 62.5406L17.2029 56.4097H5.22312C4.64753 56.4097 4.1785 55.9417 4.1785 55.3651V34.4726C4.1785 33.896 4.64754 33.428 5.22312 33.428H15.5283L16.5301 34.6293C17.0002 35.1934 17.6907 35.5172 18.4251 35.5172C19.6556 35.5172 20.67 34.6084 20.8538 33.428H27.1602V34.4726C27.1602 36.2004 28.5663 37.6065 30.2941 37.6065H37.6065L37.6065 55.3651ZM27.1602 31.3387H20.8925V29.2495H27.1602V31.3387ZM3.13387 27.1602C2.55828 27.1602 2.08925 26.6922 2.08925 26.1156V3.13387C2.08925 2.55724 2.55829 2.08925 3.13387 2.08925H30.2941C30.8697 2.08925 31.3387 2.55724 31.3387 3.13387V26.1156C31.3387 26.6922 30.8697 27.1602 30.2941 27.1602H18.8032V33.0498C18.8032 33.3726 18.3415 33.5397 18.1347 33.2911L13.0244 27.1602H3.13387ZM61.6328 8.35699H33.428V3.13387C33.428 1.40606 32.0219 0 30.2941 0H3.13387C1.40606 0 0 1.40606 0 3.13387V26.1156C0 27.8434 1.40606 29.2495 3.13387 29.2495H12.0466L13.788 31.3387H5.22312C3.49531 31.3387 2.08925 32.7448 2.08925 34.4726V55.3651C2.08925 57.0929 3.49531 58.4989 5.22312 58.4989H16.2251L20.7086 63.8787C21.1787 64.4428 21.8692 64.7667 22.6036 64.7667C23.9637 64.7667 25.071 63.6594 25.071 62.2993V58.4989H36.5618C38.2896 58.4989 39.6957 57.0929 39.6957 55.3651V37.6065H41.785V41.4068C41.785 42.7669 42.8923 43.8742 44.2524 43.8742C44.9867 43.8742 45.6772 43.5504 46.1473 42.9863L50.6308 37.6065H61.6328C63.3606 37.6065 64.7667 36.2004 64.7667 34.4726V11.4909C64.7667 9.76305 63.3606 8.35699 61.6328 8.35699Z" fill="#EBFD05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.4857 44.4603C28.4857 45.298 27.8044 45.9793 26.9667 45.9793C26.129 45.9793 25.4477 45.298 25.4477 44.4603C25.4477 43.6226 26.129 42.9413 26.9667 42.9413C27.8044 42.9413 28.4857 43.6226 28.4857 44.4603ZM19.3718 42.9413C19.3718 42.1036 20.053 41.4223 20.8908 41.4223C21.7285 41.4223 22.4097 42.1036 22.4097 42.9413C22.4097 43.779 21.7285 44.4603 20.8908 44.4603C20.053 44.4603 19.3718 43.779 19.3718 42.9413ZM13.2958 44.4603C13.2958 43.6226 13.9771 42.9413 14.8148 42.9413C15.6525 42.9413 16.3338 43.6226 16.3338 44.4603C16.3338 45.298 15.6525 45.9793 14.8148 45.9793C13.9771 45.9793 13.2958 45.298 13.2958 44.4603ZM23.9287 44.4603C23.9287 44.9107 24.0335 45.3345 24.2097 45.7188C23.8824 45.4287 23.5178 45.1811 23.1237 44.9844C23.6189 44.4436 23.9287 43.7304 23.9287 42.9413C23.9287 41.2659 22.5662 39.9033 20.8908 39.9033C19.2153 39.9033 17.8528 41.2659 17.8528 42.9413C17.8528 43.7304 18.1626 44.4436 18.6578 44.9843C18.2637 45.1811 17.8991 45.4286 17.5718 45.7188C17.748 45.3345 17.8528 44.9107 17.8528 44.4603C17.8528 42.7848 16.4902 41.4223 14.8148 41.4223C13.1393 41.4223 11.7768 42.7848 11.7768 44.4603C11.7768 45.2699 12.0996 46.0013 12.6176 46.5466C11.2125 47.3236 10.2578 48.8198 10.2578 50.5363V52.0552H11.7768V50.5363C11.7768 48.8608 13.1393 47.4983 14.8148 47.4983C15.2933 47.4983 15.752 47.6122 16.1697 47.8211C16.0497 48.1681 15.9616 48.5312 15.9191 48.9102L15.5788 51.9709L17.088 52.1388L17.4282 49.078C17.6249 47.3114 19.1135 45.9793 20.8907 45.9793C22.668 45.9793 24.1566 47.3114 24.3533 49.078L24.6935 52.1388L26.2027 51.9709L25.8624 48.9102C25.8206 48.5312 25.7318 48.1681 25.6118 47.8211C26.0295 47.6122 26.4882 47.4983 26.9667 47.4983C28.6422 47.4983 30.0047 48.8608 30.0047 50.5362V52.0552H31.5237V50.5362C31.5237 48.8198 30.569 47.3236 29.1639 46.5466C29.6819 46.0013 30.0047 45.2699 30.0047 44.4603C30.0047 42.7848 28.6422 41.4223 26.9667 41.4223C25.2913 41.4223 23.9287 42.7848 23.9287 44.4603V44.4603Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.4318 27.263C53.4318 28.0501 52.7917 28.6902 52.0046 28.6902H48.8684C47.452 28.6902 46.0783 28.3041 44.8688 27.5784V21.8498L45.5082 21.2104C46.4451 20.2735 47.1109 19.0982 47.432 17.8131C47.5112 17.4955 47.7959 17.2729 48.1242 17.2729C48.5259 17.2729 48.9055 17.4484 49.1653 17.756C49.425 18.0628 49.5363 18.466 49.47 18.8627L49.0219 21.5544H52.0046C52.7917 21.5544 53.4318 22.1944 53.4318 22.9815V27.263ZM40.5873 28.6902V21.5544H43.4416L43.4424 28.6902H40.5873ZM52.0046 20.1272H50.7059L50.8779 19.0968C51.0127 18.2854 50.7858 17.4605 50.2542 16.8333C49.7226 16.2054 48.9462 15.8457 48.1242 15.8457C47.1401 15.8457 46.286 16.5129 46.0469 17.467C45.7886 18.5017 45.2534 19.4464 44.4992 20.2014L44.2887 20.4119C44.051 20.2357 43.7599 20.1272 43.4416 20.1272H40.5873C39.8002 20.1272 39.1602 20.7673 39.1602 21.5544V28.6902C39.1602 29.4772 39.8002 30.1173 40.5873 30.1173H43.4416C44.0646 30.1173 44.5891 29.7142 44.7839 29.1568C46.0498 29.7848 47.4406 30.1173 48.8684 30.1173H52.0046C53.5788 30.1173 54.8589 28.8372 54.8589 27.263V22.9815C54.8589 21.4074 53.5788 20.1272 52.0046 20.1272L52.0046 20.1272Z" fill="white"/>
</svg>

    </div>
  )
}

export default Inner_svg5
