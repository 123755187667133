import React from 'react'
import Svg1 from '../../Svgs/Medical/Svg1'

const Medical_layout5 = () => {
  return (
    <div style={{minHeight:'80vh'}} className='py-4'>
      <div className="container row m-0 mx-auto align-items-center justify-content-evenly">
        <div className="col-md-6">
            <button className='btn border rounded-pill fw-medium px-3 py-1' style={{color:'#18A0FB',fontSize:'13px'}}>Analytics</button>
            <p className='fs-1 fw-medium mt-4' style={{lineHeight:'50px'}}>Tracking Payments and Billing <span style={{color:'#18A0FB'}}>Trends Easily</span></p>
            <p style={{fontSize:'13px'}}>Monitor trends with real-time reports and insights that improve decision-making for better financial planning.</p>

            <p className='d-flex align-items-center mt-4'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>Real-time Reports</span></p>
            <p className='d-flex align-items-center mt-3'><Svg1/> <span className='ms-3' style={{fontSize:'13px'}}>Trend Insights</span></p>
        </div>
        <div className="col-md-6 col-lg-5">
            <div className='medical-layout2-subdiv d-flex align-items-center justify-content-center' style={{height:'490px'}}>
                <img src={require('../../images/products/Income.png')} style={{width:'80%'}} alt="" />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Medical_layout5
