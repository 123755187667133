import React from 'react'
import Payroll_layout2 from './Payroll_layout2'
import Payroll_layout3 from './Payroll_layout3'
import Payroll_layout4 from './Payroll_layout4'
import Payroll_layout5 from './Payroll_layout5'
import Client_reviews from '../../Landingpages/Client_reviews'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import Footer from '../../Landingpages/Footer'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Navbar from '../../Landingpages/Navbar'
import { Helmet } from 'react-helmet'

const Payroll_management = () => {
  return (
    <div>
      <Helmet>
        <title>Simplify Payroll with Our Top-Rated Management Software</title>
        <meta name="description" content="Manage employee salaries with our software. Get accurate calculations and secure data management for smooth payroll operations every time." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Payroll Management Software for Accurate Salary Processing</h1>
      <h2 className="d-none">Top Payroll Management Software Features</h2>

      <div>
      <Navbar/>
     <div className='px-1 px-md-3'>
     <div className='payroll-maindiv py-lg-4'>
       <div className="container row m-0 mx-auto">
       <div className="col-lg-6 pt-md-4">
       <p className='payroll-maintext'>Reliable Payroll Management Software Built to Fit Your Business</p>
       <p style={{lineHeight:'30px'}}>Your business deserves smooth payroll management. From tracking hours to automating payments, we simplify payroll so you can focus on growth.</p>

       <button className='mt-4 payroll-mainbtn btn rounded-pill px-4 border-0 text-white fw-medium d-flex align-items-center py-3'><span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></span>Get Started</button>
       </div>
       </div>
</div>
     </div>
     <div className='container py-5'>
        <p className='text-center fs-5'>More than 25+ companies HR around the world trusted Empki with the employee management.</p>
     </div>

     <Payroll_layout2/>
     <Payroll_layout3/>
     <Payroll_layout4/>
     <Payroll_layout5/>
     <Client_reviews/>
     <FAQ/>
     <Blogs/>
     <Backtotop/>
     <Scrolltotop/>
    </div>
    </div>
  )
}

export default Payroll_management
