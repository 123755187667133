import React from 'react'

const Svg3 = () => {
  return (
    <div>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M43.5613 50.124L34.2531 33.998L24.9449 50.124L15.6367 66.2499H52.877L43.5688 50.124H43.5613ZM26.1319 59.8604L30.1925 52.8285L34.2531 45.7967L38.3137 52.8285L42.3743 59.8604H26.1357H26.1319Z" fill="white" stroke="#2F2F2F" stroke-width="2.25"/>
<path d="M24.941 17.8759L34.2492 34.0019L43.5574 17.8759L52.8693 1.75H15.6328L24.941 17.8759ZM42.3704 8.13952L38.3098 15.1714L34.2492 22.2032L30.1886 15.1714L26.128 8.13952H42.3666H42.3704Z" fill="white" stroke="#2F2F2F" stroke-width="2.25"/>
<path d="M18.1259 43.3104L34.2519 34.0022L18.1259 24.694L2 15.3821V52.6224L18.1259 43.3142V43.3104ZM8.38952 25.881L15.4214 29.9416L22.4532 34.0022L15.4214 38.0628L8.38952 42.1234V25.8848V25.881Z" fill="white" stroke="#2F2F2F" stroke-width="2.25"/>
<path d="M50.372 24.6903L34.2461 33.9985L50.372 43.3067L66.498 52.6149V15.3821L50.372 24.6903ZM60.1084 42.1197L53.0766 38.0591L46.0447 33.9985L53.0766 29.9379L60.1084 25.8773V42.1159V42.1197Z" fill="white" stroke="#2F2F2F" stroke-width="2.25"/>
</svg>

    </div>
  )
}

export default Svg3
