import React from 'react'

const Socialmedia = () => {
  return (
    <div>
      <svg width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.95585 18.9876C8.17151 18.9877 6.42716 18.4587 4.94347 17.4675C3.45979 16.4763 2.30344 15.0673 1.62053 13.4188C0.937623 11.7703 0.758812 9.95633 1.10688 8.20627C1.45495 6.45621 2.31418 4.84875 3.5759 3.58703C4.83762 2.32532 6.44517 1.46608 8.19523 1.11801C9.94529 0.769946 11.7593 0.948658 13.4078 1.63157C15.0563 2.31448 16.4652 3.47092 17.4564 4.95461C18.4477 6.4383 18.9766 8.18255 18.9764 9.96688C18.973 12.3583 18.0215 14.6508 16.3306 16.3417C14.6396 18.0327 12.3472 18.9841 9.95585 18.9876ZM9.95585 1.55044C8.29119 1.55028 6.6639 2.0437 5.27971 2.96842C3.89551 3.89314 2.81662 5.20765 2.17948 6.74555C1.54233 8.28345 1.37552 9.97567 1.70018 11.6084C2.02485 13.2411 2.82637 14.7408 4.0034 15.918C5.18044 17.0951 6.68013 17.8968 8.31279 18.2217C9.94545 18.5465 11.6377 18.3799 13.1757 17.7429C14.7137 17.1059 16.0282 16.027 16.953 14.6429C17.8779 13.2588 18.3715 11.6315 18.3715 9.96688C18.3692 7.73555 17.4818 5.59634 15.9041 4.01847C14.3263 2.44061 12.1872 1.553 9.95585 1.55044Z" fill="white"/>
<path d="M7.8385 8.23853H6.23828V13.407H7.8385V8.23853Z" fill="white"/>
<path d="M7.02733 7.55928C7.21645 7.56104 7.40179 7.50654 7.55988 7.40273C7.71798 7.29893 7.84166 7.15055 7.91522 6.97631C7.98877 6.80208 8.00889 6.60986 7.97301 6.42416C7.93714 6.23847 7.84684 6.06765 7.71367 5.93335C7.58051 5.79905 7.41051 5.70733 7.22513 5.66988C7.03975 5.63243 6.84744 5.65097 6.67259 5.72305C6.49773 5.79512 6.34816 5.91753 6.24301 6.07474C6.13787 6.23194 6.08189 6.41679 6.08204 6.60592C6.08118 6.85763 6.18019 7.09944 6.35742 7.27818C6.53465 7.45693 6.77562 7.558 7.02733 7.55928Z" fill="white"/>
<path d="M10.3958 10.6934C10.3958 9.96752 10.7305 9.53439 11.3709 9.53439C11.9589 9.53439 12.2419 9.94978 12.2419 10.6934V13.4067H13.8341V10.1345C13.8744 9.71667 13.7734 9.29738 13.5471 8.94384C13.3208 8.5903 12.9824 8.32297 12.5861 8.18463C12.1897 8.0463 11.7585 8.04506 11.3613 8.18099C10.9642 8.31692 10.6242 8.58222 10.3958 8.93439V8.23829H8.86328V13.4067H10.3958V10.6934Z" fill="white"/>
</svg>

    </div>
  )
}

export default Socialmedia
