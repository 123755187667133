import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"How can brand building help my business grow?",
            answer:"Brand building creates trust and recognition, making customers more likely to choose your products or services over others. With Merida Tech Minds’ brand building services, we help you create a strong identity that sets you apart from the competition!"
        },
        {
            question:"What’s the first step in starting my brand journey?",
            answer:"Start by defining your brand's purpose and understanding your audience's needs. This lays the groundwork for all your branding goals."
        },
        {
            question:"How do I know if my brand is connecting with people?",
            answer:"You can measure connections through customer feedback, surveys, and engagement on social media. Listen to what your audience says!."
        },
        {
            question:"Can brand building improve my personal brand too?",
            answer:"Absolutely! A strong personal brand enhances your visibility and helps you showcase your unique skills, attracting more opportunities."
        },
        {
            question:"What makes a brand memorable?",
            answer:"A memorable brand combines a unique logo, consistent messaging, and an engaging story that resonates emotionally with the audience."
        },
        {
            question:"How can I make the most of brand building services?",
            answer:"Engage with us actively, share your vision, and provide feedback. Your participation is essential for building a successful brand!"
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
