import React from 'react'

const Mobile_app_layout3 = () => {
  return (
    <div className='d-flex align-items-center' style={{backgroundColor:'#1E5CFC',minHeight:'50vh'}}>
      <div className="row mx-auto m-0 container align-items-center">
        <div className="col-md-5">
            <h3 className='fs-1 text-white'>Why Choose Our Mobile App Services?</h3>
            <p className='text-white mt-5' style={{fontSize:'15px'}}>We create personalized mobile apps that perfectly align with your business needs. Our team prioritizes user experience, ensuring that every app we develop is not only functional but also enjoyable to use.</p>
<button className='btn rounded-pill text-transparent px-4 py-3 mt-4' style={{backgroundColor:'#ffff',fontSize:'13px'}}>Learn More</button>
        </div>

        <div className="col-md-7 text-end pt-5" style={{position:'relative'}}>
            <img src={require('../images/innerpage4/Group 1171275183.png')} style={{width:'100%'}} alt="" />
           </div>
      </div>
    </div>
  )
}

export default Mobile_app_layout3
