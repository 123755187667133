import React from 'react'

const Ocr_layout5 = () => {
  return (
    <div className='ocr-layout5 py-4 py-md-5 mt-4'>
      <div className="container">
        <p className='text-white fw-bold fs-1 text-center'>Redefine Learning Experiences with Our Software Solutions</p>
        <p className='text-white text-center' style={{fontSize:'16px'}}>At Meirda Tech Minds, we offer a software platform designed to enhance your organization’s learning management. With features focused on user needs, our solution is crafted to make learning straightforward and enjoyable for everyone involved.</p>

        <div style={{height:'80vh',borderRadius:'35px'}} className='bg-white p-3 mt-4 mt-md-5'>
            <div className='h-100 bg-secondary' style={{borderRadius:'25px'}}>

            </div>
        </div>
      </div>
    </div>
  )
}

export default Ocr_layout5
