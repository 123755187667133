import React, { useState } from 'react'

const Software_layout3 = () => {
    const text=[
        'Outdated systems that slow down operations',
        'Lack of integration between existing software tools',
        'Data management and security concerns',
        'Inadequate reporting and analytics',
        'Inadequate reporting and analytics',
        'Scalability issues'
    ]

    const[index1,setindex1]=useState(-1)


  return (
    <div>
        <div className='bg-black py-5 mt-2'>
      <div className="row m-0 mx-auto container">
       <div className="col-md-7">
       <p className='text-white m-0'>Common Business Challenges</p>
        <p className='text-white fs-4 fw-bold mb-4'>Solved By Software Consultation</p>

        {text.map((x,index)=>{
            return(
                <p onClick={()=>setindex1(index)} style={{cursor:'pointer'}} className={`border-bottom border-secondary fw-medium py-4 m-0 ${index===index1 ? 'text-color':'text-white'}`}>{index+1} <span className='fw-bold ms-4'>{x}</span></p>
            )
        })}
       </div>
      </div>
    </div>

    <div className='software-layout2-text rounded-0' style={{height:'1.5vh'}}>

    </div>
    </div>
  )
}

export default Software_layout3
