import React from 'react'
import Check from '../../Svgs/Products/Check'

const Task_layout5 = () => {
  return (
    <div>
      <div className="container pb-5 row m-0 mx-auto align-items-center">

       <div className='col-md-6 col-lg-5 mx-auto'>
        <p className='mb-4'><span className='fw-bold border px-2 py-1 rounded-pill' style={{fontSize:'14px'}}><img className='me-1' src={require('../../images/products/Union.png')} alt="" /> TRACK WORKS</span></p>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Streamline <span className='task-text-color'>workflow</span> with us</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Improve your productivity with intuitive tools that simplify project tracking and ensure timely task completion.</p>

       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Monitor tasks to visualize progress and stay motivated.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Use task dependencies charts for project timelines.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Set priorities to focus on essential tasks each day.</span></p>
       <p className='d-flex align-items-center'><Check/> <span className='fw-medium ms-2'>Collaborate in real-time to keep everyone informed easily.</span></p>

       
       </div>
       <div className="col-md-6">
       <img src={require('../../images/products/Hero Content3.png')} className='' style={{width:'90%'}} alt="" />
       </div>
      </div>
    </div>
  )
}

export default Task_layout5
