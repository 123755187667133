import React from 'react'
import Svg1 from '../Svgs/Inner_page2/Svg1'

const Inner4_Layout2 = () => {
  
  return (
    <div className='inner2-layout3 py-5 container'>
      <p className='fs-1 fw-medium text-center m-0'>Our Graphic Design Services</p>
      <img src={require('../images/innerpage2/Group 1171275043.png')} className='d-block mx-auto mt-3' alt="" />
      <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>We create visual solutions that connect your brand with people. Through simple, thoughtful designs, we help your business leave a lasting impression across various platforms, both online and offline.</p>

      <div className="row m-0 mt-5">
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142189.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Poster Design</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Posters designed to deliver messages clearly and attract attention, perfect for events, campaigns, or product promotions.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142188.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Brochure Design</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Easy-to-read brochures that tell your story, showcasing services or products in an engaging and organized format.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142186.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Social Media</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Customized visuals for different social platforms, making your brand visually appealing and easy to recognize online.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142187.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Business Cards</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Professional cards that reflect your identity, creating an impactful first impression during meetings and networking.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142189.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Infographics</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Turn complex data into clear and attractive visuals that anyone can easily understand and engage with effectively.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142188.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Flyers & Leaflets</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Compact designs that convey messages quickly, ideal for events, product launches, or promotions.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142186.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Branding Materials</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>All your brand essentials from templates to stationery designed to keep your visual identity strong and consistent.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 mb-4">
            <div className='inner2-layout3-card'>
                <div className='p-4'>
                    <img src={require('../images/innerpage2/Group 142187.png')} className='mt-4' alt="" />
                </div>
                <div className='p-4 mt-3'>
                  <p className='text-white fs-4 fw-bold'>Logo Variations</p>
                  <p className="text-white" style={{fontSize:'13px',lineHeight:'24px'}}>Generators on the Internet tend to repeat predefined chunks as neces making this the first true generator on the Internet.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Inner4_Layout2
