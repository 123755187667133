import React from 'react'
import Check from '../../Svgs/Products/Check'
import { useNavigate } from 'react-router-dom'

const Ticket_layout5 = () => {
  const navigate=useNavigate()

  return (
    <div className='ticket-layout5 mx-md-3 d-flex align-items-center'>
      <div className='printlele-layout5-text mx-auto'>
      <p style={{fontSize:'40px'}} className='text-center text-white'>Want to Enhance Your Ticket Management?</p>
      <button onClick={()=>navigate('/contact-us')} className='btn mt-4 rounded-pill px-4 py-3 d-block mx-auto fw-medium' style={{fontSize:'14px',backgroundColor:'#ffff'}}>Contact Us</button>
      </div>
    </div>
  )
}

export default Ticket_layout5
