import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Ocr_layout4 = () => {
  return (
    <div style={{minHeight:'90vh'}} className='d-flex align-items-center position-relative'>
        <img src={require('../../images/products/Effect_Dot.png')} style={{width:'5%'}} className='position-absolute bottom-0' alt="" />
      <div className="container row m-0 mx-auto justify-content-center">
        <div className="col-md-6 col-lg-5">
            <p className='fw-bold fs-2 mb-1' style={{color:'#3735B3',lineHeight:'40px'}}>Why Partner with Merida Tech Minds?</p>
            <p>We specialize in delivering innovative software solutions that enhance your organization's success.</p>

            <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Expert developers at your service</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Custom solutions for your needs</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Strong focus on user experience</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Continuous updates and support</span></p>
        </div>
        <div className="col-md-6">

        </div>
      </div>
    </div>
  )
}

export default Ocr_layout4
