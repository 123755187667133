import React from 'react'

const Svg3 = () => {
  return (
    <div>
      <svg width="42" height="42" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5 57.4346C17.402 57.4346 17.2712 57.5 17.1732 57.5C10.8333 54.3627 5.66993 49.1667 2.5 42.8268C2.5 42.7288 2.56536 42.598 2.56536 42.5C6.55229 43.6765 10.6699 44.5588 14.7549 45.2451C15.4739 49.3627 16.3235 53.4477 17.5 57.4346Z" fill="currentColor"/>
<path d="M57.5 42.8526C54.3065 49.2308 48.9839 54.391 42.5 57.5C43.7258 53.4295 44.7581 49.3269 45.4355 45.1923C49.5 44.5192 53.5 43.6538 57.4355 42.5C57.4032 42.6282 57.5 42.7564 57.5 42.8526Z" fill="currentColor"/>
<path d="M57.5 17.5C53.5042 16.2949 49.4767 15.3118 45.3858 14.6142C44.7199 10.5233 43.7368 6.46406 42.5 2.5C49.0645 5.63953 54.3605 10.9355 57.5 17.5Z" fill="currentColor"/>
<path d="M17.5 2.59677C16.3437 6.53226 15.5086 10.5323 14.834 14.5968C10.6906 15.2419 6.57923 16.2742 2.5 17.5C5.61563 11.0161 10.7869 5.69355 17.1788 2.5C17.2752 2.5 17.4036 2.59677 17.5 2.59677Z" fill="currentColor"/>
<path d="M40 15C33.3524 14.1503 26.6476 14.1503 20 15C20.7163 10.5229 21.6332 6.04575 22.9226 1.73203C22.9799 1.47059 22.9513 1.27451 22.9799 1.01307C25.2436 0.392157 27.5645 0 30 0C32.4069 0 34.7564 0.392157 36.9914 1.01307C37.0201 1.27451 37.0201 1.47059 37.0774 1.73203C38.3668 6.07843 39.2837 10.5229 40 15Z" fill="currentColor"/>
<path d="M15 40C10.4902 39.2837 6.04575 38.3668 1.73203 37.0774C1.47059 37.0201 1.27451 37.0487 1.01307 37.0201C0.392157 34.7564 0 32.4355 0 30C0 27.5931 0.392157 25.2436 1.01307 23.0086C1.27451 22.9799 1.47059 22.9799 1.73203 22.9226C6.07843 21.6619 10.4902 20.7163 15 20C14.183 26.6476 14.183 33.3524 15 40Z" fill="currentColor"/>
<path d="M60 30C60 32.4355 59.6078 34.7564 58.9869 37.0201C58.7255 37.0487 58.5294 37.0201 58.268 37.0774C53.9216 38.3381 49.4771 39.2837 45 40C45.8497 33.3524 45.8497 26.6476 45 20C49.4771 20.7163 53.9542 21.6332 58.268 22.9226C58.5294 22.9799 58.7255 23.0086 58.9869 23.0086C59.6078 25.2722 60 27.5931 60 30Z" fill="currentColor"/>
<path d="M40 45C39.2837 49.5098 38.3668 53.9542 37.0774 58.268C37.0201 58.5294 37.0201 58.7255 36.9914 58.9869C34.7564 59.6078 32.4069 60 30 60C27.5645 60 25.2436 59.6078 22.9799 58.9869C22.9513 58.7255 22.9799 58.5294 22.9226 58.268C21.6619 53.9216 20.7163 49.5098 20 45C23.3238 45.4248 26.6476 45.719 30 45.719C33.3524 45.719 36.7049 45.4248 40 45Z" fill="currentColor"/>
<path d="M39.4083 39.4083C33.1556 40.1972 26.8444 40.1972 20.5917 39.4083C19.8028 33.1556 19.8028 26.8444 20.5917 20.5917C26.8444 19.8028 33.1556 19.8028 39.4083 20.5917C40.1972 26.8444 40.1972 33.1556 39.4083 39.4083Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg3
