import React from 'react'
import CRM_layout2 from './CRM_layout2'
import CRM_layout3 from './CRM_layout3'
import CRM_layout4 from './CRM_layout4'
import Client_reviews from '../../Landingpages/Client_reviews'
import FAQ from '../../Landingpages/FAQ'
import Footer from '../../Landingpages/Footer'
import CRM_layout5 from './CRM_layout5'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Navbar from '../../Landingpages/Navbar'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const CRM = () => {
  return (
    <div>
       <Helmet>
        <title>CRM Solutions for Effective Customer Management</title>
        <meta name="description" content="Find CRM software that tracks customer interactions, improves support, and grows your business. Discover effective CRM systems designed for your business!" />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Customer Relationship Management Solutions for Stronger Business Relationships</h1>
      <h2 className="d-none">Simplify Customer Engagement with Custom CRM Tools</h2>
      
      <div>
      <Navbar/>
      <div className='container pt-5' style={{minHeight:'100vh'}}>
        <p className='fs-1 fw-bold text-center'>Connecting Businesses and Customers with Effective CRM Solutions</p>
        <p className='text-center' style={{fontSize:'13px'}}>Engage with customers, keep track of leads, and expand your business with tools anyone can use efficiently.</p>

        <div className='mt-4 mt-md-5'>
          <p style={{fontSize:'13px'}} className='text-center mb-4'>CRM for businesses <span style={{color:'#FF2465'}}>big and small!</span></p>

          <div className='crm' style={{borderRadius:'25px 25px 0px 0px',height:'70vh'}}>

          </div>
        </div>
      </div>

      <CRM_layout2/>
      <CRM_layout3/>
      <CRM_layout4/>
      <Client_reviews/>
      <FAQ/>
      <CRM_layout5/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default CRM
