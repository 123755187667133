import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Blogs from '../../Landingpages/Blogs'
import Ticket_layout2 from './Ticket_layout2'
import Ticket_layout3 from './Ticket_layout3'
import Ticket_layout4 from './Ticket_layout4'
import Ticket_layout5 from './Ticket_layout5'
import Ticket_layout6 from './Ticket_layout6'
import FAQ from '../../Landingpages/FAQ'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Ticket_management_system = () => {

  const text="Enhance Your Support Experience"

  const navigate=useNavigate()

  return (
    <div>
      <Helmet>
        <title>Best Ticket Management System for Enhanced Customer Experience </title>
        <meta name="description" content="Easily manage customer inquiries with our ticket management solutions. Improve response times and improve customer satisfaction today!" />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Simplified Ticket Management for Enhanced Customer Support</h1>
      <h2 className="d-none">Ticket Management Solutions </h2>

      <div style={{backgroundColor:'#F8F8F8'}}>
      <div className="printlele-manager-layout1">
        <Navbar/>
       <div className='task-manager-subdiv mx-auto pt-5'>
      <div className='ticket-management-subdiv mx-auto'>
      <p style={{color:'#4F46E5',letterSpacing:'2px'}} className='text-center fw-medium m-0'>{text && text.toUpperCase()}</p>
       <p className='fw-bold text-center fs-1'>Reliable Ticket Management Services for Every Business</p>
       <p className="text-secondary text-center">Resolve customer inquiries quickly and accurately every time!</p>
       <button onClick={()=>navigate('/contact-us')} className='btn ticket-management-btn rounded-pill px-4 py-3 d-block mx-auto text-white fw-medium' style={{fontSize:'14px'}}>Get Started</button>
      </div>
       </div>

       <img src={require('../../images/mtm_images/ticket_management_banner.png')} className='d-block mx-auto mt-5 pt-4' style={{width:'80%'}} alt="" />
      </div>

      <Ticket_layout2/>
      <div className='bg-white container pb-4 pb-md-5 mb-5' style={{borderRadius:'20px'}}>
      <Ticket_layout3/>
      <Ticket_layout4/>
      <Ticket_layout6/>
      <Ticket_layout5/>
      </div>
      <Backtotop/>
      <Scrolltotop/>
      <FAQ/>
      <Blogs/>
    </div>
    </div>
  )
}

export default Ticket_management_system
