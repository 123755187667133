import React from 'react'

const Task_layout2 = () => {
    const data=[{
        img:""
    }]
  return (
    <div>
      <div className="container py-5">
       <div className='task-manager-subdiv mx-auto'>
        <p className='text-center mb-4'><span className='fw-bold border px-2 py-1 rounded-pill' style={{fontSize:'14px'}}><img className='me-1' src={require('../../images/products/Union.png')} alt="" /> HOW IT WORKS</span></p>
       <p className='text-center fs-1 fw-bold' style={{letterSpacing:'1px'}}>Features That Simplify <span className='task-text-color'>Task Handling</span></p>
       </div>

       <div className="row m-0 justify-content-center mt-5 pt-md-3">
        <div className="col-lg-3 col-md-6 mb-4 ps-md-0">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 14 (1).png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Easy Setup</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Start planning instantly with an intuitive interface, no complex configurations required.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 14.png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Task Prioritization</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Sort tasks by importance to focus on what truly matters each day.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 pe-md-0">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 13.png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Real-time Collaboration</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Work effortlessly with team members on shared tasks and projects.</p>
                </div>
            </div>
        </div>
        
       </div>

       <div className="row m-0 justify-content-center">
        <div className="col-lg-3 col-md-6 ps-md-0 mb-4 mb-lg-0">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 6.png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Progress Tracking</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Visualize your task completion with built-in tracking tools and dashboards.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 15.png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Custom Reminders</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Set alerts so you never miss deadlines, no matter how packed your schedule.</p>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6 pe-md-0">
            <div className='border task-layout2-card shadow-sm p-4 d-flex align-items-start'>
                <img src={require('../../images/products/Frame 17.png')} style={{width:'40%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-bold m-0 mb-2'>Cloud Syncing</p>
                    <p className='m-0' style={{fontSize:'13px'}}>Access your task lists anywhere through automatic sync across all devices.</p>
                </div>
            </div>
        </div>
        
       </div>
      </div>
    </div>
  )
}

export default Task_layout2
