import React from 'react'

const Layout3 = () => {
  return (
    <div className='py-5' style={{backgroundColor:'#f7f7f7',minHeight:'100vh'}}>
        <p className='fs-1 fw-bold text-center m-0' style={{color:'#3A3A3A'}}>Our Logo Design Process</p>
        <p className='text-center fs-5' style={{color:'#969696'}}>Crafting Your Unique Logo</p>
        <div className='text-center container mt-5' style={{position:'relative'}}>
        <div style={{position:'absolute',zIndex:1,top:'44%'}}>
        <img src={require('../images/innerpage3/Vector.png')} alt="" style={{width:'100%'}}/>
        </div>
        <div >
        <div className="row m-0 justify-content-center" style={{position:'relative',zIndex:2}}>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>01</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275057.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Connect</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>We listen closely to your ideas and understand what you want for your logo. Your vision is important!</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Sketch</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Our team sketches various logo concepts, playing with shapes and colors to perfectly align with your vision.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275056 (1).png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>02</p>
            </div>
        </div>

        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>03</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275056.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Create</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>We craft multiple logo designs for you to choose from, blending creativity with a clean, modern touch.</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Refine</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Your feedback helps us polish the chosen design, ensuring it’s perfectly suited to your style and preferences.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275058.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>04</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>05</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275059.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Deliver</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Once finalized, we provide you with the logo files, making them ready for use across all your platforms!</p>
            </div>
        </div>
      </div>
        </div>
        </div>
    </div>
  )
}

export default Layout3
