import React from 'react'

const Inner_svg4 = () => {
  return (
    <div>
      <svg width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M51.2024 60.4611H2.72363V11.9823H26.963V9.25882H0.000110006V63.1846H53.9259V32.9535H51.2024L51.2024 60.4611Z" fill="#EBFD05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M47.4769 9.35027L53.5592 15.4325L25.7807 43.1202L19.7893 37.038L47.4769 9.35027ZM59.0059 9.89494L55.4655 13.5261L49.4741 7.4439L53.1053 3.90351L59.0059 9.89494ZM18.0644 39.1259L23.602 44.7542L16.8843 45.8436L18.0644 39.1259ZM15.6134 37.2195L13.707 49.1116L25.5991 47.2052L26.7793 45.9343L62.9094 9.89495L52.9237 0L16.8843 36.0394L15.6134 37.2195Z" fill="white"/>
</svg>

    </div>
  )
}

export default Inner_svg4
