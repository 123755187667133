import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Hrms_layout2 from './Hrms_layout2'
import Hrms_layout3 from './Hrms_layout3'
import Hrms_layout4 from './Hrms_layout4'
import Hrms_layout5 from './Hrms_layout5'
import Hrms_layout6 from './Hrms_layout6'
import Hrms_layout7 from './Hrms_layout7'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const HRMS = () => {
  return (
    <div>
      <Helmet>
        <title>HR Management Made Easy with Innovative Software Solutions</title>
        <meta name="description" content="Improve your HR operations with our user-friendly software that simplifies payroll, onboarding, and employee management for greater productivity." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">HR Management Software Designed for Your Needs</h1>
      <h2 className="d-none">Human Resource Management System</h2>

      <div>
      <div className='hrms2'>

      </div>

      <div className='hrms'>
        <Navbar/>

        <div className='text-center task-manager-subdiv mx-auto mt-lg-5' style={{position:'relative',zIndex:5}}> 
            <p className='hrms-text'>Enhancing the Way HR Gets Things Done</p>
            <p style={{fontSize:'14px'}}>HRMS software designed to simplify human resource operations and ensure every process works smoothly from start to finish.</p>

            <button style={{backgroundColor:'#7744D6',fontSize:'14px'}} className='mt-4 mt-lg-5 btn text-white fw-medium px-4 px-lg-5 py-3 rounded-pill d-block mx-auto'>Get Started</button>

            <img src={require('../../images/products/Group 1.png')} className='mt-5' style={{width:'100%'}} alt="" />
        </div>
      </div>

      <Hrms_layout2/>
      <Hrms_layout4/>
      <Hrms_layout3/>
      <Hrms_layout5/>
      <Hrms_layout6/>
      <Hrms_layout7/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default HRMS
