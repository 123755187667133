import React from 'react'
import Svg1 from '../../Svgs/Printlele/Svg1'

const Printlele_layout3 = () => {
  const data=[
    {
      heading:"Set Up Printlele",
      para:"Install Printlele software on devices and connect your printers."
    },
    {
      heading:"Add Users",
      para:"Create user profiles with role-based access controls."
    },
    {
      heading:"Select Preferences",
      para:"Choose print settings like paper size, color, and orientation."
    },
    {
      heading:"Upload Files",
      para:"Send documents directly from any device to Printlele."
    },
    {
      heading:"Track & Manage",
      para:"Monitor print status and costs from the dashboard."
    },
  ]

  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
      <div className="col-md-6 text-center">
       <img src={require('../../images/products/Container.png')} className='' style={{width:'90%'}} alt="" />
       </div>

       <div className='col-md-6 mx-auto'>
       <p className='fs-2 fw-bold' style={{letterSpacing:'1px',lineHeight:'45px'}}>Here’s How Printlele Streamlines Every Print Process</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Printlele’s process makes printing smooth from upload to completion in just a few simple steps.</p>

       {data.map((x)=>{
        return(
          <p className='d-flex align-items-start'><Svg1/> 
       <p className='ms-3'>
       <span className='fw-bold d-block'>{x.heading}</span>
       <span style={{fontSize:'13px'}}>{x.para}</span>
       </p></p>  
        )
       })}     
       </div>
      </div>
    </div>
  )
}

export default Printlele_layout3
