import React from 'react'
import Svg1 from '../Svgs/Svg1'

const Client_reviews = () => {
  return (
    <div className='client-review-div py-5' style={{position:'relative'}}>
        <img src={require('../images/Group 571.png')} style={{position:'absolute',top:0,right:0}} alt="" />
        <img src={require('../images/Group 294.png')} style={{position:'absolute',bottom:0,left:0}} alt="" />
      <p className='client-main-text mt-md-4'>What  our <span className='client-text'>Client says</span></p>
      <p className="text-white text-center">Take a look at what our clients are saying about working with us and the positive impact we've had on their success.</p>
      <img src={require('../images/Pattern.png')} className='d-block mx-auto' alt="" />
      <div className="row m-0 container mx-auto mt-5 mb-md-5">
        <div className="col-md-4 mb-4 mb-lg-0">
            <div className='client-card p-4'>
                    <div className='d-flex align-items-center'>
                        <img src={require('../images/profile.png')} alt="" />
                        <div className='ms-2'>
                            <p className='text-white fw-medium m-0'>Madhavan</p>
                            <p className='text-white m-0' style={{fontSize:'13px'}}>React Developer</p>
                        </div>
                    </div>

                    <p className='text-white mt-5' style={{fontSize:'14px'}}>So simple is very understanding and masters the field of small business, making it easier for us to establish further communication, related to the fund distribution strategy that we developed. Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ipsam laudantium, sint in quos rerum ipsum ea suscipit fuga!</p>

                    <div className='d-flex align-items-end justify-content-between mt-5 pt-5'>
                        <p className='text-white d-flex align-items-end m-0'><Svg1/> <span className='ms-2' style={{fontSize:'14px'}}>4.4 / 5.0</span></p>
                        <p className='text-white m-0' style={{fontSize:'13px'}}>06 August 2024</p>
                    </div>
            </div>
        </div>

        <div className="col-md-4 mb-4 mb-lg-0">
            <div className='client-card p-4'>
                    <div className='d-flex align-items-center'>
                        <img src={require('../images/profile.png')} alt="" />
                        <div className='ms-2'>
                            <p className='text-white fw-medium m-0'>Madhavan</p>
                            <p className='text-white m-0' style={{fontSize:'13px'}}>React Developer</p>
                        </div>
                    </div>

                    <p className='text-white mt-5' style={{fontSize:'14px'}}>So simple is very understanding and masters the field of small business, making it easier for us to establish further communication, related to the fund distribution strategy that we developed. Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ipsam laudantium, sint in quos rerum ipsum ea suscipit fuga!</p>

                    <div className='d-flex align-items-end justify-content-between mt-5 pt-5'>
                        <p className='text-white d-flex align-items-end m-0'><Svg1/> <span className='ms-2' style={{fontSize:'14px'}}>4.4 / 5.0</span></p>
                        <p className='text-white m-0' style={{fontSize:'13px'}}>06 August 2024</p>
                    </div>
            </div>
        </div>

        <div className="col-md-4 mb-4 mb-lg-0">
            <div className='client-card p-4'>
                    <div className='d-flex align-items-center'>
                        <img src={require('../images/profile.png')} alt="" />
                        <div className='ms-2'>
                            <p className='text-white fw-medium m-0'>Madhavan</p>
                            <p className='text-white m-0' style={{fontSize:'13px'}}>React Developer</p>
                        </div>
                    </div>

                    <p className='text-white mt-5' style={{fontSize:'14px'}}>So simple is very understanding and masters the field of small business, making it easier for us to establish further communication, related to the fund distribution strategy that we developed. Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ipsam laudantium, sint in quos rerum ipsum ea suscipit fuga!</p>

                    <div className='d-flex align-items-end justify-content-between mt-5 pt-5'>
                        <p className='text-white d-flex align-items-end m-0'><Svg1/> <span className='ms-2' style={{fontSize:'14px'}}>4.4 / 5.0</span></p>
                        <p className='text-white m-0' style={{fontSize:'13px'}}>06 August 2024</p>
                    </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Client_reviews
