import React from 'react'
import Inner_svg1 from '../Svgs/Inner-svg1'
import Inner_svg2 from '../Svgs/nner_svg2'
import Inner_svg3 from '../Svgs/Inner_svg3'
import Inner_svg4 from '../Svgs/Inner_svg4'
import Inner_svg5 from '../Svgs/Inner_svg5'
import Inner_svg6 from '../Svgs/Inner_svg6'

const Layout5 = () => {
  return (
    <div className='innerLayout5 py-5 d-flex align-items-center'>
      <div className='row m-0 container mx-auto'>

        <div className="col-lg-3 mb-4">
        <div className="innerlayout5-cards">
            <div style={{height:'70px'}}>
            <Inner_svg1/>
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>

        <div className="col-lg-3 mb-4">
        <div className="innerlayout5-cards">
        <div style={{height:'70px'}}>
            <Inner_svg2 />
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>

        <div className="col-lg-6">
          <p className='fs-1 m-0 text-white fw-medium'>Why</p>
          <p className='fs-1 fw-medium inner-layout5-cards-subtext'>Choose Us</p>
          <p className='text-white' style={{fontSize:'14px'}}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore officia impedit quibusdam vel quae fugit facilis, velit excepturi ducimus consequuntur sapiente adipisci accusamus inventore porro aliquid dignissimos modi. Vitae, id?</p>
        </div>

        <div className="col-lg-3 mb-4 mb-lg-0">
        <div className="innerlayout5-cards">
        <div style={{height:'70px'}}>
            <Inner_svg3 />
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0">
        <div className="innerlayout5-cards">
        <div style={{height:'70px'}}>
            <Inner_svg4/>
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>
        <div className="col-lg-3 mb-4 mb-lg-0">
        <div className="innerlayout5-cards">
        <div style={{height:'70px'}}>
            <Inner_svg5/>
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>
        <div className="col-lg-3">
        <div className="innerlayout5-cards">
        <div style={{height:'70px'}}>
            <Inner_svg6/>
            </div>
            <p className='inner-layout5-cards-text'>Input Your
            Requirements</p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Layout5
