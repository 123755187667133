import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.00576 4.89834C9.26344 -0.359342 17.7878 -0.359342 23.0455 4.89834C28.3032 10.156 28.3032 18.6804 23.0455 23.9381C17.7878 29.1958 9.26344 29.1958 4.00576 23.9381C-1.25192 18.6804 -1.25192 10.156 4.00576 4.89834Z" fill="#DBFF00"/>
<path d="M17.8848 18.5208L17.8808 10.312M17.8808 10.312L9.17383 10.3076M17.8808 10.312L9.35899 18.7144" stroke="#5A2CDA" stroke-width="2.04915" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </div>
  )
}

export default Svg2
