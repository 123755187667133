import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="20" height="20" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0245 0.463527C13.1741 0.00287187 13.8259 0.00286984 13.9755 0.463525L16.6432 8.67376C16.7101 8.87977 16.9021 9.01925 17.1187 9.01925L25.7515 9.01925C26.2358 9.01925 26.4372 9.63906 26.0454 9.92376L19.0613 14.998C18.8861 15.1253 18.8128 15.351 18.8797 15.557L21.5474 23.7672C21.697 24.2279 21.1698 24.6109 20.7779 24.3262L13.7939 19.252C13.6186 19.1247 13.3814 19.1247 13.2061 19.252L6.22206 24.3262C5.8302 24.6109 5.30297 24.2279 5.45264 23.7672L8.12031 15.557C8.18725 15.351 8.11392 15.1253 7.93867 14.998L0.954628 9.92376C0.562771 9.63906 0.764161 9.01925 1.24852 9.01925L9.88128 9.01925C10.0979 9.01925 10.2899 8.87977 10.3568 8.67376L13.0245 0.463527Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg1
