import React, { useState } from 'react';
import Icon_div from './Icon_div';
import { useNavigate } from 'react-router-dom';
import svg from '../images/SLA_logo_Blue.svg';
import Mtm_offcanvas from './Mtm_offcanvas';

const Navbar = () => {
  const [state, setstate] = useState(false);

  const navigate = useNavigate();

  return (
    <div
      className="py-4"
      style={{ position: 'relative', zIndex: 5 }}
    >
      <div className="row container m-0 mx-auto align-items-center">
        <div className="col-5 col-lg-2">
          <img
            style={{ cursor: 'pointer', width: '80%' }}
            onClick={() => navigate('/')}
            src={svg}
            alt=""
          />
        </div>
        <div className="d-none col-7  d-lg-flex align-items-center justify-content-between ms-auto">
          <span className="  nav-text d-flex align-items-center">
            <a
              href="https://portfolio.meridatechminds.com"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-black"
            >
              <img
                src={require('../images/Polygon 2.png')}
                className="me-2"
                alt=""
              />
              PORTFOLIO
              <img
                src={require('../images/Polygon 1.png')}
                className="ms-2"
                alt=""
              />
            </a>
          </span>

          {/* <a
            href="/#newsandevents"
            rel="noreferrer"
            className="text-decoration-none text-black"
          >
            <span className="nav-text d-flex align-items-center">
              <img
                src={require('../images/Polygon 2.png')}
                className="me-2"
                alt=""
              />
              NEW EVENTS
              <img
                src={require('../images/Polygon 1.png')}
                className="ms-2"
                alt=""
              />
            </span>
          </a> */}

          <span
            onClick={() => navigate('/blogs')}
            className="nav-text d-flex align-items-center"
          >
            <img
              src={require('../images/Polygon 2.png')}
              className="me-2"
              alt=""
            />
            BLOGS
            <img
              src={require('../images/Polygon 1.png')}
              className="ms-2"
              alt=""
            />
          </span>
          <span
            onClick={() => navigate('/contact-us')}
            className="nav-text d-flex align-items-center"
          >
            <img
              src={require('../images/Polygon 2.png')}
              className="me-2"
              alt=""
            />
            CONTACT US
            <img
              src={require('../images/Polygon 1.png')}
              className="ms-2"
              alt=""
            />
          </span>

          <img
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setstate(true);
            }}
            src={require('../images/Group 1000002139.png')}
            width={46}
            alt=""
          />
        </div>
        <div className="col-7 ms-auto text-end d-lg-none">
          <svg
            style={{ cursor: 'pointer' }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling"
            aria-controls="offcanvasScrolling"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            class="bi bi-text-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </div>
      </div>
      <Icon_div
        state={state}
        setstate={setstate}
      />
      <Mtm_offcanvas />
    </div>
  );
};

export default Navbar;
