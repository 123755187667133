import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="45" height="45" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M57.5 27.3512V14.1746C57.5 11.7134 55.5864 8.92196 53.2099 7.9915L36.0185 1.14807C32.1605 -0.382691 27.8086 -0.382691 23.9506 1.14807L6.75926 7.9915C4.41358 8.92196 2.5 11.7134 2.5 14.1746V27.3512C2.5 42.0285 13.4568 55.7754 28.4259 59.7974C29.4444 60.0675 30.5556 60.0675 31.5741 59.7974C46.5432 55.7754 57.5 42.0285 57.5 27.3512ZM32.3148 32.6038V40.4977C32.3148 41.7284 31.2654 42.7489 30 42.7489C28.7346 42.7489 27.6852 41.7284 27.6852 40.4977V32.6038C24.5679 31.6433 22.284 28.8219 22.284 25.4902C22.284 21.3482 25.7407 17.9865 30 17.9865C34.2593 17.9865 37.716 21.3482 37.716 25.4902C37.716 28.8519 35.4321 31.6433 32.3148 32.6038Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg2
