import React from 'react'

const Onlinetest_layout2 = () => {

  const data=[
    {
      heading:"Design Your Test",
      para:"Create personalized assessments using flexible templates to meet your unique needs and standards.",
      img:"/asset/Icon (12).png"
    },
    {
      heading:"Administer Effortlessly",
      para:"Share your test link and manage participant access easily, ensuring a smooth testing experience.",
      img:"/asset/Icon (11).png"
    },{
      heading:"Receive Immediate Feedback",
      para:"Get instant results and in-depth analysis, helping you make informed decisions quickly and efficiently.",
      img:"/asset/Icon_Total Control.png"
    }
  ]
  return (
    <div className='row m-0 container justify-content-evenly mx-auto py-4 py-md-5'>
      <div className='my-5'>
      <button className='p-2 px-3 btn rounded fw-medium m-0 d-block mx-auto' style={{color:'#36B37E',backgroundColor:'#E6F7F2'}}>HOW IT WORKS</button>
       <p className="text-center fw-bold fs-2 mt-3" style={{color:'#183B56'}}>Here’s How Simple Online Testing Works</p>
       <p className='text-center' style={{fontSize:'13px',color:'#5A7184'}}>Experience a hassle-free journey from test creation to results with our intuitive online assessment platform.</p>
      </div>

      {data.map((x)=>{
        return(
          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
       <div>
       <img src={x.img} alt="" className='d-block mx-auto mb-4' style={{width:'18%'}}/>
        <p className='fw-medium text-center mb-2' style={{color:'#183B56'}}>{x.heading}</p>
        <p className='text-center' style={{fontSize:'13px',color:'#5A7184',lineHeight:'20px'}}>{x.para}</p>
       </div>
      </div>
        )
      })}
    </div>
  )
}

export default Onlinetest_layout2
