import React from 'react'

const Layout4 = () => {
  return (
    <div className='innerLayout4 py-5'>
      <div className='row m-0 container mx-auto'>
        <p className='fs-1 fw-medium text-center mb-5' style={{color:'#CDF552'}}>How we Works</p>

        <div className="col-lg-3">
        <div className="innerlayout4-cards">
            <p className='rounded-circle fs-5 fw-bold inner-layout4-cards-p d-flex align-items-center justify-content-center' style={{width:'40px',height:'40px'}}>1</p>
            <p className='inner-layout4-cards-text'>Input Your
            Requirements</p>
            <p className='inner-layout4-cards-subtext'>Provide basic information about your content needs, such as topic, tone, and target audience
persuasive and engaging
copy tailored to your
specifications.</p>
        </div>
        </div>

        <div className="col-lg-3 inner4">
        <img src={require('../images/innerPage1/Vector 4.png')} className='mb-3' alt="" />
        <div className="innerlayout4-cards">
            <p className='rounded-circle fs-5 fw-bold inner-layout4-cards-p d-flex align-items-center justify-content-center' style={{width:'40px',height:'40px'}}>1</p>
            <p className='inner-layout4-cards-text'>Input Your
            Requirements</p>
            <p className='inner-layout4-cards-subtext'>Provide basic information about your content needs, such as topic, tone, and target audience
persuasive and engaging
copy tailored to your
specifications.</p>
        </div>
        </div>

        <div className="col-lg-3 inner5">
        <img src={require('../images/innerPage1/Vector 4.png')} className='mb-3' alt="" />
        <div className="innerlayout4-cards">
            <p className='rounded-circle fs-5 fw-bold inner-layout4-cards-p d-flex align-items-center justify-content-center' style={{width:'40px',height:'40px'}}>1</p>
            <p className='inner-layout4-cards-text'>Input Your
            Requirements</p>
            <p className='inner-layout4-cards-subtext'>Provide basic information about your content needs, such as topic, tone, and target audience
persuasive and engaging
copy tailored to your
specifications.</p>
        </div>
        </div>

        <div className="col-lg-3 inner6">
        <img src={require('../images/innerPage1/Vector 4.png')} className='mb-3' alt="" />
        <div className="innerlayout4-cards">
            <p className='rounded-circle fs-5 fw-bold inner-layout4-cards-p d-flex align-items-center justify-content-center' style={{width:'40px',height:'40px'}}>1</p>
            <p className='inner-layout4-cards-text'>Input Your
            Requirements</p>
            <p className='inner-layout4-cards-subtext'>Provide basic information about your content needs, such as topic, tone, and target audience
persuasive and engaging
copy tailored to your
specifications.</p>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Layout4
