import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Onlinetest_layout2 from './Onlinetest_layout2'
import Onlinetest_layout3 from './Onlinetest_layout3'
import Onlinetest_layout4 from './Onlinetest_layout4'
import Onlinetest_layout5 from './Onlinetest_layout5'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const Online_test = () => {
  return (
    <div>
      <Helmet>
        <title>Online Testing Solutions for All Your Assessment Needs </title>
        <meta name="description" content="Create and manage online assessments easily with our tools. Get fast results and allow participants to test their skills anytime and anywhere." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Online Assessment Tools for Quick and Easy Testing</h1>
      <h2 className="d-none">Easy Setup for Online Assessments and Instant Results</h2>

      <div>
      <div className='online-test-div mb-md-5'>
        <Navbar/>
        <div className="row m-0 container mx-auto justify-content-between" style={{height:'90vh'}}>
            <div className="col-md-6 col-lg-5 pt-4 pt-md-5 mt-lg-5">
              <p className='fs-1 fw-medium' style={{color:'#183B56'}}>Change the Way Tests Are Taken Online</p>
              <p style={{color:'#183B56',letterSpacing:'1px',lineHeight:'26px'}}>Create, share, and score tests easily on our intuitive online test platform designed for everyone.</p>
            </div>
            <div className="col-md-6 position-relative">
                <img src={require('../../images/mtm_images/payroll benefit.png')} className='position-absolute' style={{width:'80%',bottom:'-100px',left:'20%'}} alt="" />
            </div>
        </div>
      </div>

      <Onlinetest_layout2/>
      <Onlinetest_layout3/>
      <Onlinetest_layout4/>
      <Onlinetest_layout5/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Online_test
