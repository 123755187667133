import React from 'react'

const Inner4_layout4 = () => {
  return (
    <div className='inner-page2-div bg-black' style={{minHeight:'90vh'}}>
      <img src={require('../images/innerpage2/Group 142443.png')} style={{width:'100%'}} alt="" />
      <div className='py-5'>
       <div className="container">
      <p className='fs-4 text-center py-5 text-white' style={{lineHeight:'50px'}}>With countless successful projects, our team has a proven track record of delivering designs that resonate with clients' requirements. Count on us to bring your vision to reality and enhance your brand's presence.</p>

<div className='row m-0 justify-content-center'>
<div className="col-lg-3 text-center col-md-6 text-white">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6 text-white">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6 text-white">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6 text-white">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
</div>
       </div>
      </div>
    </div>
  )
}

export default Inner4_layout4
