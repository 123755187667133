import React from 'react'
import Arrow_svg from '../Svgs/Arrow_svg'

const Brand_building_layout3 = () => {
  return (
    <div className='branding-layout3 position-relative py-5'>
        <div className='branding-layout3-subdiv position-absolute w-100 h-100 z-1' style={{top:0,left:0}}>
        </div>

        <p className='text-white fs-1 fw-bold text-center'>Services We Offer</p>
        <p className='text-white text-center'>We offer a wide range of brand building services designed to help your business grow. Our goal is to help you create a memorable brand that connects with your audience.</p>
      
      <div className="row m-0 container mx-auto position-relative z-2 justify-content-center mt-5 pt-5">
        <div className="col-md-6 col-lg-5 px-2 px-md-3 px-lg-5 branding-layout-column">
            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>Graphic Design</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>Creative visuals that tell stories</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22743.png')} style={{width:'100%'}} alt="" />
            </div>

            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>UI/UX Design</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>Simple and user-friendly experiences</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22745.png')} style={{width:'100%'}} alt="" />
            </div>

            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>Logo Design</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>Unique logos for easy recognition</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22747.png')} style={{width:'100%'}} alt="" />
            </div>
        </div>

        <div className="col-md-6 col-lg-5 px-2 px-md-3 px-lg-5">
            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>Content Creation</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>Compelling content that engages readers</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22742.png')} style={{width:'100%'}} alt="" />
            </div>

            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>Profile Maintenance</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>Regular updates on all Social Profiles</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22744.png')} style={{width:'100%'}} alt="" />
            </div>

            <div className='mb-4 mb-md-5 pb-4'>
                <div className='d-flex align-items-end justify-content-between mb-2'>
                    <div>
                    <p className='text-white fw-medium fs-5 m-0'>Ad Shoot & Photography</p>
                    <p className='text-white m-0' style={{fontSize:'12px'}}>High-quality images that grab attention</p>
                    </div>
                    <p className='m-0' style={{color:'#DD24E1',fontSize:'13px'}}>2.3 ETH</p>
                </div>
                <img src={require('../images/innerpage5/Rectangle 22746.png')} style={{width:'100%'}} alt="" />
            </div>

            <button className='btn d-flex btn-light rounded-0 align-items-center justify-content-between fw-medium py-2 px-3 d-block ms-auto' style={{fontSize:'14px'}}>Explore More <span className='ms-2'><Arrow_svg/></span></button>
        </div>
      </div>
    </div>
  )
}

export default Brand_building_layout3
