import React from 'react'

const Ovcr_layout2 = () => {
    const card_data=[
        {
        heading:"User-Friendly Interface for All Users",
        para:"Navigate effortlessly with a design that is simple and meets everyone’s needs.",
        img:"/asset/ocricon1.png"
    },
    {
        heading:"Personalized Learning Paths for Effective Outcomes",
        para:"Customize learning experiences to suit individual preferences and learning styles for maximum impact.",
        img:"/asset/ocricon2.png"
    },
    {
        heading:"Detailed Analytics for Informed Decisions",
        para:"Gain valuable insights to enhance performance and guide your engagement strategies.",
        img:"/asset/ocricon3.png"
    },
    {
        heading:"Collaborative Tools for Group Activities",
        para:"Encourage teamwork with features that enable smooth communication and collaboration.",
        img:"/asset/ocricon4.png"
    },
]

  return (
    <div className='py-4 py-md-5 container'>
        <div className='ocr-layout2-div mx-auto'>
        <p className='fs-1 fw-medium text-center'>A Software Experience Unlike Any Other
        </p>
        <p className='fs-5 text-center'>Innovative solutions designed for enhanced management and user satisfaction in educational environments.</p>
      </div>

      <div className="row m-0 mt-5">
       {card_data.map((x)=>{
        return(
            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
            <div>
                <img src={x.img} alt="" style={{width:'40%'}} className='d-block mx-auto'/>
                <p className='fw-medium text-center mt-3'>The Complete Guide to Your Dashboard Report</p>
                <p className='text-center' style={{fontSize:'13px'}}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem</p>
            </div>
        </div>
        )
       })}
      </div>
    </div>
  )
}

export default Ovcr_layout2
