import React, { useEffect, useRef, useState } from 'react';
import Home_layout3 from './Home_layout3';
import Why_choose_us from './How_it_works';
import Client_reviews from './Client_reviews';
import News_and_events from './News_and_events';
import ScrollEffect2 from './Animation_page1';
import Blogs from './Blogs';
import Footer from './Footer';
import Navbar from './Navbar';
import Icon_div from './Icon_div';
import Scrolltotop from '../Scrolltotop';
import Backtotop from '../Backtotop';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

const Homepage = () => {
  const [state, setstate] = useState(false);

  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const [isVisible4, setIsVisible4] = useState(true);
  const [isVisiblewhite, setIsVisiblewhite] = useState(false);

  useEffect(() => {
    // Set a timer to show the remaining letters and move them after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2500);

    const timer2 = setTimeout(() => {
      setIsVisible2(true);
    }, 2800);

    const timer3 = setTimeout(() => {
      setIsVisible3(true);
    }, 3300);

    // const timer2 = setTimeout(() => {
    //   setIsVisible2(true);
    //   setIsVisible4(false)
    // }, 10000);

    // const timer4 = setTimeout(() => {
    //   setIsVisiblewhite(true);
    //   setIsVisible4(false)
    // }, 7000);

    // const timer5 = setTimeout(() => {
    //   setIsVisible4(true);
    // }, 11000);

    // return () => clearTimeout(timer5);

    return () => clearTimeout(timer); // Clean up the timer on unmount
    // return () => clearTimeout(timer2); // Clean up the timer on unmount
    return () => clearTimeout(timer3);
    // return ()=> clearTimeout(timer4)
  }, []);

  const [isVisible3, setIsVisible3] = useState(false);
  const divRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false); // Track whether the gradient animation has run
  const [isGradientVisible, setIsGradientVisible] = useState(false);
  const [isBgColorVisible, setIsBgColorVisible] = useState(false);

  // Intersection observer to detect div visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated) {
          setIsGradientVisible(true); // Show the gradient first
          setHasAnimated(true); // Ensure animation happens only once

          // After 6 seconds, switch to the background color effect
          setTimeout(() => {
            setIsGradientVisible(false); // Remove the gradient
            setIsBgColorVisible(true); // Show background colors
          }, 6000); // Delay for 6 seconds
        }
      },
      { threshold: 0.5 }, // Trigger when 50% of the div is visible
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, [hasAnimated]);

  const homeSideLinks = {
    leftSide: [
      {
        name: 'Software Consulting',
        to: '/service/software-consulting',
      },
      {
        name: 'Mobile App Development ',
        to: '/service/best-mobile-app-development-company-bengaluru',
      },
      {
        name: 'Website Development',
        to: '/service/website-development-company',
      },
      {
        name: 'Logo Designing',
        to: '/service/logo-designing-company',
      },
    ],
    rightSide: [
      {
        name: 'Graphic Designing',
        to: '/service/graphic-design-services',
      },
      {
        name: 'Digital Marketing',
        to: '/service/digital-marketing',
      },
      {
        name: 'Lead Generation',
        to: '/service/lead-generation-services',
      },
      {
        name: 'Brand Building',
        to: '/service/brand-building',
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <title>The Best Software Services and Digital Marketing Company</title>
        <meta
          name="description"
          content="Achieve your business goal with our software solutions and digital marketing services to drive growth, Increase conversion and ROI with a brand in the industry."
        />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        <link
          rel="canonical"
          href={`https://www.meridatechminds.com`}
        />
      </Helmet>

      <div>
        <h1 className="d-none">software development company</h1>
        <h2 className="d-none">digital marketing company</h2>

        <div
          className="main-container"
          style={{
            position: 'relative',
            overflow: 'hidden',
            opacity: isVisiblewhite ? 0 : 1,
            transition: '0.6s ease-in',
          }}
        >
          <div
            className=""
            style={{ opacity: isVisible3 ? 1 : 0, transition: '0.6s ease-in' }}
          >
            <Navbar setstate={setstate} />
          </div>

          <div className="m-0">
            {/* <img src={require('../images/Merida Icon.png')} style={{width:'40px',position:'absolute'}} className='main-box' alt="" /> */}
            <div className="">
              <div className={isVisible3 ? 'd-none' : ''}>
                <div
                  class={`text-container2`}
                  style={{
                    opacity: isVisible ? 0 : 1,
                    transition: '0.6s ease-out',
                  }}
                >
                  <span
                    class="letter2 left fw-medium"
                    style={{ transition: '0.5s ease-out' }}
                  >
                    M
                  </span>
                  <span
                    class="letter2 left fw-medium"
                    style={{ transition: '0.5s ease-out' }}
                  >
                    A
                  </span>
                  <span
                    class="rest fw-medium"
                    style={{ transition: '0.5s ease-out' }}
                  >
                    ERID
                  </span>
                </div>
                {/* <img src={require('../images/633b16170a5347d8d6ee468c_home-experience-video-image.jpg.png')} alt="" /> */}
              </div>

              <p
                className={`home-text d-lg-none`}
                style={{
                  backgroundSize: 'cover',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  fontSize: '100px',
                  opacity: isVisible3 ? 1 : 0,
                  transition: '0.5s ease-in',
                }}
              >
                MERIDA
              </p>
              <p
                className="text-center d-lg-none"
                style={{
                  fontSize: '54px',
                  letterSpacing: '8px',
                  color: '#ECECEF',
                  opacity: isVisible3 ? 1 : 0,
                  transition: '0.5s ease-in',
                }}
              >
                A CATALYST FOR CREATIVE DESIGN
              </p>

              <div className="d-none bg-danger d-lg-inline">
                <div
                  className="row m-0"
                  style={{
                    opacity: isVisible3 ? 1 : 0,
                    transition: '0.5s ease-in',
                    zIndex: 3,
                    paddingTop: '100px',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      zIndex: 3,
                    }}
                    className="col-lg-2   ps-0 d-none d-lg-block"
                  >
                    {homeSideLinks.leftSide.map((each, idx) => {
                      return (
                        <p
                          key={idx}
                          onClick={() => {
                            navigate(each.to);
                          }}
                          style={{
                            backgroundColor: '#fbfbfc',
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          className={`p-3 text-uppercase ${
                            idx !== 0 && 'mt-5'
                          }`}
                        >
                          {each.name}
                        </p>
                      );
                    })}
                  </div>
                  <div className="col-lg-8">
                    <p
                      className={`home-text`}
                      style={{
                        backgroundSize: 'cover',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                      }}
                    >
                      MERIDA
                    </p>

                    <p
                      className="text-center px-lg-5 mt-4"
                      style={{ fontSize: '14px', letterSpacing: '1px' }}
                    >
                      Your one-stop solution for all your business needs, with
                      experts at your service every step of the way!
                    </p>
                  </div>

                  <div className="col-lg-2 pe-0 d-none d-lg-block">
                    {homeSideLinks.rightSide.map((each, idx) => {
                      return (
                        <p
                          onClick={() => navigate(each.to)}
                          key={idx}
                          style={{
                            backgroundColor: '#fbfbfc',
                            fontSize: '12px',
                            cursor: 'pointer',
                          }}
                          className={`p-3 text-end text-uppercase ${
                            idx !== 0 && 'mt-5'
                          }`}
                        >
                          {each.name}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <p
              className={`ms-3 bg-info d-none`}
              style={{
                position: 'absolute',
                left: 0,
                top: '190px',
                width: '28%',
                fontSize: '12px',
                transition: '0.6s ease',
                opacity: isVisible ? 1 : 0,
              }}
            >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius
              odio, totam qui animi fuga consequatur similique rem dignissimos
              culpa. Quidem?
            </p>
          </div>

          {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius odio, totam qui animi fuga consequatur similique rem dignissimos culpa. Quidem?</p> */}

          <div
            className={`main-subdiv text-center d-none d-lg-inline`}
            style={{
              opacity: isVisible3 ? 1 : 0,
              zIndex: 4,
              width: '80%',
              paddingTop: '60px',
            }}
          >
            <p
              style={{
                fontSize: '54px',
                letterSpacing: '8px',
                color: '#ECECEF',
              }}
            >
              A CATALYST FOR CREATIVE DESIGN
            </p>
          </div>

          <div
            className="main-div8 "
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: '-50px',
              opacity: isVisible3 ? 1 : 0,
            }}
          >
            <div className="main-div1 animate__animated ">
              {/* <p className='positioned-text' style={{bottom:'35%'}}>LOGO DESIGN <i class="fa-solid fa-square ms-2" style={{color:'#d9d9d9'}}></i></p> */}
            </div>

            <div className="main-div2">
              {/* <p className='positioned-text' style={{bottom:'37%'}}>SEO<i class="fa-solid fa-square ms-2" style={{color:'#dc204c'}}></i></p> */}
            </div>

            <div className="main-div3">
              {/* <p className='positioned-text' style={{bottom:'40%'}}>UI & UX <i class="fa-solid fa-square ms-2" style={{color:'#344296'}}></i></p> */}
            </div>

            <div className="main-div4">
              {/* <p className='positioned-text' style={{bottom:'42%'}}>VIDEO EDITING <i class="fa-solid fa-square ms-2" style={{color:'#f2f326'}}></i></p> */}
            </div>

            <div className="main-div5">
              {/* <p className='positioned-text' style={{bottom:'44%'}}>DEVELOPMENT <i class="fa-solid fa-square ms-2" style={{color:'#43134f'}}></i></p> */}
            </div>

            <div className="main-div6">
              {/* <p className='positioned-text' style={{bottom:'45%'}}>PORTFOLIO <i class="fa-solid fa-square ms-2" style={{color:'#3df550'}}></i></p> */}
            </div>
          </div>
        </div>

        <div
          ref={divRef}
          className={`row m-0 ${isGradientVisible ? 'floating-gradient' : ''}`}
          style={{ height: '1vh', transition: 'background 1s ease' }}
        >
          {/* Columns with background color change */}
          <div
            className="col-4"
            style={{
              background: isBgColorVisible ? '#d2ff3a' : 'transparent',
              transition: '1s ease',
            }}
          ></div>
          <div
            className="col-4"
            style={{
              background: isBgColorVisible ? '#5b6cff' : 'transparent',
              transition: '1s ease',
            }}
          ></div>
          <div
            className="col-4"
            style={{
              background: isBgColorVisible ? '#1e2234' : 'transparent',
              transition: '1s ease',
            }}
          ></div>
        </div>

        <ScrollEffect2 />

        <Home_layout3 />

        <Why_choose_us />

        <Client_reviews />

        {/* <section id="newsandevents">
          <News_and_events />
        </section> */}

        <Blogs />

        <Scrolltotop />
        <Backtotop />
      </div>
    </div>
  );
};

export default Homepage;
