import React from 'react'
import { useNavigate } from 'react-router-dom'

const Algo_layout7 = () => {
  const navigate=useNavigate()

  return (
    <div className='p-3'>
        <div className='algo-layout7 pt-4 pt-md-5'>
            <div className='algo-layout7-subdiv mx-auto'>
                <p className='fs-1 fw-medium text-center'>The Future of Trading is Already Here</p>
                <p className='text-secondary text-center'>Experience trading redefined with algo trading systems that work 24/7 without manual effort. Start trading with confidence, knowing the system handles everything. Get in touch today to explore more about our services or start your journey now!</p>
                <button onClick={()=>navigate('/contact-us')} className='mt-4 mt-md-5 btn rounded-pill d-block mx-auto px-4 py-3 text-white fw-medium' style={{backgroundColor:'#3A91D0',fontSize:'13px',letterSpacing:'2px'}}>CONTACT US</button>
            </div>
            <div className='algo-contact-div container mt-4 mt-md-5' style={{height:'90vh',borderRadius:'30px 30px 0px 0px'}}>

            </div>
        </div>
    </div>
  )
}

export default Algo_layout7
