import React from 'react'
import Svg1 from '../../Svgs/Products/Svg1'
import Svg2 from '../../Svgs/Products/Svg2'
import Svg3 from '../../Svgs/Products/Svg3'

const CRM_layout2 = () => {
  return (
    <div className='py-5' style={{minHeight:'70vh'}}>
       <div className="container py-5 row m-0 mx-auto justify-content-evenly">
       <p className='fs-1 fw-bold text-center'>Building Better Customer Relationships Through Smart CRMs</p>
       <p style={{fontSize:'13px'}} className='text-center mb-5'>Get a CRM made just for you. We help you set it up, improve what you have, and support you along the way.</p>
       <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
        <div className='crm-product-card'>
            <Svg1/>
            <p className='mt-4'>Develop Your CRM</p>
            <p style={{fontSize:'13px'}}>Enhance your business with a custom CRM designed to your needs. Our services include CRM consulting, CRM implementation, and ongoing support for success.</p>
        </div>
       </div>

       <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
        <div className='crm-product-card'>
            <Svg2/>
            <p className='mt-4'>Improve Existing CRM</p>
            <p style={{fontSize:'13px'}}>Upgrade your current system with our expert CRM migration services. We provide seamless CRM integration and implement features to improve customer communication.</p>
        </div>
       </div>

       <div className="col-lg-3 col-md-6">
        <div className='crm-product-card'>
            <Svg3/>
            <p className='mt-4'>Advanced CRM Solutions</p>
            <p style={{fontSize:'13px'}}>Utilize customer data analytics to improve engagement. With personalization strategies and machine learning tools get better customer insights.</p>
        </div>
       </div>
       </div>
    </div>
  )
}

export default CRM_layout2
