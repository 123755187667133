import React from 'react'
import Algo_layout2 from './Algo_layout2'
import Algo_layout3 from './Algo_layout3'
import Algo_layout4 from './Algo_layout4'
import Algo_layout5 from './Algo_layout5'
import Algo_layout6 from './Algo_layout6'
import Algo_layout7 from './Algo_layout7'
import Navbar from '../../Landingpages/Navbar'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Algo_trading = () => {
  const navigate=useNavigate()

  return (
    <div>
      <Helmet>
        <title>Algo Trading Solutions for Smarter Investments</title>
        <meta name="description" content="Explore our algo trading solutions that automate buying and selling, ensuring timely trades and improved profits. Start trading smarter today!" />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Automate Your Trades for Better Financial Outcomes</h1>
      <h2 className="d-none">Algo Trading Solutions</h2>

      <div>
      <div className="p-3">
      <div className='algo-trading-div'>
        <Navbar/>

        <div className='row m-0 align-items-center mx-auto container' style={{minHeight:'80vh'}}>
        <div className="col-lg-6">
       <button className='d-flex border-0 py-1 rounded-pill px-3 align-items-center fw-medium' style={{fontSize:'14px',letterSpacing:'2px',backgroundColor:'rgba(255, 255, 255,0.6)'}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#4AA3CC" class="bi bi-dot" viewBox="0 0 16 16">
  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
</svg>
            <span>ALGO TRADING</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#4AA3CC" class="bi bi-dot" viewBox="0 0 16 16">
  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
</svg>
        </button>

        <p className='algo-text fw-medium mt-4 pt-md-3' style={{lineHeight:'68px'}}>Smarter Trading with Reliable Algo Automation</p>
        <p className='mt-4' style={{fontSize:'14px'}}>Intelligent Algorithms That Helps You Make Better Financial Decisions</p>
        <button onClick={()=>navigate('/contact-us')} className='mt-4 btn rounded-pill px-4 py-3 text-white fw-medium' style={{backgroundColor:'#3A91D0',fontSize:'13px'}}>GET STARTED</button>
       </div>

       <div className="col-lg-6">
        <img src={require('../../images/mtm_images/algo trading 1.png')} style={{width:'100%'}} alt="" />
       </div>
        </div>
</div>
      </div>

      <Algo_layout2/>
      <Algo_layout3/>
      <Algo_layout4/>
      <Algo_layout5/>
      <Algo_layout6/>
      <Algo_layout7/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Algo_trading
