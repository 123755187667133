import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.0566406" width="40" height="40" rx="16" fill="#3F96CF" fill-opacity="0.06"/>
<path d="M28.168 13.5391C28.3672 13.7383 28.5 14.0039 28.5 14.3027V21.8066C28.5 23.002 27.5371 23.9316 26.375 23.9316H20C18.8047 23.9316 17.875 23.002 17.8418 21.8066V13.3066C17.8418 12.1445 18.7715 11.1816 19.9668 11.1816H25.3789C25.6777 11.1816 25.9434 11.3145 26.1426 11.5137L28.168 13.5391ZM26.9062 21.8066H26.873V15.4316H25.3125C24.7148 15.4316 24.25 14.9668 24.25 14.3691L24.2168 12.8086H19.9668C19.668 12.8086 19.4355 13.041 19.4355 13.3398V21.8066C19.4355 22.1055 19.668 22.3379 19.9668 22.3379H26.375C26.6406 22.3379 26.9062 22.1055 26.9062 21.8066ZM20.5312 26.0566V24.9941H22.125V26.0566C22.125 27.252 21.1621 28.1816 20 28.1816H13.625C12.4297 28.1816 11.5 27.252 11.5 26.0566L11.4668 17.5566C11.4668 16.3945 12.4297 15.4316 13.5918 15.4316H16.8125V17.0586H13.5918C13.3262 17.0586 13.0605 17.291 13.0605 17.5898V26.0566C13.0605 26.3555 13.293 26.5879 13.5918 26.5879H20C20.2656 26.5879 20.5312 26.3555 20.5312 26.0566Z" fill="#3F96CF"/>
</svg>
    </div>
  )
}

export default Svg1
