import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:" How do I get started if I want a website?",
            answer:"It’s super simple! Just tell us what’s on your mind, and we will handle everything from planning to designing to building."
        },
        {
            question:"When can I expect my website to be up and running?",
            answer:"Most websites take around two to six weeks, but it depends on the features you want. We try our best to get things done quickly without keeping you waiting forever."
        },
        {
            question:"Will my website show up on Google?",
            answer:"Yes, yes, and yes! We use smart SEO techniques to help your website rank better on Google and attract the right audience to your business."
        },
        {
            question:"How much will it cost to build my website?",
            answer:"It all depends on the type of site you're looking for. Whether you need a simple website or a full-on online store, we will give you a plan that fits your budget perfectly."
        },
        {
            question:"What happens if something breaks on the website after it’s live",
            answer:"Not to worry! We’re here for you with post-launch support. Just reach out, and we’ll take care of any issues and fix things up in no time."
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
