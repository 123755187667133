import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Inner1_Mainpage from './Inner_page1/Inner1_Mainpage';
import Inner_page2 from './Inner_page2/Inner_page2';
import Inner_page3 from './Inner_page3/Inner_page3';
import Inner_page4 from './Inner_page4/Inner_page4';
import Web_development from './Web_development/Web_development';
import Mobile_app_development from './Mobile_app_development/Mobile_app_development';
import Software_consulting from './Software_consulting/Software_consulting';
import Brand_building from './Brand_building/Brand_building';

const Services = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/digital-marketing"
          element={<Inner1_Mainpage />}
        />
        <Route
          path="/lead-generation-services"
          element={<Inner_page2 />}
        />
        <Route
          path="/logo-designing-company"
          element={<Inner_page3 />}
        />
        <Route
          path="/graphic-design-services"
          element={<Inner_page4 />}
        />
        <Route
          path="/website-development-company"
          element={<Web_development />}
        />
        <Route
          path="/best-mobile-app-development-company-bengaluru"
          element={<Mobile_app_development />}
        />
        <Route
          path="/software-consulting"
          element={<Software_consulting />}
        />
        <Route
          path="/brand-building"
          element={<Brand_building />}
        />
      </Routes>
    </div>
  );
};

export default Services;
