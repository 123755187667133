import React from 'react'

const Hrms_layout2 = () => {

    const data=[
        {
            heading:"Talent Acquisition",
            para:"Find the right people for your team effortlessly. It simplifies job postings, applicant tracking, and candidate communication, making hiring smooth and effective.",
            img:"/asset/01.png"
        },
        {
            heading:"Onboarding Support",
            para:"Make new hires feel welcome. Our onboarding service provides checklists and training materials to help them learn and settle in quickly and confidently.",
            img:"/asset/02.png"
        },
        {
            heading:"Payroll Management",
            para:"Ensure everyone gets paid accurately and on time. This system automates calculations and deductions, making payroll processing simple and minimizing errors for smooth operations.",
            img:"/asset/03.png"
        },
        {
            heading:"Performance Tracking",
            para:"Keep track of how everyone is doing. This tool offers regular feedback and goal-setting features that help employees improve and reach their personal best.",
            img:"/asset/04.png"
        },
    ]

  return (
    <div className='bg-light py-4 py-md-5' style={{minHeight:'100vh'}}>
        <button style={{backgroundColor:'#C866D8',fontSize:'14px',letterSpacing:'1px'}} className='d-block mx-auto text-white fw-medium btn'>STATS</button>
        <p className='fs-1 text-center fw-medium'>Building Better Workplaces One Service at a Time</p>
      <div className='row m-0 container mx-auto justify-content-center mt-4 mt-md-5'>
        {data.map((x)=>{
            return(
                <div className="col-lg-5 col-md-6 mb-4">
            <div className='bg-white p-2 p-lg-4 shadow-sm rounded'>
                <img src={x.img} alt="" />
                <p className='fw-medium mt-3'>{x.heading}</p>
                <p style={{fontSize:'13px'}}>{x.para}</p>
            </div>
        </div>
            )
        })}
      </div>
    </div>
  )
}

export default Hrms_layout2
