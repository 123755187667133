import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="60" height="60" viewBox="0 0 75 79" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.7503 0.168701C22.7503 30.3701 46.0576 54.8545 74.807 54.8545V78.0604C33.8584 78.0604 0.660156 43.1855 0.660156 0.168701H22.7503Z" fill="currentColor"/>
<path d="M37.7344 0.168701H74.8078V39.1145C54.3265 39.1145 37.7344 21.6697 37.7344 0.168701Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg1
