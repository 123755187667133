import React from 'react'

const Printlele_layout2 = () => {
    const data=[{
        heading:"Cloud Integration",
        para:"Access printers remotely and manage jobs from anywhere with real-time tracking and control.",
        img:"/asset/Icon (1).png"
    },
    {
        heading:"Multi-Format Support",
        para:"Print PDFs, images, and Word files easily, without worrying about file compatibility issues.",
        img:"/asset/Icon (2).png"
    },
    {
        heading:"User Permissions",
        para:"Control who can print what, ensuring secure access for individual users or teams.",
        img:"/asset/Icon (3).png"
    },
    {
        heading:"Print Queue Management",
        para:"Prioritize tasks by urgency to ensure smooth operations and avoid unnecessary delays.",
        img:"/asset/Icon (4).png"
    },
    {
        heading:"Custom Print Settings",
        para:"Personalize layouts, choose paper sizes, and select color options to meet your printing needs.",
        img:"/asset/Icon (5).png"
    },
    {
        heading:"Cost Tracking",
        para:"Monitor printing expenses with detailed reports to reduce waste and save money.",
        img:"/asset/Icon (6).png"
    }
    ]

  return (
    <div className='printlele-layout2-div py-5'>
      <div className="container pt-md-5">
       <div className=''>
        <p className='text-center' style={{color:'#36B37E'}}>FEATURES</p>
       <p className="text-center fw-medium fs-2" style={{color:'#183B56'}}>What Printlele Offers You?</p>
       <p className='text-center' style={{fontSize:'13px',color:'#5A7184'}}>Handle all printing tasks with organized tools designed to make the entire process smoother, faster, and more efficient.</p>
       </div>
       <div className="row m-0 justify-content-center mt-5 pt-md-3">
       {data.map((x)=>{
        return(
            <div className="col-lg-4 col-md-6 mb-4">
            <div className='p-4 d-flex align-items-start'>
                <img src={x.img} style={{width:'12%'}} alt="" />
                <div className='ms-3'>
                    <p className='fw-medium m-0 mb-2' style={{color:'#183B56'}}>{x.heading}</p>
                    <p className='m-0' style={{fontSize:'13px',color:'#5A7184',lineHeight:'24px'}}>{x.para}</p>
                </div>
            </div>
        </div>
        )
       })}
        
       </div>
      </div>
    </div>
  )
}

export default Printlele_layout2
