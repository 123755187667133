import React from 'react'
import Svg1 from '../Svgs/Inner_page2/Svg1'

const Website_Layout3 = () => {
  
  return (
    <div className='inner2-layout3 py-5 container'>
      <p className='fs-1 fw-medium text-center m-0'>Our Web Development Services</p>
      <img src={require('../images/innerpage2/Group 1171275043.png')} className='d-block mx-auto mt-3' alt="" />
      <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>We develop customized websites that meet specific business needs, ensuring smooth functionality across different industries.</p>

      <div className="row m-0 mt-5">
        <div className="col-md-6 col-lg-3 p-0">
            <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275122.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Construction Company</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Custom websites to showcase your construction projects and connect effectively with ideal clients for new contracts.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 10.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Ecommerce 
                  Business</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Building online stores with easy-to-use shopping systems that attract new customers and improve product sales.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275123.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>HVAC Service Company</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Web solutions for HVAC firms to manage customer inquiries, schedule services, and display detailed service options.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275124.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Local Vendors</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Custom sites provided for vendors who want to promote their products locally and expand customer reach quickly.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275125.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Custom Email Design</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Personalized email templates integrated into websites for professional communication with customers.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275126.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Monitoring Ranking</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Website interfaces for financial monitoring tools that help banks track and manage client accounts effectively.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275127.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Code Optimization</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>Refining the website’s code to make it faster, more efficient, and enhance the user experience.</p>
                </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-3 p-0">
        <div className='website-layout3-card shadow'>
                <div className=''>
                    <img src={require('../images/innerpage4/Group 1171275129.png')} className='website_card_img' alt="" />
                </div>
                <div className=''>
                  <p className=' fs-4 fw-medium'>Nap Syndication</p>
                  <p className="" style={{fontSize:'13px',lineHeight:'24px'}}>We make sure your business name, address, and phone number are consistent online to improve visibility.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Website_Layout3
