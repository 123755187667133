import React from 'react'

const Inner_svg3 = () => {
  return (
    <div>
      <svg width="78" height="50" viewBox="0 0 78 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M55.9566 42.0833C55.9566 45.4888 53.1956 48.25 49.7901 48.25H8.16643C4.761 48.25 1.99995 45.4888 1.99995 42.0833V8.16663C1.99995 4.76109 4.761 1.99996 8.16643 1.99996H49.7901C53.1956 1.99996 55.9566 4.76109 55.9566 8.16663V42.0833Z" stroke="#EBFD05" stroke-width="3" stroke-linecap="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.9989 8.16313L55.9555 18.9569V31.2927L75.9989 42.0865V8.16313Z" stroke="white" stroke-width="3" stroke-linecap="round"/>
</svg>

    </div>
  )
}

export default Inner_svg3
