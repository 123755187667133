import React from 'react'

const Check = () => {
  return (
    <div>
      <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6875 0.8125C15.0938 1.1875 15.0938 1.84375 14.6875 2.21875L6.6875 10.2188C6.3125 10.625 5.65625 10.625 5.28125 10.2188L1.28125 6.21875C0.875 5.84375 0.875 5.1875 1.28125 4.8125C1.65625 4.40625 2.3125 4.40625 2.6875 4.8125L5.96875 8.09375L13.2812 0.8125C13.6562 0.40625 14.3125 0.40625 14.6875 0.8125Z" fill="#AC21D3"/>
</svg>

    </div>
  )
}

export default Check
