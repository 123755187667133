import React from 'react'

const Payroll_layout3 = () => {
  return (
    <div className='py-5'>
      <div className='container'>
        <div className='payroll-layout3-subdiv mx-auto'>
        <p style={{color:'#4F14DE',letterSpacing:'3px'}} className='fs-5 text-center fw-medium'>HOW IT WORKS</p>
        <p className='fs-2 fw-bold text-center'>A Simple Three-Step process</p>
        <p className='text-secondary text-center mb-4 mb-md-5 pb-md-3'>Our straightforward approach helps users manage payroll tasks easily, ensuring accuracy and timely payments with minimal effort.</p>
        </div>

       <div className="row m-0 justify-content-center">
       <div className="col-lg-4 px-md-4 px-2 col-md-6 mb-4 mb-lg-0">
            <div className='payroll-layout3-card border p-3'>
                <div className='py-4'>
                    <img src={require('../../images/products/Group 33894.png')} style={{width:'100%'}} alt="" />
                </div>
                <p className='fw-bold text-center'>Collect Employee Data Automatically</p>
                <p className='text-secondary text-center m-0' style={{fontSize:'13px'}}>Easily capture attendance and work hours using connected systems.</p>
            </div>
        </div>
        <div className="col-lg-4 px-md-4 px-2 col-md-6 mb-4 mb-lg-0">
            <div className='payroll-layout3-card border p-3'>
                <div className='py-4'>
                    <img src={require('../../images/products/Group 33894.png')} style={{width:'100%'}} alt="" />
                </div>
                <p className='fw-bold text-center'>Calculate Payments and Deductions Instantly</p>
                <p className='text-secondary text-center m-0' style={{fontSize:'13px'}}>Our system ensures taxes and benefits are applied accurately.</p>
            </div>
        </div>
        <div className="col-lg-4 px-md-4 px-2 col-md-6 mb-4 mb-lg-0">
            <div className='payroll-layout3-card border p-3'>
                <div className='py-4'>
                    <img src={require('../../images/products/Group 33894.png')} style={{width:'100%'}} alt="" />
                </div>
                <p className='fw-bold text-center'>Process and Transfer Salaries Securely</p>
                <p className='text-secondary text-center m-0' style={{fontSize:'13px'}}>Initiate payments with a single click, ensuring timely salary transfers</p>
            </div>
        </div>
       </div>
      </div>
    </div>
  )
}

export default Payroll_layout3
