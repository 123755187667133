import React from 'react'
import { useNavigate } from 'react-router-dom'

const Contact_us_layout = () => {
  const navigate=useNavigate()
  return (
    <div className='contact-us-layout d-flex align-items-center'>
      <div className="row container m-0 mx-auto">
        <div className="col-md-6 ">
            <p className='fs-1 fw-medium text-white'>Get Started With Us Today</p>
            <p className='text-white'>Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular.</p>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
            <button className='btn btn-light rounded-pill px-5 fw-bold py-2' onClick={()=>navigate('/contact-us')}>Contact Us</button>
            <button className='btn border text-white rounded-pill px-5 fw-bold py-2 ms-4'>Get A Quote</button>
        </div>
      </div>
    </div>
  )
}

export default Contact_us_layout
