import React from 'react'

const Ticket_layout2 = () => {
    const data=[{
        heading:"User-Friendly Interface",
        para:"Our intuitive design simplifies ticket navigation, ensuring easy access for users and support teams.",
        img:"/asset/Badge (1).png"
    },
    {
        heading:"Automated Notifications",
        para:"Receive timely alerts for ticket updates, ensuring that no request goes unnoticed.",
        img:"/asset/Badge (2).png"
    },
    {
        heading:"Priority Tagging",
        para:"Tickets can be prioritized, helping teams focus on what matters most first to enhance responsiveness.",
        img:"/asset/Badge (3).png"
    },
    {
        heading:"Reporting Tools",
        para:"Get detailed reports on ticket resolution times, customer satisfaction, and team performance.",
        img:"/asset/Badge (4).png"
    },
    {
        heading:"Customizable Workflows",
        para:"Personalize the ticketing process to match your organization’s specific needs and workflows.",
        img:"/asset/Badge (5).png"
    },
    {
        heading:"Multi-Channel Support",
        para:"Manage tickets from different sources such as email, chat, and social media in a single platform.",
        img:"/asset/Badge.png"
    }
    ]

  return (
    <div className='py-5' style={{backgroundColor:'#F8F8F8'}}>
      <div className="container pt-md-5">
       <div className=''>
        <button className='text-center p-2 px-3 btn rounded fw-medium m-0 d-block mx-auto' style={{color:'#36B37E',backgroundColor:'#E6F7F2'}}>FEATURES</button>
       <p className="text-center fw-bold fs-2 mt-3" style={{color:'#183B56'}}>Explore tools that enhance support request management</p>
       <p className='text-center' style={{fontSize:'13px',color:'#5A7184'}}>Our platform offers intuitive tools that simplify your navigation, give instant alerts, and customizable workflows for all users.</p>
       </div>
       <div className="row m-0 justify-content-center mt-5 pt-md-3">
       {data.map((x)=>{
        return(
            <div className="col-lg-4 col-md-6 mb-4">
            <div className='p-4 bg-white rounded shadow-sm ticket-layout2-cards'>
                <div className='d-flex align-items-center'>
                <img src={x.img} style={{width:'12%'}} alt="" />
                    <p className='fw-bold m-0 ms-3' style={{fontSize:'17px'}}>{x.heading}</p>
                </div>
                <p className='m-0 mt-3 text-secondary' style={{fontSize:'14px',lineHeight:'24px'}}>{x.para}</p>
            </div>
        </div>
        )
       })}
        
       </div>
      </div>
    </div>
  )
}

export default Ticket_layout2
