import React from 'react'

const Svg4 = () => {
  return (
    <div>
      <svg width="81" height="70" viewBox="0 0 81 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.1595 31.3647C40.2514 35.9684 36.5349 39.7039 31.9548 62.7297C27.3746 39.7001 23.6582 35.9684 0.75 31.3647C23.6582 26.7611 27.3746 23.0255 31.9548 -0.000244141C36.5349 23.0255 40.2514 26.7611 63.1595 31.3647Z" fill="white"/>
<path d="M74.0212 54.0675C62.5652 56.3712 60.7107 58.2352 58.4188 69.75C56.1269 58.2352 54.2724 56.3712 42.8164 54.0675C54.2724 51.7638 56.1269 49.8998 58.4188 38.385C60.7107 49.8998 62.5652 51.7638 74.0212 54.0675Z" fill="white"/>
<path d="M80.2518 25.2617C73.1218 26.6935 71.9664 27.8548 70.5419 35.0214C69.1174 27.8548 67.9621 26.6935 60.832 25.2617C67.9621 23.8299 69.1174 22.6686 70.5419 15.502C71.9664 22.6686 73.1218 23.8299 80.2518 25.2617Z" fill="white"/>
</svg>

    </div>
  )
}

export default Svg4
