import React from 'react'

const Svg2 = () => {
  return (
    <div>
      <svg width="60" height="60" viewBox="0 0 78 94" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.1904 0.679199L39.0764 0.746225L38.9624 0.679199L0.949219 23.8969V70.3323L38.9624 93.55L76.9755 70.3323V24.2052L77.343 23.9773L39.1904 0.679199ZM8.93198 23.9907L39.0764 5.57208L69.3349 24.0444L39.1904 42.463L8.93198 23.9907Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg2
