import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"What is Software Consulting?",
            answer:"Software consulting means professionals help you choose, set up, and use the best software solutions that fit your business perfectly."
        },
        {
            question:"How can software consulting help my business?",
            answer:"By choosing the right tools, you’ll run things smoother, avoid unnecessary expenses, and get more done in less time. With the right technology solutions, you can stay ahead of your competition too!"
        },
        {
            question:"What should I consider when selecting software?",
            answer:"It’s all about what your business needs. Look for software that’s easy to use, fits your budget, and can grow with your company. Software consultants help you avoid confusion and pick exactly what’s right."
        },
        {
            question:"Is hiring a software consultant worth it?",
            answer:"Absolutely! With a consultant guiding you, you’ll skip the trial-and-error phase. They make sure you avoid costly mistakes and invest in tools that actually help your business run smoothly."
        },
        {
            question:"How long does a typical software consultation take?",
            answer:"The timeline varies depending on your business needs. Most consultations can be completed within a few weeks, ensuring you receive expert recommendations promptly."
        },
        {
            question:"What kinds of businesses benefit from software consulting?",
            answer:"Any business, big or small, can benefit. Whether you're a startup or a large company, software consulting makes sure you’re using the best tools to keep things running efficiently."
        },
        {
            question:"How do I get started with a software consultation?",
            answer:"It’s super simple! Don’t hesitate to reach out via our website or just give us a call. We’ll talk about your needs and get started with finding the best software for your business!"
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
