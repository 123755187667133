import React from 'react'

const Medical_layout6 = () => {
  return (
    <div style={{minHeight:'100vh'}} className='container py-4 py-md-5'>
      <div className='medical-layout6'>
        <p className='fs-1 fw-medium' style={{lineHeight:'50px'}}>How It Works</p>
        <p style={{fontSize:'13px'}} className='text-secondary'>Making Every Step in Billing Easier and Faster</p>
      </div>

      <div className="row m-0 mt-4 mt-md-5">
        <div className="col-lg-6 ps-md-0">
            <div className='border rounded'>
            <div className='d-flex p-4' style={{backgroundColor:'#F7FAFC'}}>
                <p className='rounded-circle bg-white p-2 border m-0 d-flex align-items-center justify-content-center fw-medium fs-5' style={{height:'40px',width:'40px'}}>1</p>
                <div className='ms-3'>
                    <p className='fw-medium fs-5'>Medical Coding Setup</p>
                    <p className="m-0" style={{fontSize:'13px'}}>Convert patient records to codes for accurate claim submission, ensuring alignment with insurance requirements.</p>
                </div>
            </div>
            <div className='d-flex p-4' style={{backgroundColor:'#FFFF'}}>
                <p className='rounded-circle bg-white p-2 border m-0 d-flex align-items-center justify-content-center fw-medium fs-5' style={{height:'40px',width:'40px'}}>2</p>
                <div className='ms-3'>
                    <p className='fw-medium fs-5'>Billing and Submission</p>
                    <p className="m-0" style={{fontSize:'13px'}}>Generate claims and submit them directly to payers, with reduced manual intervention and faster approvals.</p>
                </div>
            </div>
            <div className='d-flex p-4' style={{backgroundColor:'#F7FAFC'}}>
                <p className='rounded-circle bg-white p-2 border m-0 d-flex align-items-center justify-content-center fw-medium fs-5' style={{height:'40px',width:'40px'}}>3</p>
                <div className='ms-3'>
                    <p className='fw-medium fs-5'>Payment and Follow-up</p>
                    <p className="m-0" style={{fontSize:'13px'}}>Track payments and handle unpaid invoices promptly, improving cash flow and minimizing outstanding balances.</p>
                </div>
            </div>
            </div>
        </div>
        <div className="col-lg-6 text-center">
            <img src={require('../../images/products/Frame 820.png')} style={{width:'90%'}} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Medical_layout6
