import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Ticket_layout4 = () => {
  return (
    <div className='pt-md-5'>
      <div className="container py-5 row m-0 mx-auto align-items-center">
      <div className="col-md-6 text-center">
       <img src={require('../../images/mtm_images/ticket management 4.jpg')} className='' style={{width:'90%'}} alt="" />
       </div>

       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-bold' style={{letterSpacing:'1px',lineHeight:'54px'}}>Why Partner with Merida Tech Minds?</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Delivering reliable ticket management solutions that fit your requirements.</p>

       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Experienced development team</span></p>
       <p className='d-flex align-items-center'><Svg6/> <span className='fw-medium ms-3'>Custom solutions for everyone</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Affordable service options</span></p>
       <p className='d-flex align-items-center mt-4'><Svg6/> <span className='fw-medium ms-3'>Proven client satisfaction</span></p>

       
       </div>
      </div>
    </div>
  )
}

export default Ticket_layout4
