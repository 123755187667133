import React, { useState } from 'react'
import Socialmedia from '../Svgs/Socialmedia'
import Socialmedia2 from '../Svgs/Socialmedia2'
import Socialmedia3 from '../Svgs/Socialmedia3'
import Footer from './Footer'
import Navbar from './Navbar'
import Backtotop from '../Backtotop'
import Scrolltotop from '../Scrolltotop'
import { toast } from 'react-toastify'

const Contact_us = () => {

  const [fields,setfields]=useState({
    name:"",
    email:"",
    phone:"",
    message:""
  })

  const updateValues=(e)=>{
    const {name,value}=e.target
      setfields({
        ...fields,
        [name]:value
      })
  }

  const submit_form=()=>{
    toast.success("Submitted Succesfully!",{
      autoClose:1000,
      theme:'colored',
      position:'top-center'
    })
  }

  return (
    <div className='animate__animated animate__fadeIn'>
      <Navbar/>
      <div className='contact-us-div py-5' style={{minHeight:'100vh'}}>
      <div className="row container mx-auto m-0">
        <div className="col-lg-8 ps-md-0">
            <div className='p-3 p-md-5' style={{backgroundColor:'rgb(220,220,220,0.1)',borderRadius:'20px'}}>
                <input type="text" name='name' onChange={updateValues} className="form-control py-3 shadow-none border-0 contact-inputs mb-4" placeholder='Your Name' />
                <input type="text" name='phone' onChange={updateValues} className="form-control py-3 shadow-none border-0 contact-inputs mb-4" placeholder='Your Phone Number' />
                <input type="text" name='email' onChange={updateValues} className="form-control py-3 shadow-none border-0 contact-inputs mb-4" placeholder='Your Email' />
                <textarea type="text" rows={6} name='message' onChange={updateValues}  className="form-control py-2 shadow-none border-0 contact-inputs mb-4" placeholder='Your Email' />
                <button style={{backgroundColor:'#5A2CDA'}} onClick={submit_form} className='btn rounded-pill border-0 text-white fw-medium px-4'>Let's Talk</button>

            </div>
        </div>
        <div className="col-lg-4">
            <div className='p-3 p-md-5' style={{backgroundColor:'#5A2CDA',borderRadius:'15px'}}>
                <p className='fs-3 fw-bold text-white'>Say Hello!</p>
                <p style={{fontSize:'13px'}} className='text-white'>Nam ultricies metus purus laoreet aliquam Aliq
uam lectus urna, tempus ac lectus et, gravida
bibendum Nulla consequat.</p>

<div>
  <img src={require('../images/innerpage3/view-3d-businessman 2.png')} style={{width:'100%',height:'210px'}} alt="" />
</div>

<p className='fw-medium fs-5 text-white m-0 mb-2 mt-4'>Enquires</p>
<p className='text-white m-0 mb-2' style={{fontSize:'13px'}}>info@meridatechminds.com</p>
<div className='d-flex align-items-center'>
    <span><Socialmedia/></span>
    <span className='ms-3'><Socialmedia2/></span>
    <span className='ms-3'><Socialmedia3/></span>
</div>
            </div>
        </div>
      </div>

      <div className="row container mx-auto m-0 py-5 mt-5">
        <div className="col-lg-3 col-md-6">
          <div className='d-flex align-items-start'>
          <img src={require('../images/innerpage4/Group 1171275130.png')} style={{width:'18%'}} alt="" />
          <div className='ms-3'>
          <p className='text-white fw-medium m-0 fs-5 mb-1'>Our Location</p>
          <p className='m-0' style={{fontSize:'12px',color:'#5E5E5E'}}>45 Folgate Street Canberra
          Looloith E1 6GL</p>
          </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className='d-flex align-items-start'>
          <img src={require('../images/innerpage4/Group 1171275131.png')} style={{width:'18%'}} alt="" />
          <div className='ms-3'>
          <p className='text-white fw-medium m-0 fs-5 mb-1'>Mobile Number</p>
          <p className='m-0' style={{fontSize:'12px',color:'#5E5E5E'}}>+00 (0)12 3456 789</p>
          <p className='m-0 mt-1' style={{fontSize:'12px',color:'#5E5E5E'}}>+00 (0)12 3456 789</p>
          </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className='d-flex align-items-start'>
          <img src={require('../images/innerpage4/Group 1171275132.png')} style={{width:'18%'}} alt="" />
          <div className='ms-3'>
          <p className='text-white fw-medium m-0 fs-5 mb-1'>Contact Support</p>
          <p className='mb-1' style={{fontSize:'12px',color:'#5E5E5E'}}>info@example.com</p>
          <p className='mb-1' style={{fontSize:'12px',color:'#5E5E5E'}}>info@example.com</p>
          </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className='d-flex align-items-start'>
          <img src={require('../images/innerpage4/Group 1171275130.png')} style={{width:'18%'}} alt="" />
          <div className='ms-3'>
          <p className='text-white fw-medium m-0 fs-5 mb-1'>Have Quires ?</p>
          <p className='' style={{fontSize:'12px',color:'#5E5E5E'}}>Donec imperdiet magna
          semperex tincidunt feugiat.</p>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <Backtotop/>
    <Scrolltotop/>
    </div>
  )
}

export default Contact_us
