import React from 'react'

const Svg1 = () => {
  return (
    <div>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icon  3">
<circle id="Oval" opacity="0.1" cx="15" cy="15" r="15" fill="#FCFCFD"/>
<circle id="Oval_2" cx="15" cy="15" r="6" fill="#FAAD13"/>
</g>
</svg>
    </div>
  )
}

export default Svg1
