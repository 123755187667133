import React from 'react'
import { useNavigate } from 'react-router-dom'

const Software_layout2 = () => {
  const products=[
  {
    name:"Customer Relationship Management",
    description:"Enhance customer relationships with CRM tools that track interactions and improve engagement.",
    img:"/asset/crm1.jpg",
    slug:"/service/software-consulting/crm-software-solutions"
  },
  {
    name:"Task Manager",
    description:"Keep tasks organized so it’s easy to know what needs to be done each day.",
    img:"/asset/Macbook.png",
    slug:"/service/software-consulting/task-manager-software"
  },
  {
    name:"Payroll Management",
    description:"Effortlessly manage employee salaries and payments to ensure everyone gets paid correctly.",
    img:"/asset/Group2.png",
    slug:"/service/software-consulting/payroll-management-solutions"
  },
  {
    name:"Printlele",
    description:"Quickly print documents and photos with user-friendly tools and features available online.",
    img:"/asset/printlili1.jpg",
    slug:"/service/software-consulting/printlele-printing-solutions"
  },
  {
    name:"Ticket Management System",
    description:"Keep track of customer inquiries and support issues for quick and effective solutions.",
    img:"/asset/ticketmanagement2.jpg",
    slug:"/service/software-consulting/ticket-management-software-solutions"
  },
  {
    name:"Lernenhub",
    description:"Access fun courses and pick up new skills easily, learning whenever it suits you best.",
    img:"/asset/Group1.png",
    slug:"/service/software-consulting/lernen-hub-learning-management-software"
  },
  {
    name:"Algo Trading",
    description:"Automatically buy and sell stocks using simple strategies without needing advanced knowledge.",
    img:"/asset/algotrading1.png",
    slug:"/service/software-consulting/algo-trading-software-solutions"
  },
  {
    name:"Background Verification",
    description:"Quickly check important information about people before hiring for safety and trust.",
    img:"/asset/bgverification1.png",
    slug:"/service/software-consulting/background-verification-services"
  },
  {
    name:"Online Test",
    description:"Create and take tests online easily to check your knowledge and skills effectively.",
    img:"/asset/onlinetest1.png",
    slug:"/service/software-consulting/online-testing-software"
  },
  {
    name:"Medical Billing",
    description:"Manage healthcare payments and insurance claims accurately for smooth processing.",
    img:"/asset/Dashboard.png",
    slug:"/service/software-consulting/best-medical-billing-software"
  },
  {
    name:"Human Resource Management System",
    description:"Efficiently manage employee details and benefits in one user-friendly online platform.",
    img:"/asset/Frame820.png",
    slug:"/service/software-consulting/human-resource-management-software"
  },
]

const navigate=useNavigate()

  return (
    <div className='software-layout2' style={{paddingTop:'150px'}}>

      <div className="container row m-0 mx-auto justify-content-center">
        <p className='text-white fs-1 fw-medium text-center'>Types of Software We <span className='software-layout2-text px-4'>Specialize</span> In</p>
        <p className='text-center text-secondary mb-5 pb-lg-5'>We build software customized to your business needs, providing reliable solutions that support smooth operations and growth.</p>

        {products.map((x,index)=>{
          return(
            <div className='col-lg-5 mb-4'>
            <div className='software-layout2-card d-flex align-items-end' style={{
          backgroundImage: `url(${x.img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white', // Adjust text color for better readability if needed
        }} onClick={()=>navigate(`${x.slug}`)}>
                <div className='p-3 p-md-4'>
                <p className='fw-bold fs-4 m-0 mb-2 mt-4 pt-2'>{x.name}</p>
                <p style={{fontSize:'14px'}}>{x.description}</p>
                </div>
            </div>
        </div>
          )
        })}
      </div>
    </div>
  )
}

export default Software_layout2
