import React from 'react'

const Layout2 = () => {
  return (
    <div className='inner2-layout2 py-5'>
      <p className='text-center'>WE MAKE QUICK RESULT</p>
      <p className='fs-1 fw-bold text-white text-center mt-5'>You’ve Finally Found the Right SEO Company With Answers</p>
      <p className='text-white text-center' style={{fontSize:'14px'}}>Sit risus, vitae nunc sit molestie rutrum odio pellentesque mus. Cursus faucibus elit, aenean tellus ipsum vitae facilisi non. Gravida placerat nisl amet.</p>

      <button style={{backgroundColor:'#FFE600',fontSize:'14px',letterSpacing:'1px'}} className='mt-5 btn d-block mx-auto rounded-pill px-4 py-2'>Contact Now</button>
    </div>
  )
}

export default Layout2
