import React from 'react'

const CRM_layout5 = () => {
  return (
    <div className='position-relative py-5 mb-4 container d-flex align-items-center justify-content-center' style={{minHeight:'120vh'}}>
      <img className='position-absolute crm-layout5-img z-1' style={{width:'80%',height:'94%'}} src={require('../../images/products/Illustration.png')} alt="" />
      <div className='text-center crm-layout5-imgdiv z-2'>
        <img src={require('../../images/products/meridaicon (1).png')} style={{width:'10%'}} alt="" />
        <p className='fs-1 fw-bold mt-4'>Ready to Elevate Your Sales Strategy? Get Started Today!</p>
        <p style={{fontSize:'13px'}}>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
        <button style={{backgroundColor:'#FFE600',fontSize:'14px'}} className='rounded-pill mt-4 px-4 btn fw-medium'>Get Started Now</button>
      </div>
    </div>
  )
}

export default CRM_layout5
