import React from 'react'
import Check from '../../Svgs/Products/Check'

const Printlele_layout5 = () => {
  return (
    <div className='printlele-layout5 d-flex align-items-center'>
      <div className='printlele-layout5-text mx-auto'>
      <p style={{fontSize:'40px'}} className='text-center'>Get the Best Printing Solution with Printlele</p>
      <button className='btn mt-4 rounded-pill px-4 py-3 d-block mx-auto text-white fw-medium' style={{fontSize:'14px',backgroundColor:'#004643'}}>Contact Us</button>
      </div>
    </div>
  )
}

export default Printlele_layout5
