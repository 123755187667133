import React from 'react'

const Layout3 = () => {
  return (
    <div className='py-5'>
         <div className="container gradient-div1 p-0">
      <div className="orbit orbit1"></div>
      
      <div className='inner-layout3 container row m-0 mx-auto p-4'>
        <div className="col-md-4">
          <p className='fw-medium' style={{ fontSize: '18px' }}>SEO Strategy</p>
          <p style={{ fontSize: '15px' }}>
            Add custom branded graphics that lay over your live video to intro speakers, emphasize key points, and display clickable CTAs.
          </p>
        </div>
      </div>
    </div>

      <p className='container fs-5 fw-medium py-4 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg> <span className='ms-2'>Search Engine Optimization – Be Found Everywhere Online</span></p>
    

<div className='container row m-0 mx-auto'>
       <div className="col-md-7 ps-md-0">
        <div className='inner-layout4 position-relative overflow-hidden p-4'>
      <div className="orbit2 orbit"></div>

        <div className="row m-0">
        <div className="col-md-6">
        <p className='fw-medium' style={{fontSize:'18px'}}>Active Presence</p>
        <p style={{fontSize:'15px'}}>Build strong connections with your audience through regular posts, stories, and updates that encourage interaction and leave a lasting impression on their minds.</p>
        </div>
       </div>
        </div>

       <p className='container fs-6 fw-medium py-4 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg> <span className='ms-2'>Social Media Marketing – Engage, Share, Connect</span></p>
       </div>

       <div className="col-md-5 pe-md-0">
        <div className='inner-layout5 position-relative overflow-hidden d-flex flex-column justify-content-between'>
          <div className="orbit orbit3"></div>
        <div className='row m-0'>
        <div className="col-md-10 p-4">
        <p className='fw-medium text-white' style={{fontSize:'18px'}}>Lead Finder</p>
        <p className='text-white' style={{fontSize:'15px'}}>Find people who are already looking for what you offer and guide them smoothly from curiosity to becoming loyal customers.</p>
        </div>
       </div>
       <img className='d-block mx-auto' src={require('../images/633b16170a5347d8d6ee468c_home-experience-video-image.jpg.png')} style={{width:'70%'}} alt="" />
        </div>
        <p className='container fs-6 fw-medium py-4 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg> <span className='ms-2'>Lead Generation – Turning Interest Into Sales</span></p>
       </div>

       <div className="col-md-6 ps-md-0">
        <div className='inner-layout6 p-4'>
        <div className="row m-0">
        <div className="col-md-8">
        <p className='fw-medium text-white' style={{fontSize:'18px'}}>Valuable Insights</p>
        <p className='text-white' style={{fontSize:'15px'}}>Share meaningful content that answers questions, solves problems, and keeps your audience coming back for more information and advice from your brand.</p>
        </div>
       </div>
        </div>

       <p className='container fs-6 fw-medium py-4 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg> <span className='ms-2'>Content Marketing – Stories That Speak Volumes</span></p>
       </div>

       <div className="col-md-6 pe-md-0">
        <div className='inner-layout7 d-flex flex-column justify-content-between'>
        <div className='row m-0'>
        <div className="col-md-10 p-4">
        <p className='fw-medium ' style={{fontSize:'18px'}}>Brand Partners</p>
        <p className='' style={{fontSize:'15px'}}>Collaborate with influencers who genuinely like your brand and share it with their followers, making your product trustworthy in new circles.</p>
        </div>
       </div>
       </div>
        <p className='container fs-6 fw-medium py-4 d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-square-fill" viewBox="0 0 16 16">
  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z"/>
</svg> <span className='ms-2'>Influencer Marketing – Trusted Voices Create Impact</span></p>
       </div>
      </div>
    
    </div>
  )
}

export default Layout3
