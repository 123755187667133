import React from 'react'

const Layout1 = () => {
  return (
    <div className='inner-page2-div'>
      <img src={require('../images/innerpage2/Group 142443.png')} style={{width:'100%'}} alt="" />
      <div className='inner-page2-subdiv py-5'>
       <div className="container">
       <p className='page2-text'>WHAT WE OFFER</p>
        <p className='text-center fs-1 fw-bold m-0'>Lead Generation: Helping You Find Ideal Customers</p>
        <p className='fs-5 text-center py-5'>At Merida Tech Minds, we specialize in helping businesses like yours attract more customers through effective lead generation strategies. We’ve successfully assisted many clients in growing their customer base and we can do the same for you.</p>

<div className='row m-0 justify-content-center'>
<div className="col-lg-3 text-center col-md-6">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
<div className="col-lg-3 text-center col-md-6">
<p className='fs-1 fw-bold m-0'>25K+</p>
<p className='fw-medium'>Project Completed</p>
</div>
</div>
       </div>
      </div>
    </div>
  )
}

export default Layout1
