import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"How does digital marketing help grow my business?",
            answer:"Digital marketing increases your brand’s visibility online, connects you with the right audience. With Merida Tech Minds’ digital marketing services, your business can grow steadily and turn visitors into loyal customers through targeted strategies."
        },
        {
            question:" Is Digital Marketing better than traditional marketing?",
            answer:"Yes! Digital marketing allows real-time tracking, personalized targeting, and lower costs, making it more effective than traditional marketing in today’s competitive environment."
        },
        {
            question:"How soon can I see results from Digital Marketing?",
            answer:"It varies. SEO efforts can take 3-6 months, while paid ads and social media campaigns can deliver faster results within days or weeks."
        },
        {
            question:"What’s the role of SEO in digital marketing?",
            answer:"SEO improves your website’s visibility in search engines, bringing organic visitors who are actively looking for your products or services."
        },
        {
            question:"How is social media important for my business?",
            answer:"Social media helps your business stay connected with customers, share updates, and build trust through consistent engagement and interaction."
        },
        {
            question:"Can Digital Marketing help me reach local customers?",
            answer:"Yes! Local SEO makes sure your business appears in local searches, bringing nearby customers directly to your store or website."
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
