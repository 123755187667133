import React from 'react'
import Printlele_layout2 from './Printlele-layout2'
import Printlele_layout3 from './Printlele-layout3'
import Printlele_layout4 from './Printlele-layout4'
import Printlele_layout5 from './Printlele-layout5'
import Navbar from '../../Landingpages/Navbar'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Blogs from '../../Landingpages/Blogs'
import FAQ from '../../Landingpages/FAQ'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Printlele = () => {
  const navigate=useNavigate()

  return (
    <div>
      <Helmet>
        <title>Printlele: Smart Printing Solutions for Every Need</title>
        <meta name="description" content="Our Printlele software simplifies printing, ensuring quick document and image printing for all your home or office needs. Enjoy hassle-free printing every time!" />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Printlele: Your Ultimate Printing Software</h1>
      <h2 className="d-none">Printing Software Solutions</h2>

      <div>
      <div className="printlele-manager-layout1">
        <Navbar/>
       <div className='task-manager-subdiv mx-auto pt-5'>
       <p style={{color:'#004643',letterSpacing:'2px'}} className='text-center fw-medium m-0'>MEET THE ONE AND ONLY COCA</p>
       <p className='fw-bold text-center mt-3' style={{fontSize:'50px',lineHeight:'70px'}}>Smart Software for All Your Printing Needs</p>
       <button onClick={()=>navigate('/contact-us')} className='mt-3 btn rounded-pill px-4 py-3 d-block mx-auto text-white fw-medium' style={{fontSize:'14px',backgroundColor:'#004643'}}>Contact Us</button>
       </div>

       <img src={require('../../images/products/Group 2.png')} className='d-block mx-auto mt-5 pt-4' style={{width:'80%'}} alt="" />
      </div>

      <Printlele_layout2/>
      <Printlele_layout3/>
      <Printlele_layout4/>
      <Printlele_layout5/>
      <Backtotop/>
      <Scrolltotop/>
      <FAQ/>
      <Blogs/>
    </div>

    </div>
  )
}

export default Printlele
