import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import './styles.css'; // Ensure this file contains your CSS

const ScrollEffect2 = () => {
  const [scrollY, setScrollY] = useState(0);

  // Assume the height of the container before is 100vh (e.g., 100% of viewport height)
  const offsetHeight = window.innerHeight;

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getLeftColumnStyle = (
    shrinkPoint,
    hidePoint,
    initialColor,
    backgroundImage,
  ) => {
    const colorIntensity = Math.max(
      0,
      Math.min(1, (scrollY - shrinkPoint) / (hidePoint - shrinkPoint)),
    );
    const color = `rgba(${initialColor}, ${colorIntensity})`;

    return {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover', // Ensures the image covers the entire container
      backgroundPosition: 'center', // Centers the image
      transition: 'background-image 1s ease-out', // Smooth transition for image change
      // transition: 'background-color 1s ease-out', // Smooth transition for color change
    };
  };

  const getDivStyle = (shrinkPoint, hidePoint) => {
    const blurMax = 6;
    let blur = 0;

    if (scrollY >= shrinkPoint) {
      blur = Math.min(
        ((scrollY - shrinkPoint) / (hidePoint - shrinkPoint)) * blurMax,
        blurMax,
      );
    }

    let opacity = 1;
    if (scrollY >= shrinkPoint) {
      opacity =
        1 - Math.min((scrollY - shrinkPoint) / (hidePoint - shrinkPoint), 1);
    }

    if (scrollY >= hidePoint) {
      return {
        opacity: 0,
        filter: `blur(${blurMax}px)`,
      };
    }

    const scale =
      0.65 +
      (1 - 0.65) * (1 - (scrollY - shrinkPoint) / (hidePoint - shrinkPoint));
    return {
      opacity: opacity,
      filter: `blur(${blur}px)`,
    };
  };

  const [isVisible3, setIsVisible3] = useState(false);
  const divRef = useRef(null);

  const [showGif, setShowGif] = useState(false);
  const divRef2 = useRef(null);
  const divRef3 = useRef(null);
  const divRef4 = useRef(null);
  const divRef5 = useRef(null);
  const divRef6 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef2.current) {
        const divTop = divRef2.current.getBoundingClientRect().top;
        // const viewportHeight = window.innerHeight;
        const viewportHeight = 0;

        if (divTop === viewportHeight) {
          setShowGif(true);
          setTimeout(() => setShowGif(false), 4000); // Show GIF for 4 seconds
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check scroll position on mount

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [hasAnimated2, setHasAnimated2] = useState(false); // Track whether the gradient animation has run
  const [isGradientVisible2, setIsGradientVisible2] = useState(false);
  const [isBgColorVisible2, setIsBgColorVisible2] = useState(false);

  // Intersection observer to detect div visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated2) {
          setIsGradientVisible2(true); // Show the gradient first
          setHasAnimated2(true); // Ensure animation happens only once

          // After 6 seconds, switch to the background color effect
          setTimeout(() => {
            setIsGradientVisible2(false); // Remove the gradient
            setIsBgColorVisible2(true); // Show background colors
          }, 6000); // Delay for 6 seconds
        }
      },
      { threshold: 0.5 }, // Trigger when 50% of the div is visible
    );

    if (divRef3.current) {
      observer.observe(divRef3.current);
    }

    return () => {
      if (divRef3.current) {
        observer.unobserve(divRef3.current);
      }
    };
  }, [hasAnimated2]);

  const [hasAnimated3, setHasAnimated3] = useState(false); // Track whether the gradient animation has run
  const [isGradientVisible3, setIsGradientVisible3] = useState(false);
  const [isBgColorVisible3, setIsBgColorVisible3] = useState(false);

  // Intersection observer to detect div visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated3) {
          setIsGradientVisible3(true); // Show the gradient first
          setHasAnimated3(true); // Ensure animation happens only once

          // After 6 seconds, switch to the background color effect
          setTimeout(() => {
            setIsGradientVisible3(false); // Remove the gradient
            setIsBgColorVisible3(true); // Show background colors
          }, 6000); // Delay for 6 seconds
        }
      },
      { threshold: 0.5 }, // Trigger when 50% of the div is visible
    );

    if (divRef4.current) {
      observer.observe(divRef4.current);
    }

    return () => {
      if (divRef4.current) {
        observer.unobserve(divRef4.current);
      }
    };
  }, [hasAnimated3]);

  const [hasAnimated4, setHasAnimated4] = useState(false); // Track whether the gradient animation has run
  const [isGradientVisible4, setIsGradientVisible4] = useState(false);
  const [isBgColorVisible4, setIsBgColorVisible4] = useState(false);

  // Intersection observer to detect div visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated4) {
          setIsGradientVisible4(true); // Show the gradient first
          setHasAnimated4(true); // Ensure animation happens only once

          // After 6 seconds, switch to the background color effect
          setTimeout(() => {
            setIsGradientVisible4(false); // Remove the gradient
            setIsBgColorVisible4(true); // Show background colors
          }, 6000); // Delay for 6 seconds
        }
      },
      { threshold: 0.5 }, // Trigger when 50% of the div is visible
    );

    if (divRef5.current) {
      observer.observe(divRef5.current);
    }

    return () => {
      if (divRef5.current) {
        observer.unobserve(divRef5.current);
      }
    };
  }, [hasAnimated4]);

  const [hasAnimated5, setHasAnimated5] = useState(false); // Track whether the gradient animation has run
  const [isGradientVisible5, setIsGradientVisible5] = useState(false);
  const [isBgColorVisible5, setIsBgColorVisible5] = useState(false);

  // Intersection observer to detect div visibility
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasAnimated5) {
          setIsGradientVisible5(true); // Show the gradient first
          setHasAnimated5(true); // Ensure animation happens only once

          // After 6 seconds, switch to the background color effect
          setTimeout(() => {
            setIsGradientVisible5(false); // Remove the gradient
            setIsBgColorVisible5(true); // Show background colors
          }, 6000); // Delay for 6 seconds
        }
      },
      { threshold: 0.5 }, // Trigger when 50% of the div is visible
    );

    if (divRef6.current) {
      observer.observe(divRef6.current);
    }

    return () => {
      if (divRef6.current) {
        observer.unobserve(divRef6.current);
      }
    };
  }, [hasAnimated5]);

  const navigate = useNavigate();

  return (
    <div className="bg-secondary-subtle">
      <div
        ref={divRef2}
        className="first-div bg-transparent"
        // style={getDivStyle(offsetHeight + 100, offsetHeight + 500)}
      >
        <div
          className="bg-transparent rounded mx-auto row m-0 align-items-center"
          style={{ height: '95%' }}
        >
          <div
            className="col-lg-6 column py-md-5 first-div1 d-flex flex-column align-items-center justify-content-center h-100 px-md-5"
            style={getLeftColumnStyle(
              100,
              500,
              '0, 123, 255',
              '/images/Mask group (1).png',
            )}
          >
            <div className="">
              <img
                src={require('../../src/images/Group.png')}
                alt=""
              />

              <p className="text-start text-white m-0 mt-4 fs-5">
                Software Consulting
              </p>
            </div>
            <div className="">
              <p
                className="text-white mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Customized <span className="fw-bold">Software Services</span>{' '}
                That Meet Your Business Needs
              </p>

              <button
                onClick={() => {
                  navigate('/service/software-consulting');
                }}
                className="btn text-white p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>
          <div
            className="col-lg-6 column first-div2 px-md-5 h-100 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              100,
              500,
              '0, 123, 255',
              '/images/Mask group (1).png',
            )}
          >
            <div className="">
              <div className="position-relative">
                <img
                  src={require('../../src/images/animated-icons/Group.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '70px' }}
                  src={require('../../src/images/animated-icons/Vector.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '40px', top: '70px' }}
                  src={require('../../src/images/animated-icons/Vector (1).png')}
                  alt=""
                />
              </div>

              <p className="m-0 mt-4 fs-5">Mobile App Development</p>
            </div>
            <div className="">
              <p
                className="text-center mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Create <span className="fw-bold">Engaging Apps</span> That
                Enhance User-experiences Easily
              </p>

              <button
                onClick={() => {
                  navigate(
                    '/services/best-mobile-app-development-company-bengaluru',
                  );
                }}
                className="btn p-0 border-0 d-block mx-auto"
              >
                Find Out More
              </button>
            </div>
          </div>
        </div>

        <div
          ref={divRef3}
          className={`m-0  row floating-gradient2`}
          style={{ height: '5%', transition: 'background 1s ease' }}
        >
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible2 ? '#d2ff3a' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">SOFTWARE INTEGRATION</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">SAAS CONSULTING</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className={`col-lg-4 col-md-6 d-flex justify-content-between align-items-center`}
            style={{
              backgroundColor: isBgColorVisible2 ? '#5a2cda' : 'transparent',
              color: isBgColorVisible2 ? 'white' : '',
              transition: '1s ease',
            }}
          >
            <p className="m-0">ENTERPRISE SOFTWARE SOLUTIONS</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">UI & UX DESIGN</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible2 ? '#FDCC0D' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">APP TESTING & QA</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">API DEVELOPMENT</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        ref={divRef2}
        className="second-div bg-transparent"
        // style={getDivStyle(offsetHeight + 750, offsetHeight + 1350)}
      >
        <div
          className="bg-transparent rounded mx-auto row m-0 align-items-center"
          style={{ height: '95%' }}
        >
          <div
            className="col-lg-6 column second-div1  h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div className="">
              <div className="position-relative">
                <img
                  src={require('../../src/images/animated-icons/Group2.png')}
                  alt=""
                />
                <img
                  className="position-absolute"
                  style={{ left: '24px', top: '60px', zIndex: 3 }}
                  src={require('../../src/images/animated-icons/Group 1171275297.png')}
                  alt=""
                />
                <img
                  className={`position-absolute moving-image`}
                  style={{ right: '55px', top: '80px', zIndex: 1 }}
                  src={require('../../src/images/animated-icons/Group 1171275298.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '46px', top: '25px' }}
                  src={require('../../src/images/animated-icons/Group 1171275299.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '10px', top: '50px' }}
                  width={50}
                  src={require('../../src/images/animated-icons/Group 1171275299.png')}
                  alt=""
                />
              </div>

              <p className="text-start mt-4 m-0 fs-5">Website Development</p>
            </div>
            <div className="">
              <p
                className="mt-5 fw-medium text-center"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                <span className="fw-bold">Web Solutions</span> That Make Your
                Business Accessible Online
              </p>

              <button
                onClick={() => {
                  navigate('/service/website-development-company');
                }}
                className="btn p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>

          <div
            className="col-lg-6 column second-div2 column-right h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div className="">
              <div className="position-relative">
                <img
                  src={require('../../src/images/animated-icons/Frame.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ left: '30px', top: '30px' }}
                  width={44}
                  src={require('../../src/images/animated-icons/Group 11712752100.png')}
                  alt=""
                />
              </div>
              <p className="fs-5 mt-4 m-0 text-white">Digital Marketing</p>
            </div>
            <div className="">
              <p
                className="text-center text-white mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Reach more People with Our{' '}
                <span className="fw-bold">Digital Marketing</span> Expertise
              </p>

              <button
                onClick={() => {
                  navigate('/service/digital-marketing');
                }}
                className="btn rounded-pill text-white p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>
        </div>

        <div
          ref={divRef4}
          className={`m-0 row floating-gradient2`}
          style={{ height: '5%', transition: 'background 1s ease' }}
        >
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#d2ff3a' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">UI & UX</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">FULL STACK DEVELOPMENT</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className={`col-lg-4 col-md-6 d-flex justify-content-between align-items-center`}
            style={{
              backgroundColor: isBgColorVisible3 ? '#5a2cda' : 'transparent',
              color: isBgColorVisible3 ? 'white' : '',
              transition: '1s ease',
            }}
          >
            <p className="m-0">MAINTENANCE & SUPPORT</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">SMM</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#FDCC0D' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">SEO</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">INFLUENCER MARKETING</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        ref={divRef2}
        className="second-div bg-transparent"
        // style={getDivStyle(offsetHeight + 1400, offsetHeight + 2000)}
      >
        <div
          className="bg-transparent rounded mx-auto row m-0 align-items-center"
          style={{ height: '95%' }}
        >
          <div
            className="col-lg-6 third-div1 column h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div className="">
              <img
                src={require('../../src/images/animated-icons/Group 1171275254.png')}
                alt=""
              />
              <p className="text-center text-white fs-5 mt-4 m-0">
                Lead Generation
              </p>
            </div>
            <div className="">
              <p
                className="text-center text-white mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Bringing in <span className="fw-bold">Quality Leads</span> That
                Turn into Loyal Customers
              </p>

              <button
                onClick={() => {
                  navigate('/service/lead-generation-services');
                }}
                className="btn text-white p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>

          <div
            className="col-lg-6 second-div1 column column-right h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div className="">
              <div className="position-relative">
                <img
                  src={require('../../src/images/animated-icons/Group 1171275255.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '32px', top: '25px' }}
                  src={require('../../src/images/animated-icons/11.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ right: '30px', bottom: '80px' }}
                  width={20}
                  src={require('../../src/images/animated-icons/3.png')}
                  alt=""
                />
                <img
                  className="position-absolute rotating-image"
                  style={{ left: '40px', top: '15px' }}
                  src={require('../../src/images/animated-icons/4.png')}
                  alt=""
                />
              </div>

              <p className="text-center mt-4 m-0 fs-5">Graphics Designing</p>
            </div>
            <div className="">
              <p
                className="text-center mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Delivering Designs That Speak Your{' '}
                <span className="fw-bold">Brand’s Language</span>
              </p>

              <button
                onClick={() => {
                  navigate('/service/graphic-design-services');
                }}
                className="btn p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>
        </div>

        <div
          ref={divRef5}
          className={`m-0 row floating-gradient2`}
          style={{ height: '5%', transition: 'background 1s ease' }}
        >
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#d2ff3a' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">CONTENT MARKETING</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">EMAIL MARKETING</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className={`col-lg-4 col-md-6 d-flex justify-content-between align-items-center`}
            style={{
              backgroundColor: isBgColorVisible3 ? '#5a2cda' : 'transparent',
              color: isBgColorVisible3 ? 'white' : '',
              transition: '1s ease',
            }}
          >
            <p className="m-0">CRM INTEGRATION</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">PRINT DESIGN</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#FDCC0D' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">ILLUSTRATION ART</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">INFOGRAPHIC DESIGN</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
        </div>
      </div>

      <div
        className="second-div bg-transparent"
        // style={getDivStyle(offsetHeight + 2100, offsetHeight + 2700)}
      >
        <div
          className="bg-transparent rounded mx-auto row m-0 align-items-center"
          style={{ height: '95%' }}
        >
          <div
            className="col-lg-6 fourth-div1 column h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div>
              <img
                src={require('../../src/images/animated-icons/Group 1171275256.png')}
                alt=""
              />

              <p className="text-center fw-5 mt-4 m-0 text-white">
                Logo Designing
              </p>
            </div>
            <div className="">
              <p
                className="text-center text-white mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Crafting <span className="fw-bold">Memorable Logos</span> That
                Represents Your Brand
              </p>

              <button
                onClick={() => {
                  navigate('/service/logo-designing-company');
                }}
                className="btn rounded-pill text-white p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>

          <div
            className="col-lg-6 fourth-div2 column column-right h-100 px-md-5 py-md-5 d-flex flex-column align-items-center justify-content-center"
            style={getLeftColumnStyle(
              700,
              1300,
              '40, 167, 69',
              '../images/Mask group (1).png',
            )}
          >
            <div className="">
              <img
                src={require('../../src/images/animated-icons/OBJECTS.png')}
                alt=""
              />

              <p className="text-center fs-5 mt-4 m-0">Brand Building</p>
            </div>
            <div className="">
              <p
                className="text-center mt-5 fw-medium"
                style={{ fontSize: '40px', lineHeight: '50px' }}
              >
                Build a Strong <span className="fw-bold">Brand Identity</span>{' '}
                That Connects with People
              </p>

              <button
                onClick={() => {
                  navigate('/service/brand-building');
                }}
                className="btn rounded-pill p-0 border-0 d-block mx-auto mt-5"
              >
                Find Out More
              </button>
            </div>
          </div>
        </div>

        <div
          ref={divRef5}
          className={`m-0 row floating-gradient2`}
          style={{ height: '5%', transition: 'background 1s ease' }}
        >
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#d2ff3a' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">LOGO GUIDELINES</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">LOGO ANIMATION</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className={`col-lg-4 col-md-6 d-flex justify-content-between align-items-center`}
            style={{
              backgroundColor: isBgColorVisible3 ? '#5a2cda' : 'transparent',
              color: isBgColorVisible3 ? 'white' : '',
              transition: '1s ease',
            }}
          >
            <p className="m-0">BRANDMARK & SYMBOL DESIGN</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">BRAND GUIDELINES</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
          <div
            className="col-lg-4 col-md-6 d-flex justify-content-between align-items-center"
            style={{
              backgroundColor: isBgColorVisible3 ? '#FDCC0D' : 'transparent',
              transition: '1s ease',
            }}
          >
            <p className="m-0">BRAND STRATEGY</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
            <p className="m-0">VISUAL IDENTITY</p>
            <img
              src={require('../images/Group 1000003203.png')}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollEffect2;
