import React from 'react'
import Svg1 from '../Svgs/Software_consulting/Svg1'
import Svg_arrow from '../Svgs/Software_consulting/Svg_arrow'
import Svg2 from '../Svgs/Software_consulting/Svg2'
import Svg3 from '../Svgs/Software_consulting/Svg3'

const Software_layout1 = () => {
  
  const Content = [
    {
      svg:<Svg1/>,
      heading: "Technology Selection",
      para: "Experts guide you to pick the right software from many options. Picking the right one ensures smoother operations, better results, and avoids unnecessary upgrades."
    },
    {
      svg:<Svg2/>,
      heading: "Expert Guidance",
      para: "Consultants offer personalized solutions for your business needs. Their experience ensures you stay on track with smart, practical advice that saves time and effort."
    },
    {
      svg:<Svg1/>,
      heading: "Cost Efficiency",
      para: "Planning with consultants helps cut extra expenses. Stick to essential tools and make thoughtful software choices that align perfectly with your budget."
    },
    {
      svg:<Svg2/>,
      heading: "Faster Delivery",
      para: "With proper consultation, projects move faster without delays. You stay ahead of competitors and complete tasks on time with fewer obstacles."
    }
  ];

  
  return (
    <div>
      <div className="row m-0 container mx-auto py-5 mb-4">
        <p className='fw-bold fs-2 text-center mb-5'>The Importance of Software Consultation 
        for Business Growth</p>
       
       {Content.map((x)=>{
        return(
          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
          <div className='software_consulting_cards'>
              <p>{x.svg}</p>
              <p className='fw-bold fs-5 mt-4'>{x.heading}</p>
              <p className='' style={{fontSize:'12px'}}>{x.para}</p>
              <p className='d-flex align-items-center m-0 mt-4'><span style={{fontSize:'13px'}} className='me-2'>See More</span>
              <Svg_arrow/>
</p>
          </div>
      </div>
        )
       })}
      </div>
    </div>
  )
}

export default Software_layout1
