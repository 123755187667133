import React from 'react'

const Algo_layout2 = () => {

    const card_data=[
        {
        heading:"High-Speed Execution",
        para:"Trades completed in milliseconds, maximizing profits and reducing market risks.",
        img:"/asset/Frame 1000006346.png"
    },
    {
        heading:"Data-Driven Insights",
        para:"Utilize extensive data analysis to make informed trading decisions that align with market trends.",
        img:"/asset/Graphic.png"
    },
    {
        heading:"Risk Management Tools",
        para:"Implement advanced risk management features to protect your investments and minimize losses.",
        img:"/asset/Graphic (1).png"
    },
    {
        heading:"Customizable Strategies",
        para:"Algorithms are personalized to suit your specific trading preferences and risk tolerance levels effectively.",
        img:"/asset/Graphic (2).png"
    },
    {
        heading:"User-Friendly Interface",
        para:"Navigate effortlessly through our software, designed for both experienced traders and beginners alike.",
        img:"/asset/Graphic (3).png"
    },
]

  return (
    <div>
      <div className="container row m-0 mx-auto py-4 py-md-5">
       <p style={{color:'#3E95CF',letterSpacing:'2px'}} className='fw-medium text-center'>FEATURES</p>
        <p className='fs-2 fw-medium text-center mb-5'>Features of Our Algo Trading Software</p>

        {card_data.slice(0,3).map((x)=>{
            return(
                <div className="col-lg-4 col-md-6 mb-4">
            <div className='algo-layout2-cards'>
                <div className='px-2 p-md-4' style={{height:'150px'}}>
                <p className='fs-5 fw-medium'>{x.heading}</p>
                <p className='text-secondary' style={{fontSize:'13px'}}>{x.para}</p>
                </div>
                <div className='text-center p-0' style={{height:'200px'}}>
                <img src={x.img} style={{width:'70%',height:'100%'}} alt="" />
                </div>
            </div>
        </div>
            )
        })}

{card_data.slice(3,5).map((x)=>{
            return(
                <div className="col-md-6 mb-4 mb-md-0">
            <div className='algo-layout2-cards'>
                <div className='px-2 p-md-4' style={{height:'125px'}}>
                <p className='fs-5 fw-medium'>{x.heading}</p>
                <p className='text-secondary' style={{fontSize:'13px'}}>{x.para}</p>
                </div>
                <div className='d-flex align-items-end p-0' style={{height:'225px'}}>
                <img src={x.img} style={{width:'100%',height:'85%',borderRadius:'10px'}} alt="" />
                </div>
            </div>
        </div>
            )
        })}
      </div>
    </div>
  )
}

export default Algo_layout2
