import React from 'react'

const Svg4 = () => {
  return (
    <div>
      <svg width="60" height="60" viewBox="0 0 84 87" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.621094 0.408447V86.5387H83.0065L0.621094 0.408447ZM58.8933 0.408447C45.5809 0.408447 34.7976 11.7003 34.7976 25.6177C34.7976 39.5352 45.5985 50.827 58.9108 50.827C65.3036 50.827 71.4329 48.1647 75.964 43.446C80.4951 38.7089 83.0241 32.301 83.0241 25.6177C83.0065 11.7003 72.2232 0.408447 58.8933 0.408447Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg4
