import React from 'react'

const Layout4 = () => {
  return (
    <div className='py-5' style={{backgroundColor:'#f7f7f7',minHeight:'100vh'}}>
        <p className='fs-1 fw-bold text-center m-0' style={{color:'#3A3A3A'}}>Our Design Process</p>
        <p className='text-center fs-5' style={{color:'#969696'}}>We follow a simple five-step process to build websites that work smoothly and meet your needs.</p>
        <div className='text-center container mt-5' style={{position:'relative'}}>
        <div style={{position:'absolute',zIndex:1,top:'44%'}}>
        <img src={require('../images/innerpage3/Vector.png')} alt="" style={{width:'100%'}}/>
        </div>
        <div >
        <div className="row m-0 justify-content-center" style={{position:'relative',zIndex:2}}>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>01</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275057.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Plan</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Understanding your needs helps us outline features that perfectly match your business goals.</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Design</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>A user-friendly layout is crafted to enhance visitor engagement and make navigation a breeze.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275056 (1).png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>02</p>
            </div>
        </div>

        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>03</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275056.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Build</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>The code is meticulously organized to create a seamless experience, ensuring everything runs smoothly.</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Test</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Every element is checked to guarantee functionality, so you can be confident in the final product.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275058.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>04</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>05</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275059.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Launch</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Once everything is ready, the website goes live, welcoming visitors and showcasing your brand online.</p>
            </div>
        </div>
      </div>
        </div>
        </div>
      
    </div>
  )
}

export default Layout4
