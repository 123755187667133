import React from 'react'

const Inner_svg1 = () => {
  return (
    <div>
      <svg width="74" height="68" viewBox="0 0 74 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M60.4333 65.3646V19.6864H70.3V65.3646H60.4333ZM41.9333 65.3646V29.5628H51.8V65.3646H41.9333ZM23.4333 65.3646V39.4391H33.3V65.3646H23.4333ZM4.93333 65.3646V50.5501H14.8V65.3646H4.93333ZM72.7666 65.3646V17.2173H57.9667V65.3646H54.2667V27.0937H39.4667V65.3646H35.7667V36.97H20.9667V65.3646H17.2667V48.081H2.46666V65.3646H1.23333C0.55253 65.3646 0 65.9165 0 66.5992C0 67.2819 0.55253 67.8337 1.23333 67.8337H2.46666H17.2667H20.9667H35.7667H39.4667H54.2667H57.9667H72.7666C73.4475 67.8337 74 67.2819 74 66.5992C74 65.9165 73.4475 65.3646 72.7666 65.3646Z" fill="#CDF552"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.87966 31.1509C10.0875 31.1509 10.3002 31.098 10.4933 30.9861L55.0967 5.23417L52.3051 11.3708C52.0235 11.9894 52.2965 12.7187 52.9151 12.9991C53.0799 13.0753 53.2533 13.1098 53.4242 13.1098C53.8915 13.1098 54.3379 12.8417 54.5446 12.3891L58.9251 2.75982C58.9386 2.72908 58.9362 2.69587 58.9472 2.6639C58.9706 2.59872 58.989 2.536 59.0013 2.46714C59.0075 2.43271 59.0272 2.40441 59.0296 2.36875C59.0308 2.35399 59.0247 2.3417 59.0259 2.32694C59.0284 2.28021 59.0235 2.23717 59.021 2.19043C59.0173 2.12279 59.0063 2.05884 58.9915 1.99121C58.9817 1.94448 58.9792 1.89652 58.9632 1.85102C58.9485 1.80675 58.9226 1.76985 58.903 1.7268C58.887 1.69852 58.887 1.66531 58.8698 1.6358C58.8526 1.60629 58.823 1.58907 58.8034 1.56078C58.7775 1.52266 58.7579 1.48207 58.7271 1.44764C58.6951 1.41075 58.6546 1.38492 58.6177 1.35172C58.5672 1.30622 58.5168 1.26441 58.4603 1.22751C58.4221 1.20291 58.3877 1.17586 58.3459 1.15495C58.3324 1.1488 58.325 1.1365 58.3115 1.13035C58.2807 1.11682 58.2475 1.11928 58.2168 1.10821C58.1491 1.08361 58.0827 1.0664 58.0126 1.0541C57.9806 1.04795 57.9548 1.02949 57.9216 1.02703L47.3921 0.00631009C46.7096 -0.0625609 46.1143 0.436736 46.0492 1.11067C45.9828 1.78706 46.4784 2.38842 47.1535 2.45361L53.8633 3.10416L9.26356 28.8561C8.67449 29.1955 8.47403 29.9481 8.81345 30.536C9.04097 30.9307 9.45417 31.1509 9.87969 31.1509H9.87966Z" fill="white"/>
</svg>

    </div>
  )
}

export default Inner_svg1
