import React from 'react'

const Inner_svg2 = () => {
  return (
    <div>
      <svg width="74" height="55" viewBox="0 0 74 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M67.8304 44.4C67.1497 44.4 66.5972 43.8475 66.5972 43.1667V3.70001C66.5972 3.02168 66.0447 2.46668 65.364 2.46668H8.63654C7.95581 2.46668 7.40334 3.02168 7.40334 3.70001V43.1667C7.40334 43.8475 6.85086 44.4 6.17014 44.4C5.48941 44.4 4.93693 43.8475 4.93693 43.1667V3.70001C4.93693 1.66008 6.59683 1e-05 8.63655 1e-05H65.364C67.4037 1e-05 69.0636 1.66008 69.0636 3.70001V43.1667C69.0636 43.8475 68.5111 44.4 67.8304 44.4H67.8304Z" fill="#EBFD05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M67.8333 54.2634H6.16667C2.76514 54.2634 0 51.4982 0 48.0967C0 47.4159 0.55253 46.8634 1.23333 46.8634H28.3667C29.0475 46.8634 29.6 47.4159 29.6 48.0967C29.6 48.7775 29.0475 49.33 28.3667 49.33H2.67879C3.18692 50.7656 4.55839 51.7967 6.16666 51.7967H67.8333C69.4416 51.7967 70.8131 50.7656 71.3212 49.33H45.6333C44.9525 49.33 44.4 48.7775 44.4 48.0967C44.4 47.4159 44.9525 46.8634 45.6333 46.8634H72.7667C73.4475 46.8634 74 47.4159 74 48.0967C74 51.4982 71.2349 54.2634 67.8333 54.2634Z" fill="#EBFD05"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.8967 35.2667C25.6082 35.2667 25.3197 35.1656 25.0855 34.9609L15.2224 26.3295C14.9537 26.0952 14.8008 25.7598 14.8008 25.4022C14.8008 25.0446 14.9537 24.7092 15.2224 24.4749L25.0854 15.8435C25.5959 15.3922 26.3726 15.4415 26.8263 15.9594C27.2751 16.4724 27.2233 17.2492 26.7104 17.698L17.9052 25.4022L26.7079 33.1064C27.2208 33.5552 27.2726 34.3321 26.8238 34.845C26.5797 35.1237 26.2394 35.2667 25.8967 35.2667L25.8967 35.2667Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.2971 37.7402C33.1687 37.7402 33.0379 37.7204 32.9071 37.6785C32.2605 37.4614 31.9125 36.7631 32.1272 36.1166L39.5311 13.9096C39.7458 13.2631 40.4393 12.9103 41.0909 13.1299C41.7375 13.347 42.0855 14.0453 41.8707 14.6918L34.4669 36.8988C34.2941 37.412 33.8129 37.7402 33.2971 37.7402Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.0975 35.2667C47.7545 35.2667 47.4141 35.1237 47.1698 34.845C46.7208 34.3321 46.7726 33.5553 47.2858 33.1065L56.0939 25.4027L47.2858 17.6989C46.7726 17.2501 46.7208 16.4733 47.1698 15.9604C47.6188 15.4425 48.3985 15.3956 48.9117 15.8445L58.7807 24.4755C59.0472 24.7097 59.2002 25.0451 59.2002 25.4027C59.2002 25.7603 59.0472 26.0956 58.7783 26.3299L48.9092 34.9609C48.6748 35.1656 48.3862 35.2667 48.0975 35.2667H48.0975Z" fill="white"/>
</svg>

    </div>
  )
}

export default Inner_svg2
