import React from 'react'
import Task_layout2 from './Task_layout2'
import Task_layout3 from './Task_layout3'
import Task_layout4 from './Task_layout4'
import Task_layout5 from './Task_layout5'
import Task_layout6 from './Task_layout6'
import Task_layout7 from './Task_layout7'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import Navbar from '../../Landingpages/Navbar'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const Task_Manager = () => {
  return (
    <div>
        <Helmet>
        <title>Task Manager Tool: Keep Your Projects Organized & On Track</title>
        <meta name="description" content="Organize tasks effectively with our task manager system. Simplify project tracking, prioritize tasks, and enhance team collaboration for better results." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Task Manager System for Organized Workflows</h1>
      <h2 className="d-none">Integrated Workflow Solutions for Effective Task Management</h2>

      <div>
      <div className="container pb-5 task-manager-layout1">
        <Navbar/>
       <div className='task-manager-subdiv mx-auto pt-4 pt-lg-5'>
        <p className='text-center mb-4'><span className='fw-bold border px-2 py-1 rounded-pill' style={{fontSize:'14px'}}><img className='me-1' src={require('../../images/products/Union.png')} alt="" /> TASK MANAGER</span></p>
       <p className='text-center task-maintext' style={{letterSpacing:'1px'}}>Keep Every Task on Track, <span className='task-text-color'> All Day</span> , Every Day</p>
       <p className='text-secondary text-center mt-3 mt-md-4' style={{fontSize:'15px'}}>Achieve better task clarity and manage workflows smoothly using our task management system.</p>

       <div class="input-group mb-3 task-main-search mx-auto mt-5">
  <input type="text" class="form-control bg-transparent border-0" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <button className='task-manager-gardient-buttons btn text-dark bg-white rounded-pill fw-medium'>See More</button>
</div>
       </div>

       <img src={require('../../images/products/Group 2.png')} className='d-block mx-auto mt-5 pt-5' style={{width:'90%'}} alt="" />
      </div>

      <Task_layout2/>
      <Task_layout3/>
      <Task_layout4/>
      <Task_layout5/>
      <Task_layout6/>
      <Task_layout7/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Task_Manager
