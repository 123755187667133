import React, { useState } from 'react'

const FAQ = () => {
    const faqs=[
        {
            question:"How will a logo help build my personal brand?",
            answer:"A unique logo helps express your personality, making it easier for people to connect with you and remember your personal brand. With Merida Tech Minds’ logo design services, we focus on creating logos that truly represent who you are."
        },
        {
            question:"What should I provide to help create my logo?",
            answer:"To make the most of our Logo Design services, share your brand values, target audience, and any design preferences like colors or styles to guide us in crafting your logo."
        },
        {
            question:"How many logo concepts will I see?",
            answer:"We usually provide 3-5 unique custom logo design concepts for you to choose from, ensuring you have a variety of options to consider."
        },
        {
            question:"What if I don’t like the logo options?",
            answer:"We value your feedback! If you’re not satisfied, we’ll work closely with you to refine and adjust the designs until you love them."
        },
        {
            question:"How long does the logo design process take?",
            answer:"Typically, our logo design process takes about 1-2 weeks, allowing us to craft the perfect design that truly reflects your vision."
        },
    ]

    const[index1,setindex1]=useState(-1)

  return (
    <div className='faq-div d-flex align-items-center' style={{position:'relative'}}>
        <img src={require('../images/image 19.png')} 
        // className='animate__animated animate__bounce animate__infinite	infinite' 
        style={{position:'absolute',bottom:0,width:'16%'}} alt="" />
      <div className='row m-0 container mx-auto'>
       <div className="col-lg-8 mx-auto">
       {faqs.map((x,index)=>{
        return(
            <div style={{cursor:'pointer'}} onClick={()=>{
                if(index1===-1){
                    setindex1(index)
                }
                else{
                    setindex1(-1)
                }
            }} className="border border-white rounded mb-4">
       <div className={`text-white d-flex justify-content-between align-items-center`}>
            <div className='p-3'>
            <span>{index+1}</span>
            <span className='ms-5'>{x.question}</span>
            </div>
            <div>
                <span className='faq-icon p-3 d-block d-flex justify-content-center align-items-center' style={{width:'60px'}}>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class={`bi bi-plus-lg ${index===index1 ? 'd-none':''}`} viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg></span>
<span className={index===index1 ? '':'d-none'}>-</span>
                </span>
            </div>
        </div>
        <div className='border-top border-secondary' style={{ maxHeight: index === index1 ? '100px' : '0px',
                    opacity: index === index1 ? 1 : 0,
                    overflow: 'hidden',
                    transition: 'max-height 0.6s ease, opacity 0.6s ease',}}>
        <p className={`ms-4 text-white px-md-5 pt-3`} style={{fontSize:'13px'}}>{x.answer}</p>
        </div>
       </div>
        )
       })}
       </div>
      </div>
    </div>
  )
}

export default FAQ
