import React from 'react'
import Svg1 from '../../Svgs/Payroll_management/Svg1'

const Payroll_layout2 = () => {
  const data=["Automated Salary Processing",
    "Employee Tax Compliance Management",
    "Real-time Payroll Tracking & Reporting",
    "Secure Employee Data Management",
    "Integration with Accounting Software"
  ]

  return (
    <div className='p-3'>
      <div className='payroll-layout2 py-3 py-md-5'>
        <p style={{color:'#AE93F0',letterSpacing:'3px'}} className='fs-5 fw-medium text-center'>FEATURES</p>
        <p className='text-white fs-2 fw-medium text-center'>Core Features of Our Payroll Software</p>
        <p className='text-secondary text-center' style={{fontSize:'13px'}}>Our software comes with essential tools to simplify payroll tasks, making it easy to stay organized and compliant.</p>

        <div className="row m-0 justify-content-center mt-4 mt-md-5 pt-md-4">
          {data.map((x)=>{
            return(
              <div className="col-lg-2 col-md-6 mb-4">
            <div className='payroll-layout2-card border border-secondary'>
              <Svg1/>
              <p className='mt-4'>{x}</p>
              <button className='mt-34d-flex align-items-center border-bottom bg-transparent border-0' style={{cursor:'pointer',fontSize:'13px'}}><span className='me-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
</svg></span>Learn More</button>
            </div>
          </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Payroll_layout2
