import React from 'react';
import { useNavigate } from 'react-router-dom';

const Icon_div = ({ state, setstate }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`icon-div py-4 animate__animated animate__fadeIn ${
        state ? '' : 'd-none'
      }`}
      style={{ minHeight: '100vh' }}
    >
      <div className="text-end container px-md-4 pt-3">
        <svg
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setstate(false);
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="38"
          fill="white"
          class="bi bi-x-lg animate__animated animate__rotateIn"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
      </div>
      <div className="container">
        <p
          className="montserrat text-white fs-2 fw-bold text-center"
          style={{ letterSpacing: '1px' }}
        >
          <span style={{ color: '#A3ABB0' }}>Your Perfect</span> Branding
          Partner
        </p>

        <p
          style={{ color: '#A3ABB0' }}
          className="d-none d-lg-inline m-0 mt-4 d-flex align-items-center justify-content-center fs-5"
        >
          Software Consulting
          <img
            className="ms-4"
            src={require('../images/Line 151.png')}
            alt=""
          />
        </p>

        <div className="row m-0 mt-5">
          <div className="col-lg-3 border border-start-0 p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/software-consulting');
                setstate(false);
              }}
            >
              Software Consulting
            </p>
          </div>
          <div className="col-lg-3 border p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate(
                  '/service/best-mobile-app-development-company-bengaluru',
                );
                setstate(false);
              }}
            >
              Mobile App Development
            </p>
          </div>
          <div className="col-lg-3 border p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/website-development-company');
                setstate(false);
              }}
            >
              Website Development
            </p>
          </div>
          <div className="col-lg-3 border border-end-0 p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/logo-designing-company');
                setstate(false);
              }}
            >
              Logo Designing
            </p>
          </div>

          <div className="col-lg-3 border border-start-0 p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/graphic-design-services');
                setstate(false);
              }}
            >
              Graphic Designing
            </p>
          </div>
          <div className="col-lg-3 border p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/digital-marketing');
                setstate(false);
              }}
            >
              Digital Marketing
            </p>
          </div>
          <div className="col-lg-3 border p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/lead-generation-services');
                setstate(false);
              }}
            >
              Lead Generation
            </p>
          </div>
          <div className="col-lg-3 border border-end-0 p-3 p-lg-5">
            <p
              className="icon-div-text"
              onClick={() => {
                navigate('/service/brand-building');
                setstate(false);
              }}
            >
              Brand Building
            </p>
          </div>
        </div>

        {/* <div
          className="d-flex align-items-center justify-content-evenly py-4 mt-3 d-none d-lg-block"
          style={{ borderBottom: '1px solid #D9D9D9' }}
        >
          <span className="icon-div-subtext">About</span>
          <span className="icon-div-subtext">Services</span>
          <span className="icon-div-subtext">Portfolio</span>
          <span className="icon-div-subtext">News & Events</span>
          <span className="icon-div-subtext">Blogs</span>
        </div> */}
      </div>
    </div>
  );
};

export default Icon_div;
