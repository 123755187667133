import React from 'react'

const Brand_building_layout2 = () => {
  return (
    <div className='bg-black d-flex align-items-center' style={{minHeight:'100vh'}}>
      <div className="row m-0 mx-auto container align-items-center">
      <p className='fs-4 text-center fw-medium text-white mb-5 pb-4' style={{letterSpacing:'1px'}}>Core <span style={{color:'#CDF552'}}>Elements</span> of Effective Brand Building</p>
        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-5 ps-md-0">
                    <img src={require('../images/innerpage5/Rectangle 86.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Clear Purpose</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>A clear purpose defines what your brand stands for and guides its actions.</p>
                </div>
            </div>
        </div>

        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-5 ps-md-0">
                    <img src={require('../images/innerpage5/Rectangle 150.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Target Audience</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>Know your audience to create messages that truly connect and engage them.</p>
                </div>
            </div>
        </div>

        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Consistency</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>Keep your messages and visuals consistent to create trust and set clear expectations.</p>
                </div>
                <div className="col-5 pe-md-0">
                    <img src={require('../images/innerpage5/Rectangle 82.png')} style={{width:'100%'}} alt="" />
                </div>
            </div>
        </div>

        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Emotional Connection</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>Building an emotional connection with your audience creates loyalty towards your brand.</p>
                </div>
                <div className="col-5 pe-md-0">
                    <img src={require('../images/innerpage5/Rectangle 158.png')} style={{width:'100%',height:'100%'}} alt="" />
                </div>
            </div>
        </div>

        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-5 ps-md-0">
                    <img src={require('../images/innerpage5/Rectangle 44997.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Market Positioning</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>Define your brand’s position in the market which shows how you differ from competitors.</p>
                </div>
            </div>
        </div>

        <div className="col-lg-6 px-2 px-md-4 mb-4">
            <div className='row m-0 branding-layout2-cards align-items-center border border-secondary'>
                <div className="col-5 ps-md-0">
                    <img src={require('../images/innerpage5/Rectangle 44998.png')} style={{width:'100%'}} alt="" />
                </div>
                <div className="col-7">
                    <p className='text-white fw-bold fs-5 m-0 mb-2'>Communication Strategy</p>
                    <p className='text-white m-0' style={{fontSize:'13px'}}>Use clear, friendly messaging to easily connect with your customers.</p>
                </div>
            </div>
        </div>

      </div>
    </div>
  )
}

export default Brand_building_layout2
