import React from 'react'
import Svg1 from '../Svgs/Inner_page2/Svg1'

const Logo_design = () => {
  
  return (
    <div className='inner2-Logo_design py-5 container'>
      <p className='fs-1 fw-medium text-center m-0'>Types of Logos We Design</p>
      <img src={require('../images/innerpage2/Group 1171275043.png')} className='d-block mx-auto mt-3' alt="" />
      <p className='text-center text-secondary mt-4' style={{fontSize:'15px',lineHeight:'30px'}}>We craft logos that tell your brand’s story! Each logo we design is unique, reflecting your values and goals. Our creative team works closely with you to create a memorable visual identity that connects with your audience and leaves a lasting impression.</p>

      <div className="row m-0 mt-5">
        <div className="col-lg-3">
            <div className='p-2' style={{backgroundColor:'#D5286F',borderRadius:'15px'}}>
                <p className='text-white m-0 py-2' style={{fontSize:'18px'}}>Wordmarks</p>
                <img src={require('../images/innerpage3/view-3d-businessman 1.png')} style={{width:'100%',borderRadius:'10px'}} alt="" />
            </div>
        </div>
        <div className="col-lg-3">
            <div className='p-2' style={{backgroundColor:'#F27211',borderRadius:'15px'}}>
                <p className='text-white m-0 py-2' style={{fontSize:'18px'}}>Lettermarks</p>
                <img src={require('../images/innerpage3/view-3d-businessman 1.png')} style={{width:'100%',borderRadius:'10px'}} alt="" />
            </div>
        </div>
        <div className="col-lg-3">
            <div className='p-2' style={{backgroundColor:'#7B4DFF',borderRadius:'15px'}}>
                <p className='text-white m-0 py-2' style={{fontSize:'18px'}}>Iconic Logos</p>
                <img src={require('../images/innerpage3/view-3d-businessman 2.png')} style={{width:'100%',borderRadius:'10px'}} alt="" />
            </div>
        </div><div className="col-lg-3">
            <div className='p-2' style={{backgroundColor:'#3A4563',borderRadius:'15px'}}>
                <p className='text-white m-0 py-2' style={{fontSize:'18px'}}>Combination Marks</p>
                <img src={require('../images/innerpage3/view-3d-businessman 1 (1).png')} style={{width:'100%',borderRadius:'10px'}} alt="" />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Logo_design
