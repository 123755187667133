import React from 'react'

const Socialmedia2 = () => {
  return (
    <div>
      <svg width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.4285 18.9874C7.64417 18.9876 5.89982 18.4586 4.41613 17.4674C2.93244 16.4761 1.7761 15.0672 1.09319 13.4187C0.410279 11.7702 0.231468 9.9562 0.579536 8.20614C0.927604 6.45608 1.78684 4.84863 3.04856 3.58691C4.31027 2.32519 5.91783 1.46596 7.66789 1.11789C9.41795 0.769824 11.2319 0.948536 12.8804 1.63145C14.5289 2.31436 15.9379 3.4708 16.9291 4.95449C17.9203 6.43817 18.4492 8.18242 18.4491 9.96676C18.4457 12.3581 17.4942 14.6506 15.8032 16.3416C14.1123 18.0325 11.8199 18.984 9.4285 18.9874ZM9.4285 1.55032C7.76384 1.55016 6.13655 2.04358 4.75236 2.9683C3.36817 3.89302 2.28928 5.20753 1.65213 6.74543C1.01498 8.28333 0.84818 9.97555 1.17284 11.6082C1.4975 13.2409 2.29902 14.7407 3.47606 15.9178C4.65309 17.095 6.15278 17.8967 7.78545 18.2215C9.41811 18.5464 11.1104 18.3797 12.6484 17.7427C14.1863 17.1057 15.5008 16.0269 16.4257 14.6428C17.3505 13.2587 17.8442 11.6314 17.8442 9.96676C17.8418 7.73543 16.9544 5.59622 15.3767 4.01835C13.799 2.44048 11.6598 1.55288 9.4285 1.55032Z" fill="white"/>
<path d="M8.25181 14.6392H10.1335V9.92644H11.4458L11.5861 8.34876H10.1343V7.45024C10.1343 7.07841 10.2085 6.93166 10.5682 6.93166H11.587V5.29431H10.2835C8.88329 5.29431 8.2526 5.91055 8.2526 7.09136V8.34876H7.27344V9.94663H8.2526L8.25181 14.6392Z" fill="white"/>
</svg>

    </div>
  )
}

export default Socialmedia2
