import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Ovcr_layout2 from './Ovcr_layout2'
import Ocr_layout3 from './Ocr_layout3'
import Ocr_layout4 from './Ocr_layout4'
import Ocr_layout5 from './Ocr_layout5'
import Ocr_layout6 from './Ocr_layout6'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const Ocr = () => {
  return (
    <div>
       <Helmet>
        <title>Lernen Hub Education Software for Skill Development</title>
        <meta name="description" content="Discover Lernen Hub, the intuitive education software that transforms the way individuals enhance their skills and knowledge in a seamless manner." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Lernen Hub: Educational Software for Personalized Skill Enhancement </h1>
      <h2 className="d-none">Intuitive Learning Solutions </h2>

      <div>
        <div className='ocr-layout' style={{overflowY:'hidden'}}>
            <Navbar/>
            <div className='ocr-subdiv pt-3 mx-auto'>
                <p className='text-white fw-bold text-center fs-1'>Lernen Hub: Innovating the Way Learning Management Software Works</p>
                <p className='text-white text-center' style={{fontSize:'14px',lineHeight:'20px'}}>Providing organizations with effective solutions that enhance user engagement and learning outcomes. </p>
            </div>
           </div>

           <Ovcr_layout2/>
           <Ocr_layout3/>
           <Ocr_layout4/>
           <Ocr_layout5/>
           <Ocr_layout6/>
           <FAQ/>
           <Blogs/>
           <Backtotop/>
           <Scrolltotop/>
    </div>

    </div>
  )
}

export default Ocr
