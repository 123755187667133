import React from 'react'
import Navbar from '../../Landingpages/Navbar'
import Medical_layout2 from './Medical_layout2'
import Medical_layout3 from './Medical_layout3'
import Medical_layout4 from './Medical_layout4'
import Medical_layout5 from './Medical_layout5'
import Medical_layout6 from './Medical_layout6'
import Medical_layout7 from './Medical_layout7'
import Backtotop from '../../Backtotop'
import Scrolltotop from '../../Scrolltotop'
import FAQ from '../../Landingpages/FAQ'
import Blogs from '../../Landingpages/Blogs'
import { Helmet } from 'react-helmet'

const Medical_billing = () => {
  return (
    <div>
      <Helmet>
        <title>Advanced Medical Billing Solutions for Healthcare Providers</title>
        <meta name="description" content="Get medical billing software that ensures accurate claims processing and payment tracking, allowing providers to focus on patient care without delays." />
        {/* <meta name='keywords' content={servicecontent.keywords} /> */}
        {/* <link rel="canonical" href={`https://www.meridatechminds.com`} /> */}
      </Helmet>

      <h1 className="d-none">Medical Billing Software for Healthcare Professionals</h1>
      <h2 className="d-none">Medical Billing Software Solutions</h2>

      <div>
      <div className='medical-div'>
        <Navbar/>
        <div className='medical-subdiv mx-auto mt-4'>
            <p className='fs-1 fw-medium text-center'>Helping Healthcare Manage Payments With Complete Assurance </p>
            <p className="text-secondary text-center" style={{fontSize:'14px'}}>
            Reliable medical billing services to ensure healthcare providers focus on care while payments get handled with precision.
            </p>

            <img src={require('../../images/products/Dashboard.png')} alt="" className='mt-4 mt-md-5' style={{width:'100%'}}/>
        </div>
      </div>

      <Medical_layout2/>
      <Medical_layout3/>
      <Medical_layout4/>
      <Medical_layout5/>
      <Medical_layout6/>
      <Medical_layout7/>
      <FAQ/>
      <Blogs/>
      <Backtotop/>
      <Scrolltotop/>
    </div>
    </div>
  )
}

export default Medical_billing
