import React from 'react'
import { useNavigate } from 'react-router-dom'

const Onlinetest_layout5 = () => {
  const navigate=useNavigate()

  return (
    <div className='py-4 py-md-5'>
      <div className='onlinetest-layout5 container d-flex align-items-center' style={{borderRadius:'25px'}}>
        <div className='ocr-layout6-subdiv mx-auto'>
        <p className='fs-2 fw-medium text-center text-white'>Create, manage, and grade tests with a click!</p>
        <p style={{fontSize:'14px'}} className='text-white text-center'>Our online test software is designed for everyone, making it easy to create assessments and evaluate results, all in one place. Connect with us to learn more about our online test software solutions.</p>
        <button onClick={()=>navigate('/contact-us')} className='bg-light rounded-pill d-block mx-auto mt-5 px-4 py-3 fw-medium btn' style={{color:'#ED6C3C'}}>Contact Us</button>
        </div>
      </div>
    </div>
  )
}

export default Onlinetest_layout5
