import React from 'react'

const Svg3 = () => {
  return (
    <div>
      <svg width="60" height="60" viewBox="0 0 68 102" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.2512 1.83506C14.0943 2.78732 0.895307 18.0995 1.78681 36.0169L63.9611 32.566C63.0696 14.6487 48.4237 0.881925 31.2512 1.83506Z" fill="currentColor"/>
<path d="M32.9622 34.4893C15.8052 35.4412 2.60599 50.7531 3.49799 68.6869L65.6724 65.2373C64.7804 47.3036 50.1348 33.5365 32.9622 34.4893Z" fill="currentColor"/>
<path d="M34.6731 67.159C17.5162 68.1109 4.31612 83.4065 5.20812 101.34L67.3825 97.8906C66.4905 79.9569 51.8457 66.2062 34.6731 67.159Z" fill="currentColor"/>
</svg>

    </div>
  )
}

export default Svg3
