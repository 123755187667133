import React from 'react'
import Svg6 from '../../Svgs/Payroll_management/Svg6'

const Background_layout6 = () => {
  return (
    <div className=''>
      <div className="container py-5 row m-0 mx-auto align-items-center">
      <div className="col-md-6 text-center">
       <img src={require('../../images/products/Stacked Group.png')} className='' style={{width:'100%'}} alt="" />
       </div>

       <div className='col-md-6 col-lg-5 mx-auto'>
       <p className='fs-1 fw-medium' style={{letterSpacing:'1px',lineHeight:'54px',color:'#183B56'}}>Reliable Background Verification Solutions for Companies of All Size</p>
       <p className='text-secondary  mt-3 mt-md-4 mb-5' style={{fontSize:'15px'}}>Join us for trustworthy background verification, where our dedicated team ensures accuracy, confidentiality, and personalized service for you. Reach out to know more information on what we provide!</p>

       <div className="row m-0">
        <div className="col-md-6">
            <img src={require('../../images/products/Icon (9).png')} alt="" />
            <p className='fw-medium mt-3 mb-2' style={{color:'#183B56'}}>Always in Sync</p>
            <p style={{color:'#5A7184',fontSize:'14px'}}>Don’t worry about the data, 
            always be synchronized</p>
        </div>
        <div className="col-md-6">
            <img src={require('../../images/products/Icon (10).png')} alt="" />
            <p className='fw-medium mt-3 mb-2' style={{color:'#183B56'}}>Powerful dashboard</p>
            <p style={{color:'#5A7184',fontSize:'14px'}}>Combine multiple reports into a single beautiful dashboard.</p>
        </div>
       </div>
       </div>
      </div>
    </div>
  )
}

export default Background_layout6
