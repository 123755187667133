import React from 'react'
import Svg1 from '../../Svgs/Algo/Svg1'

const Algo_layout6 = () => {
  return (
    <div className='container row mx-auto m-0 justify-content-around align-items-center' style={{minHeight:'100vh'}}>
      <div className="col-md-6 col-lg-5">
        <div style={{backgroundColor:'#EBF5FD'}} className='rounded text-center'>
            <img src={require('../../images/products/Hero Content3.png')} style={{width:'100%'}} alt="" />
        </div>
      </div>
      <div className="col-md-6 col-lg-5">
        <p className='fs-2 fw-medium'>Why partner with us?</p>
        <p className='text-secondary' style={{fontSize:'13px'}}>Merida delivers what matters! Count on us for reliable algo trading solutions.</p>

        <p className='d-flex align-items-center mt-5'><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Custom software built for your unique trading needs.</span></p>
        <p className='d-flex align-items-center mt-4 pt-3'><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Continuous support and upgrades for seamless performance.</span></p>
        <p className='d-flex align-items-center mt-4 pt-3' pt-3><Svg1/> <span className='fw-medium ms-3' style={{fontSize:'14px'}}>Experienced developers with deep market and tech expertise.</span></p>
      </div>
    </div>
  )
}

export default Algo_layout6
