import React from 'react'

const Layout3 = () => {
  return (
    <div className='py-5' style={{backgroundColor:'#f7f7f7',minHeight:'100vh'}}>
        <p className='fs-1 fw-bold text-center m-0' style={{color:'#3A3A3A'}}>Our Design Process</p>
        <p className='text-center fs-5' style={{color:'#969696'}}>Collaborative and Creative Journey</p>
        <div className='text-center container mt-5' style={{position:'relative'}}>
        <div style={{position:'absolute',zIndex:1,top:'44%'}}>
        <img src={require('../images/innerpage3/Vector.png')} alt="" style={{width:'100%'}}/>
        </div>
        <div >
        <div className="row m-0 justify-content-center" style={{position:'relative',zIndex:2}}>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>01</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275057.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Understand</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>We listen carefully to your requirements, ensuring we’re on the same page and capturing your vision.</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Ideate</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Our creative team comes together to brainstorm fresh concepts, crafting designs that truly reflect your brand's spirit.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275056 (1).png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>02</p>
            </div>
        </div>

        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>03</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275056.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Design</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>We develop multiple design options, blending creativity with your requirements to provide you with fresh perspectives.</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0'>Refine</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Your feedback is essential! We polish the selected design, making adjustments to ensure it meets your expectations.</p>
            
            </div>
            <img src={require('../images/innerpage3/Group 1171275058.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <img src={require('../images/innerpage3/Group (1).png')} alt="" />
            <p className='inner3-text m-0'>04</p>
            </div>
        </div>
        <div className="col-md-2 text-center" style={{height:'60vh'}}>
            <div className='' style={{height:'32%'}}>
            <p className='inner3-text m-0'>05</p>
            <img src={require('../images/innerpage3/Group.png')} alt="" />
            </div>
            <img src={require('../images/innerpage3/Group 1171275059.png')} style={{width:'100%',height:'36%'}} className='' alt="" />
            <div className="" style={{height:'32%'}}>
            <p className='fs-4 fw-bold m-0 mt-3'>Deliver</p>
            <p className='mt-2' style={{fontSize:'12px',color:'#969696'}}>Once finalized, we provide all necessary design files, making them ready for you to showcase everywhere.</p>
            </div>
        </div>
      </div>
        </div>
        </div>
    </div>
  )
}

export default Layout3
